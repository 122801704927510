.pp_gain_coins_head {
    @include Flex(row, center, center);
    margin: 12px auto;

    .gain_coins_ref {
        background-color: #00984a;
        padding: 8px 16px;
        border-radius: 24px;
        position: absolute;
        right: 0;
    }

    .gain_coins_title {
        color: #f8c84f;
        font-size: $brand-fontSizeWebPageHeading;
        font-weight: $brand-fontWeight;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: $brand-fontSizeMobilePageHeading;
        }
    }
}

.pp_gain_coin_container {
    position: relative;

    .referAndEarnContent {
        margin-top: 10px;

        .content_box {
            border-radius: 4px;
            box-shadow: 0 4px 12px #00000040;
            color: #a2a2a2;
        }

        .cric-accordion .cric-Accordion-header {
            padding: 10px !important;
            background: #021433 0 0 no-repeat padding-box !important;

            .content_title {
                color: #fff;
                font-size: 16px;
            }
        }

        .cric-accordion-content {
            padding: 0px 10px 10px 10px !important;
            background: #021433 0 0 no-repeat padding-box !important;

            .content_description {
                .content_bold {
                    font-weight: $brand-fontWeight;
                }

                .content_highlight {
                    color: white;
                    font-weight: $brand-fontWeight;
                }

                p {
                    font-size: 14px;
                    padding-top: 12px;
                }

                ol {
                    li {
                        font-size: 14px;
                        padding-top: 12px;
                    }
                }

                .telegram_refer_button {
                    margin-top: 10px;
                    align-items: center;
                    background-color: initial;
                    border: none;
                    border-radius: 12px;
                    box-shadow: none;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    gap: 0;
                    justify-content: center;
                    line-height: 22px;
                    outline: none;
                }
            }
        }
    }
}

.pp_accordion_sectionOne {
    // margin-bottom: 10px;
    padding-bottom: 6px;

    .title {
        font-size: 16px;
        margin: 0 0 20px 0;
        color: white;
    }

    .sub-title {
        font-size: $brand-fontSizeAccordContaint;
        padding: 6px 0px;
        color: white;
    }

    // .content {
    //     // margin: 0 0 20px 0;
    //     // padding: 0px 20px;
    // }

    p {
        margin-bottom: 10px;
        font-size: $brand-fontSizeAccordContaint;
    }

    span {
        color: #fea792;
        //padding-left: 18px;
    }

    ul {
        padding-left: 20px;

        li {
            list-style-type: "→";
        }
    }

    ol {
        padding-left: 20px;

        li {
            list-style-type: decimal;
            line-height: 30px;
        }
    }

    .telegram_refer_button {
        @include Flex(row, center, center);
        background-color: transparent;
        border-radius: 12px;
        cursor: pointer;
        outline: none;
        box-shadow: none;
        border: none;
        line-height: 22px;
        margin-top: 10px;

        img {
            width: 398px;
        }
    }
}

.pp_gain_coins_wrapper {
    .gain_coin_banner_desktop {
        img {
            display: block;
            width: 100%;
        }
    }

    .gain_coin_banner_mobile {
        img {
            display: none;
        }
    }

    .gain_coin_bannerHeader {
        border-radius: 12px;
        flex-wrap: wrap;
        // margin: 60px 0;
        margin-bottom: 20px;
        // height:380px;

        // .banner_text_content{
        //     width: 380px;
        //     height: 210px;
        //     position: absolute;
        //     left: 45%;
        //     top: 50%;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     transform: translate(50%, -50%);
        //     div:nth-child(1){
        //         height: 70px;
        //         font: normal normal 600 80px/16px Poppins;
        //         line-height: 100%;
        //         justify-self: flex-start;
        //     }
        //     div:nth-child(2){
        //         text-align: right;
        //         width: auto;
        //         justify-self: flex-end;
        //         height: 70px;
        //         font: normal normal 600 100px/16px Poppins;
        //         line-height: 100%;
        //         color: #F8C84F;

        //     }
        // }


        // .gain_coin_banner {
        //     width: 50%;
        // }

        // img {
        //     width: 100%;
        //     padding: 24px 0px 0px 24px;
        // }
        // .gain_coin_banner_text{
        //     position: absolute;
        //     left: 60%;
        //     width: auto;
        //     height: auto;
        // }

        // .gain_coinBanner_textHeader {
        //     text-align: center;
        //     padding-left: 48px;

        //     .refer {
        //         font-size: 60px;
        //     }

        //     .and {
        //         font-size: 50px;
        //         text-align: right;
        //         color: #fea792;
        //     }

        //     .Earn {
        //         font-size: 50px;
        //         text-align: right;
        //     }

        //     h1 {
        //         font-size: 60px;
        //     }
        //     h2 {
        //         // color: #f8c84f;
        //         // text-align: right;
        //         // font-size: 50px;
        //         font: normal normal 600 100px/16px Poppins;
        //         letter-spacing: 0px;
        //         color: #F8C84F;
        //         text-transform: capitalize;
        //         opacity: 1;
        //     }
        // }
    }

    .HowTogain_Accordion {
        .howToGainCoins_content {
            width: 100%;
            color: white;
            background: transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 50%, #ffffff00 100%) 0% 0% no-repeat padding-box;
            overflow: hidden;
            box-shadow: 0px 4px 12px #00000040;
            border-radius: 4px;
            margin: 15px auto;
            padding: 1px 0px;

            .howToGainCoins_contentHeader {
                padding: 20px 25px 15px 25px;
                font-size: 18px;
                background: #232833 0% 0% no-repeat padding-box;
                opacity: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 0;
                cursor: pointer;
            }

            .howToGainCoins_contentBody {
                background: #232833 0% 0% no-repeat padding-box;
                padding: 12px 5px 5px;
                font-size: 14px;
                padding-top: 12px;
                color: #b7b7b7;

                .howToGainCoins_contentSection {
                    .content {
                        padding: 0 20px;
                        margin: 0 0 20px;

                        ul {
                            padding-left: 10px;

                            li {
                                // list-style-type: "→";
                                display: flex;
                            }
                        }

                        .title {
                            color: #fff;
                            font-size: 16px;
                            margin: 0 0 20px;
                        }

                        span {
                            color: #fea792;
                        }
                    }

                    p {
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}

.pp_gain_coin_banner_desktop,
.pp_gain_coin_banner_mobile {
    object-fit: cover;
    width: 100%;
}

.pp_gain_coin_banner_desktop {
    display: block;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        display: none;
    }
}

.pp_gain_coin_banner_mobile {
    display: block;

    @media screen and (min-width: 768px) {
        display: none;
    }
}