.password-content {
    .title {
        font-weight: $brand-fontWeight;
        color: $brand-secondaryColorLight;
        margin: 20px 0px;
        text-align: center;
        font-size: $brand-fontSizeWebPageHeading;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: $brand-fontSizeMobilePageHeading !important;
            margin: 16px 0px !important;
        }
    }

    .edit-password-title {
        margin: 16px 0px;
        font-size: $brand-fontSizeWebAccordHeading;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: $brand-fontSizeMobileAccordHeading !important;
        }
    }

    .update-password-title {
        margin: 16px 0px;
    }

    .hr-line {
        border: 1px solid gray;
        opacity: 0.5;
    }

    .form-group {
        margin-top: 20px;
        color: $brand-textGrayColorLight;

        .error-msg-position {
            margin-left: 16px;
            margin-top: 2px;
        }

        .input-type {
            margin-top: 16px;
        }

        .input-box {
            outline: none;
            padding: 12px;
            border-radius: 24px;
            border: 1px solid $brand-textBlueColor;
            background-color: $brand-blackColor;
            margin: 4px 0px 0px 0px;
            color: $brand-textColor;
            width: 36%;
            padding-left: 16px;
        }

        .validation-msg {
            font-size: 16px;
            margin-top: 20px;

            div {
                color: white;
            }

            .pin_contents {
                .pin_content {
                    @include Flex(row, start, flex-start);
                    margin-top: 4px;

                    .check_icon {
                        background-color: $brand-secondaryColor;
                        border-radius: 50%;
                        margin: 4px 8px 0px 6px;
                        color: $brand-blackColor;
                    }

                    p {
                        font-size: 14px;
                        color: #c1c1c1;
                    }
                }
            }
        }

        .btn-container {
            display: flex;
            margin-bottom: 5px;

            .button {
                box-shadow: none;
                margin-top: 24px;
                height: 40px;
                font: normal normal 500 16px/21px $brand-fontFamily;
                border-radius: 24px;
                padding: 0px 5px;
                cursor: pointer;
                font-weight: 500 !important;
            }

            .update-btn {
                width: 250px;
                margin-right: 16px;
                background-color: $brand-secondaryColor;
                border: $brand-secondaryColor;
                color: $brand-blackColor;
            }

            .back-btn {
                width: 200px;
                background-color: $brand-textBlueColorLight;
                border: $brand-blackColor;
                color: $brand-textColor;
            }
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .password-content {
        font-size: 14px;

        .form-group {
            .input-box {
                width: 100%;
            }

            .input-container {
                font-size: 12px;
            }

            .validation-msg {
                div {
                    font-size: 14px;
                }

                p {
                    font-size: 12px;
                }
            }

            .btn-container {
                display: block;

                .button {
                    font: normal normal 600 16px/21px $brand-fontFamily;
                    margin-top: 14px;
                }

                .update-btn {
                    width: 100%;
                }

                .back-btn {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and #{$mediaQueryRange-tabletDevice} {
    .password-content {
        .form-group {
            .input-box {
                width: 48%;
            }
        }
    }
}