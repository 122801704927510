.gameScreen_frame {
    width: 100%;
    height: 889px;
    border: none;
}

.pick6_gameScreen_frame {
    width: 100%;
    height: 889px;
    border: none;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        height: 961px;
    }
}