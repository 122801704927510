.match_container {
  background-color: #183468;
  margin: 10px 0px 6px 0px;
  border-radius: 8px;
  padding-bottom: 1px;

  .match_heading {
    padding: 8px 20px;
    font-size: 20px;

    p {
      .live_text {
        padding-bottom: 8px;
        cursor: pointer;
      }

      .isActive {
        color: #f6ba23;
        border-bottom: 2px solid #f6ba23;
      }

      span {
        margin-right: 16px;
      }
    }
  }

  .hr_line {
    height: 3px;
    background-color: #071027;
  }

  .team_container {
    background-color: #11203d;
    color: white;
    font-size: 14px;
    padding: 7px 8px 0px;
    border: 1px solid #2d5fd5;
    border-radius: 6px;
    margin: 8px 0;
    width: 180px;
    //min-height: 88px;
    .kickOff {
      color: $kickOff_txtColor;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .team_card_details {
      color: $kickOff_txtColor;
      padding-top: 8px;
      .team_card {
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        padding-bottom: 2px;
        display: flex;

        .team_Logo_Name {
          display: flex;
          justify-content: start;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0 3px 8px 0;
          .team-icontext {
            display: flex;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 3px;
          }
          img {
            width: 18px;
            height: 18px;
            margin-right: 4px;
          }

          .fixture_name {
            color: $teamCard_txtColor;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
          }
        }
        .live_score {
          color: $kickOff_txtColor;
          padding-bottom: 8px;
        }
      }
    }

    .team_card:nth-child(1) {
      color: #9b9b9b;
    }
  }

  @media screen and #{$mediaQueryRange-mobileDevice} {
    .match_heading {
      font-size: 14px;
    }

    .team_container {
      font-size: 12px;

      .team_card {
        div {
          img {
            width: 12px;
          }
        }
      }
    }
  }
}

.trending_card_section {
  margin-left: 10px;
  .LiveUpcomingSplider {
    overflow: hidden;
  }
  span {
    display: none;
  }
  .splide__pagination {
    display: none;
  }
  .splide__list {
    display: flex;
  }
  .card_wrapper {
    background: url("../../images/png/matchCardbg.png") no-repeat;

    background-position: center;
    height: 174px;
  }

  .match_card {
    padding: 20px;
    flex-grow: 1;
    margin: 0 auto;
  }

  .bottom_section {
    @include Flex(row, flex-start, space-between);
    text-align: center;

    .liveIcon {
      width: 40px;
    }
    .completed_matches {
      font-size: 12px;
      margin-top: 8px;
      background-color: rgb(24, 201, 33);
      border-radius: 20px;
      padding: 0 10px;
    }

    p {
      color: #fe8163;
      margin: 0;
    }
  }

  .teamImg {
    width: 65px;
    @media screen and #{$mediaQueryRange-mobileDevice} {
      width: 55px !important;
      margin: 0 18px;
    }
    .opponent_logo {
      margin: 10px 0;
      width: 100%;
    }
  }

  .top_section {
    @include Flex(row, center, center);

    .match_title {
      font-size: 14px;
      background-color: #fe8163;
      padding: 0 7px;
      border-radius: 10px;
    }
  }
  .msgBox {
    padding: 10px 10px 10px 0px;
  }
}

.msgBox {
  padding: 10px;
  @media screen and #{$mediaQueryRange-desktopDevice} {
    padding: 30px 10px;
  }
  .msgBox-container {
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }
  &.error {
    .msgBox-container {
      background-color: $msgBox-container_backGroundColor;
      color: $msgBox-container_txtColor;
    }
  }
  // &.success{
  //     .msgBox-container{
  //         background-color: #FFD6D6;
  //         color: #FF3A3A;
  //     }
  // }
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.marquee-content {
  display: inline-block;
  padding: 0 4px 0 0;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee-scroll 50s linear infinite;
}

@keyframes marquee-scroll {
  0% {
    transform: translateX(1%);
  }
  100% {
    transform: translateX(-65%);
  }
}
