.faq_wrapper {
  .faq_head {
    .title {
      text-align: center;
      margin: 12px 0;
      font-weight: $brand-fontWeight;
      font-size: $brand-fontSizeWebPageHeading;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: $brand-fontSizeMobilePageHeading;
      }
    }
  }

  .faq_Accordion {
    .cric-accordion {
      // border: 0.5px solid #C1C1C1;
      background: #0f2651 0% 0% no-repeat padding-box;

      .cric-Accordion-header {
        padding: 12px 18px;
        background: #0f2651 0% 0% no-repeat padding-box;
        //box-shadow: 0px 4px 12px;

        .cric-sidePanelList-item-content {
          font-size: $brand-fontSizeWebAccordHeading;

          @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: $brand-fontSizeMobileAccordHeading !important;
          }
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
          padding: 8px 14px;
        }
      }

      .cric-accordion-content {
        padding: 0px 14px 10px 18px;
        background: #0f2651 0% 0% no-repeat padding-box;
        font-size: $brand-fontSizeAccordContaint;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          padding: 8px 14px;
        }
      }
    }
  }
}

.line-gap {
  line-height: 20px;

  p {
    padding-bottom: 10px;
    font-size: 14px;
  }
}

.hyper-link {
  color: $brand-secondaryColor;
}

.direct-link {
  color: #f8c84f;
}

.text-bolt {
  font-weight: 500;
}