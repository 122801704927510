.contact-content {
    min-height: 50dvh;

    .title {
        font-weight: $brand-fontWeight;
        color: $brand-secondaryColorLight;
        margin: 20px 0px;
        text-align: center;
        font-size: $brand-fontSizeWebPageHeading;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: $brand-fontSizeMobilePageHeading !important;
            margin: 16px 0px !important;
        }
    }

    .heading {
        margin: 16px 0px;
        font-size: $brand-fontSizeWebAccordHeading;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: $brand-fontSizeMobileAccordHeading;
        }
    }

    .hr-line {
        border: 1px solid gray;
        opacity: 0.5;
    }

    .offer {
        margin-top: 12px;
        color: $brand-textGrayColor;
        display: flex;
        align-items: center;

        .offer-info {
            width: 952px;
            padding-right: 5px;
        }

        input {
            position: relative;
            cursor: pointer;
            width: 0;
        }

        input:before {
            content: "";
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            border-radius: 4px;
            background-color: $brand-secondaryColorLight;
        }

        input:checked:after {
            content: "";
            display: block;
            width: 4px;
            height: 8px;
            border: solid $brand-blackColor;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(44deg);
            -ms-transform: rotate(44deg);
            transform: rotate(44deg);
            position: absolute;
            top: 2px;
            left: 5px;
        }
    }

    .success-msg {
        color: #5afc0f;
        font-size: 14px;
        margin-top: 24px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            text-align: center;
        }

    }

    .btn-container {
        margin-bottom: 8px;
        display: flex;

        .button {
            box-shadow: none;
            margin-top: 14px;
            width: 220px;
            height: 40px;
            font-size: 16px;
            border-radius: 28px;
            cursor: pointer;
            font-weight: 500;
        }

        .update-btn {
            margin-right: 16px;
            background-color: $brand-secondaryColor;
            border: $brand-secondaryColor;
            color: $brand-blackColor;
        }

        .back-btn {
            background-color: $brand-textBlueColorLight;
            border: $brand-blackColor;
            color: $brand-textColor;
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .contact-content {
        .offer {
            margin-right: 20px;
            justify-content: space-between;
            font-size: 14px;
        }

        .btn-container {
            display: block;
            margin-top: 2px;

            .button {
                width: 100%;
                margin-top: 14px;
            }
        }
    }
}

@media screen and #{$mediaQueryRange-tabletDevice} {
    .contact-content {
        .offer {
            margin-right: 16px;
        }
    }
}