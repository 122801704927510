.header_recover {
    width: 100%;
    // background-color: $brand-blackColor;
    padding: 10px 0;

    .container {
        @include Flex(row, center, space-between);
        height: auto;
    }

    .back-button {
        margin-top: 18px;
    }

    .title {
        font: normal normal 500 24px/16px $brand-fontFamily;
        letter-spacing: 0px;
        color: #f6ba23;
        text-transform: capitalize;
        text-align: center;
        flex-grow: 1;
        margin: 20px 0px 20px 0px;
    }

    .recover_btn_group {
        .icon {
            font-size: 35px;
        }

        .icon_close {
            margin-left: 10px;
        }
    }
}

.recover_details_body {
    // margin: 50px auto;

    .mail_response {
        @include Flex(row, center, center);
        font-size: 18px;
        margin: 10px 0px 18px 0px;
        @media screen and #{$mediaQueryRange-mobileDevice} {
            margin: 10px 0px 10px 0px;
        }
        .check_icon {
            background-color: #00ff85;
            border-radius: 12px;
            margin: 0px 0px 8px 6px;
        }

        p {
            margin: 0px 0px 8px 6px;
            text-align: center;
            color: #03c568;
        }
    }

    .content_wrapper {
        .content_container {
            p {
                margin-bottom: 8px;
                font-size: 14px;
                text-align: justify;
            }
        }
    }

    .form_wrapper {
        max-width: 420px;
        margin-top: 10px;

        .form_container {
            .form_group {
                .phone_group {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;
                    width: 100%;
                    margin-top: 10px;
                    margin-bottom: 10px;

                    &.phone-group-dropdown {
                        position: relative;

                        // .select-dropdown-icon {
                        //     position: absolute;
                        //     top: 13px;
                        //     left: 52px;
                        //     font-size: 20px;
                        //     color: white;

                        //     @media screen and #{$mediaQueryRange-mobileDevice} {
                        //         top: 11px;
                        //         left: 47px;
                        //     }
                        // }
                    }

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        gap: 5px;
                    }

                    .country_input {
                        width: 20%;
                        background-color: #10151d;
                        border: 0.5px solid #2d5fd5;
                        padding-left: 18px;
                        border-radius: 24px;
                        height: $brand-InputHeightDesktop;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            width: 75px;
                            height: $brand-InputHeightMobile;
                        }
                    }

                    .number_input {
                        margin-top: 0 !important;
                        width: 80%;
                        padding-left: 20px;
                    }

                    input {
                        width: 100%;
                        background-color: #030814;
                        border: 0.5px solid #2d5fd5;
                        border-radius: 24px;
                        color: white;
                        font-size: 16px;
                        height: $brand-InputHeightDesktop;
                        padding-left: 20px;
                        border: $countryCode_border;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            height: $brand-InputHeightMobile;
                        }
                    }

                    select {
                        width: 100%;
                        background-color: #10151d;
                        border: 0.5px solid #2d5fd5;
                        border-radius: 24px;
                        color: white;
                        font-size: 16px;
                        height: 52px;
                        padding-left: 20px;
                        appearance: none;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            height: 40px;
                            font-size: 14px;
                        }
                    }
                }

                .signup_contant {
                    width: 100%;
                    padding: 0 10px;
                    margin-bottom: 10px;
                    text-align: center;

                    @include Flex(column, center, center);

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        padding: 0 5px;
                    }

                    .signup_option {
                        width: 100%;
                        margin-top: 15px;
                        @include Flex(row, center, space-between);
                        cursor: pointer;

                        .signup_phone {
                            padding: 20px 20px;
                            width: 50%;
                            border-bottom: 3px solid $brand-textInActiveColorLight;
                            color: $brand-textInActiveColorLight;

                            @media screen and #{$mediaQueryRange-mobileDevice} {
                                padding: 20px 0px !important;
                                font-size: 14px;
                            }
                        }

                        .signup_email {
                            width: 50%;
                            padding: 20px 20px;
                            border-bottom: 3px solid $brand-textInActiveColorLight;
                            color: $brand-textInActiveColorLight;
                        }

                        .active {
                            border-bottom: 5px solid $brand-secondaryColor;
                            color: $brand-secondaryColor;
                        }
                    }
                }

                button {
                    font-weight: 600;
                    height: $brand-InputHeightDesktop;
                    font-size: 18px;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        height: $brand-ButtonHeightMobile;
                    }
                }

                .error-msg {
                    margin: 10px auto;
                }

                &.email_group {
                    .form_input {
                        height: $brand-InputHeightDesktop;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            height: $brand-InputHeightMobile;
                        }
                    }
                }
            }
        }
    }

    .flash_container_outer {
        margin: 10px auto;
    }

    .flash_container_inner {
        line-height: 50px;
        padding: 0 20px;

        .icon {
            float: right;

            &::after {
                content: "\276F";
                color: $brand-textColor;
            }
        }
    }

    .text_cotaniner {
        p {
            margin: 10px 0;
            text-align: left;
            font: normal normal normal 16px/18px $brand-fontFamily;
        }
    }
}

.update_pin_wrapper {
    .recover_details_body .form_container .form_group p {
        margin: 4px 0px 8px 0px;
    }

    .pin_text {
        text-align: center;
        font-size: 24px;
    }

    .form_container {
        label {
            color: #c1c1c1;
            margin: 0 0 8px 0;
            font-size: 12px;
        }
    }

    .form_input {
        margin: 0 !important;
        @include Flex(row, center, flex-start);

        input {
            width: 100%;
            height: 90%;
            outline: none;
            border: none;
            background-color: transparent;
            color: white;
            font-size: 16px;
        }

        span {
            font-size: 20px;
        }
    }

    .pin_contents {
        margin-bottom: 14px;

        .pin_content {
            @include Flex(row, center, flex-start);

            .check_icon {
                background-color: #f8c84f;
                border-radius: 12px;
                margin: 2px 8px 0px 6px;
            }

            p {
                font-size: 14px;
                color: #c1c1c1;
            }
        }
    }
}

.pinSet_wrapper {
    .container {
        @include Flex(row, center, center);

        .pinSet_image {
            margin: 169px 0px 30px 0px;
            text-align: center;
        }

        .pinSet_text {
            text-align: center;
            margin-bottom: 30px;
            border-width: 1px;
            font-size: 16px;
        }

        .login-back-btn {
            font: normal normal 600 16px/21px $brand-fontFamily;
            background-color: $brand-secondaryColor;
            border: #f6ba23;
            color: #000000;
            border-radius: 24px;
            opacity: 1;
            cursor: pointer;
            outline: none;
            box-shadow: none;
            width: 100%;
            height: 40px;
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .container {
        .title {
            font-size: 20px;
            margin: 10px 0px 10px 0px;
        }

        .recover_btn_group {
            .icon {
                font-size: 28px;
            }
        }

        .recover_details_body {
            // margin: auto;
            .content_wrapper {
                .content_container {
                    p {
                        margin-bottom: 5px;
                        font-size: 13px;
                    }
                }
            }

            .flash_container_outer {
                .flash_container_inner {
                    font-size: 14px;
                }
            }

            .text_cotaniner {
                max-width: 420px;
                margin: auto;

                p {
                    font-size: 80%;
                }
            }

            .form_wrapper {
                margin: 0px auto;

                .form_container {
                    .form_group {
                        .form_input {
                            width: 100%;
                        }

                        button {
                            width: 100%;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .header_recover {
        .back-button {
            margin-top: 48px;
        }
    }

    .update_pin_wrapper {
        button {
            margin-top: 12px;
        }

        .pin_text {
            text-align: center;
            font-size: 14px;
        }

        .pin_contents {
            margin-bottom: 12px;

            .pin_content {
                p {
                    font-size: 12px;
                    // margin-top: 4px;
                }
            }
        }

        .recover_details_body .form_container .form_group p {
            margin: 7px 0px 7px 0px;
        }
    }
}

@media screen and #{$mediaQueryRange-midMobileDevice} {
    .confirmationPin_wrapper {
        .container {
            .recover_details_body {
                .mail_response p {
                    font-size: 14px;
                }

                .content_wrapper {
                    .content_container {
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .header_recover {
        .back-button .backButton_Text {
            font-size: 12px;
            margin: 0 0px;
        }
    }
}

// 768px to 1023px
@media screen and #{$mediaQueryRange-tabletDevice} {
    .header_recover {
        .back-button {
            margin-top: 10px;
        }
    }
}

//1024px to 1279px
@media screen and #{$mediaQueryRange-lowDesktopDevice} {
    .header_recover {
        .back-button {
            margin-top: 10px;
        }
    }
}
