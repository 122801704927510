.live_table_wrapper {
    margin-top: 16px;
    font-size: 18px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: 14px;

        .table_container {
            overflow: auto;
        }
    }

    .live_table_heading {
        text-align: center;
        margin-bottom: 6px;
    }

    .table {
        width: 100%;

        .groupname {
            text-align: center;
            margin-bottom: 6px;
        }

        .head_block {
            display: flex;
            justify-content: space-between;
            text-align: start;
            align-items: center;
            background-color: #F8C84F;
            color: black;
            font-weight: bold;

            .team {
                // margin-right: 30px;
                // margin-left: 10px;
            }

            .right_border {
                border-right: 1px solid #0F2651;
            }
        }

        .space-2 {
            flex-grow: 1;
            flex-basis: 0;
            text-align: center;
            padding: 6px;
            min-width: 28px;
        }

        .team_header {
            flex-grow: 1;
            flex-basis: 0;
            text-align: center;
            padding: 6px;
            min-width: 125px;
            display: flex;
            justify-content: flex-start;
        }

        .body_block {
            display: flex;
            justify-content: space-between;
            text-align: start;
            align-items: center;
            margin-top: 3px;
            border-radius: 4px;
            background-color: #0F2651;

            @media screen and #{$mediaQueryRange-tabletDevice} {
                // margin-right: 36px;
                font-size: 12px;
            }

            .team_name {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 4px;
                min-width: 125px;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                @media screen and #{$mediaQueryRange-tabletDevice} {
                    // margin-right: 36px;

                }

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    // margin-right: 36px;

                    // .tname {
                    //     white-space: nowrap;
                    //     overflow: hidden;
                    //     text-overflow: ellipsis;
                    //     max-width: 10ch;
                    // }
                }

                .tname {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .body_block:nth-child(2n-1) {
            background-color: #10306B;
        }
    }
}

.matchstats_wrapper {
    margin-top: 20px;

    .CircularProgressbar-trail {
        stroke: #F6BA23;
    }

    .CircularProgressbar-path {
        stroke: #2152C4;
    }

    .matchstats_heading {
        text-align: center;
        border-bottom: 2px solid #F8C84F;
        color: #F8C84F;
        padding-bottom: 6px;
        font-size: 20px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: 14px;
        }
    }

    .possession {
        text-align: center;
        margin: 20px 0;
    }

    .range_block {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 35px;
        gap: 20px;

        img {
            width: 94px;
            height: 94px;
        }
    }

    .target_block {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        position: relative;

        .head_text {
            position: absolute;
            top: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 6px;
            width: 270px;
            font-size: 14px;
        }

        .white_block {
            border: 12px solid white;
            border-bottom: none;
            width: 158px;
            height: 71px;
            position: absolute;
            bottom: 0;
        }

        .left_block {
            background-color: #F6BA23;
            width: 146px;
            height: 110px;
        }

        .right_block {
            background-color: #2152C4;
            width: 146px;
            height: 110px;
        }

        .bottom_text {
            position: absolute;
            top: 56px;
            font-size: 14px;

            .bottom_value {
                display: flex;
                justify-content: space-evenly;
                margin-top: 10px;
            }
        }
    }

    .passes_wrapper {
        width: 290px;
        margin: auto;
        margin-top: 10px;

        .total_passes {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
        }

        .myProgress {
            width: 100%;
            background-color: #2152C4;
            height: 13px;
        }

        .myBar {
            // width: 38%;
            height: 13px;
            background-color: #F6BA23;
            text-align: center;
            line-height: 30px;
        }

        .btn_contain {
            display: flex;
            justify-content: center;
            margin-top: 10px;

            .more_btn {
                border-radius: 4px;
                font-weight: bold;
            }
        }
    }

    .map-Wrapper {
        // width: 350px;
        margin: auto;
        margin-top: 20px;

        .map_button {
            margin-top: 24px;
            display: flex;
            justify-content: start;
            gap: 8px;

            button {
                border-radius: 4px;
                font-weight: bold;
                width: 84px;
            }

            .active_btn {
                background-color: #183468;
                border: 1px solid #3D71EB;
                color: white;
            }
        }

        .map_block {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 16px;
            gap: 10px;

            .map_dropdown {
                border: 0.5px solid #3D71EB;
                border-radius: 6px;
                width: 100%;
                height: 36px;
                background-color: #183468;
                color: white;
                vertical-align: top;
                padding: 0 6px;
                box-shadow: none;
            }
        }

        .map_img {
            margin-top: 16px;
            height: 227px;
            text-align: center;
            margin-bottom: 26px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .game_button {
            display: flex;
            justify-content: center;
            gap: 10px;
            align-items: center;
            margin-top: 20px;

            button {
                border-radius: 4px;
                width: 105px;
                height: 54px;
            }

            .active_btn {
                background-color: #183468;
                border: 1px solid #3D71EB;
                color: white;
            }
        }

        .slider {
            -webkit-appearance: none;
            width: 100%;
            height: 16px;
            background: #F6BA23;
            outline: none;
            -webkit-transition: .2s;
            transition: opacity .2s;
        }

        .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 16px;
            height: 16px;
            background: white;
            cursor: pointer;
        }

        .slider::-moz-range-thumb {
            width: 16px;
            height: 16px;
            background: white;
            cursor: pointer;
        }
    }
}