.container {
    .promotion_banner-main {
        margin-top: 16px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            margin-top: 12px;
        }

        .promotion-body {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                // display: unset;
            }
            .promotion_banner {
                width: 100%;
                position: relative;
                display: flex;
                align-items: center;
                .content {
                    // margin: 13px 0px;
                    position: absolute;
                    text-align: center;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 50%;
                    transform: translate(0px, -50%);
                    width: 100%;

                    .sign-up-title {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $brand-secondaryColor;
                        font-size: 33px;
                        font-weight: $brand-fontWeight;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            font-size: 22px;
                        }

                        @media screen and #{$mediaQueryRange-tabletDevice} {
                            font-size: 26px;
                        }

                        @media screen and #{$mediaQueryRange-lowDesktopDevice} {
                            font-size: 30px;
                        }
                    }

                    .sign-up-jersey-text {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $brand-textColor;
                        font-size: 18px;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            font-size: 12px;
                        }

                        @media screen and #{$mediaQueryRange-tabletDevice} {
                            font-size: 14px;
                        }

                        @media screen and #{$mediaQueryRange-lowDesktopDevice} {
                            font-size: 16px;
                        }
                    }
                }
                img {
                    width: 100%;
                    border-radius: 8px;
                    height: 100%;
                }
                .promotion_banner_mobile {
                    display: none;
                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        display: block;
                    }
                }
                .promotion_banner_desktop {
                    display: block;
                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        display: none;
                    }
                }
            }
        }
    }
}
