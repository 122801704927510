.games_Feedback_wrapper {

    // @include Flex(row, center, center);
    .errormessage {
        color: #ff3a3a;
        font-size: 12px;
        margin: 4px 0px 0px 0px;
    }

    .successmesage {
        color: green;
        font-size: 12px;
        margin: 4px 12px 10px 27px;
    }

    .games_Feedback_container {
        .feedback-content {
            display: flex;

            .feedback-content-left {
                width: 40%;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 100%;
                }
            }
        }

        .games_Feedback_head {
            .title {
                margin: 12px 0;
                justify-content: flex-start;
                text-transform: capitalize;
                font-size: $brand-fontSizeWebPageHeading;
                font-weight: $brand-fontWeight;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    font-size: $brand-fontSizeMobilePageHeading;
                    margin-bottom: 5px;
                    padding-bottom: 0px;
                }
            }

            .pagetitle {
                text-align: center;
            }
        }

        .content {
            p {
                // margin: 12px 0px;
                padding: 0px 0px 12px 0px;
                font-size: 16px;
                font-weight: normal;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    padding: 0px 0px 10px 0px;
                    font-size: 14px;
                    // margin: 8px;
                    text-align: center;
                }
            }

            span {
                text-decoration: underline;
                color: #f8c84f;
            }
        }

        .box_content {
            @include Flex(row, center, flex-start);
            border: 0.5px solid #f8c84f;
            border-radius: 6px;
            margin: 12px 0px;
            padding: 8px;

            img {
                width: 29px;
                height: 29px;
            }

            .social_title {
                font-size: 16px;
                margin-left: 10px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    font-size: 16px;
                }
            }

            @media screen and #{$mediaQueryRange-mobileDevice} {
                width: 100%;
                margin: 11px 0px 0px 0px;
                border-radius: 4px;
            }
        }

        .feedback_header {
            padding: 12px 0 12px 0;
            font-size: 16px;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                padding: 10px 0px 10px 0px;
                font-size: 14px;
            }
        }

        .feedback_text {
            padding: 10px 0 14px 0;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                padding: 10px 0 2px 0;
            }
        }

        .feedback_input-body {
            .feedback_input {
                border: 0.5px solid #6a6a6a;
                border-radius: 6px;
                margin: 0px 0px 12px 0px;
                width: 100%;
                background-color: transparent;
                color: #ffffff;
                vertical-align: top;
                background: #030814 0% 0% no-repeat padding-box;
                padding: 6px 0px 0px 8px;
                font-size: 14px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    margin: 10px 0px 0px 0px;
                    width: 100%;
                    vertical-align: top;
                    overflow: hidden;
                    font-size: 12px;
                }
            }

            @media screen and #{$mediaQueryRange-mobileDevice} {
                // padding: 8px;
            }
        }

        .feedback_phone_input {
            border: 0.5px solid #6a6a6a;
            border-radius: 6px;
            width: 100%;
            background-color: transparent;
            color: $brand-textColor;
            background: #030814 0% 0% no-repeat padding-box;
            height: 30px;
            padding-left: 10px;
            font-size: 14px;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                // margin: 10px 0px 0px 0px;
                width: 100%;
                font-size: 12px;
            }
        }

        input:focus {
            outline: none;
        }

        textarea:focus {
            outline: none;
        }

        .feedback_dropdown {
            border: none;
            border-radius: 6px;
            width: 99%;
            height: 30px;
            background: #030814 0% 0% no-repeat padding-box;
            color: $brand-textColor;
            vertical-align: top;
            outline: none;
            // padding: 0px 5px;
            padding-left: 6px;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                // margin: 0px 8px;
                height: 36px;
            }
        }

        .games_Accordion {
            .games_Accordion_head {
                padding-bottom: 10px;
                padding-top: 10px;
            }

            .games_Accordion_Content {
                width: 100%;
                margin-bottom: 0px;
                font-size: 14px;
                border: 0.5px solid #6a6a6a;
                border-radius: 6px;
                background: #030814 0% 0% no-repeat padding-box;
            }

            @media screen and #{$mediaQueryRange-mobileDevice} {
                .games_Accordion_head {
                    // padding: 12px 0px 0px 0px;
                    text-align: left;
                    font-size: 14px;
                }

                .games_Accordion_Content {
                    // padding: 10px 0px 0px 0px;
                    width: 100%;
                    font-size: 12px;
                }
            }
        }

        .btn-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                text-align: center;
                justify-content: center;
                margin: 12px 0px 0px 0px;
            }

            .btn {
                &.feedback-submit-btn {
                    font-size: 16px;
                    width: 100% !important;
                    min-height: 44px !important;
                    font-weight: 600;
                }
            }
        }
    }

    .accordion_content {
        .content {
            p {
                text-align: left;
            }
        }
    }

    .cric-accordion {
        .cric-Accordion-header {
            .cric-sidePanelList-item-content {
                font-size: 14px;
            }
        }
    }
}

.games_Feedback_popup {
    display: flex;
    flex-direction: column;
    min-height: 350px;
    height: auto;

    .close_icon_wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 20px 0;
    }

    .popup_content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        padding: 60px 30px;
        @media screen and #{$mediaQueryRange-mobileDevice} {
            padding: 60px 0px;
        }
    }

    .title {
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        color: $brand-textColor;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: $brand-textColor;
        }
    }

    .content {
        text-align: center;
        font-size: 16px;
        font-weight: lighter;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            text-align: center;
            font-size: 12px;
            font-weight: lighter;
        }
    }

    .btn-wrapper {
        margin: 4px 12px 4px 20px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            margin: 4px 12px 4px 20px;
        }

        .btn {
            &.btnMedium {
                font-size: 18px;
                font-weight: 600;
                min-height: 44px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    font-size: 16px;
                }
            }
        }
    }

    @media screen and #{$mediaQueryRange-mobileDevice} {
        padding: 0 4px;
    }
}

.hyper_link {
    display: contents;
}