.store_wrapper {
    .heading_block {
        margin-top: 16px;
        margin-bottom: 12px;
        font-size: $brand-fontSizeWebHeading;
        font-weight: $brand-fontWeight;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: 16px;
            margin-top: 14px;
            margin-bottom: 10px;
        }

        .store_heading {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
                width: 20px;
                margin-right: 4px;
                vertical-align: middle;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 16px;
                }
            }
        }
    }

    .store_block {
        // background-image: url("../../images/png/storeBackgroundToWeb.png");
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: auto;
        border-radius: 10px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            // background-image: url("../../images/png/storeBackgroundToMobile.png");
        }

        position: relative;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;

        .store_banner {
            .store_banner_mobile {
                display: none;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    display: block;
                }
            }

            .store_banner_desktop {
                display: block;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    display: none;
                }
            }

            img {
                width: 100%;
                display: block;
            }
        }

        .store_contain {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            // justify-content: center;
            align-items: center;
            justify-content: center;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                justify-content: center;
                margin-left: 110px;
            }

            @media screen and #{$mediaQueryRange-midMobileDevice} {
                justify-content: center;
                margin-left: 90px !important;
            }

            @media screen and #{$mediaQueryRange-tabletDevice} {
                justify-content: flex-end;
            }

            .text_contain {
                margin-left: 235px;
                line-height: 40px;

                @media screen and #{$mediaQueryRange-midMobileDevice} {
                    margin-right: 16px !important;
                    margin-left: 0px;
                }

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    margin-right: 46px;
                    margin-left: 0px;
                    line-height: 24px;
                }

                @media screen and #{$mediaQueryRange-tabletDevice} {
                    margin-right: 180px;
                    margin-left: 0px;
                }

                .heading {
                    color: #f6ba23;
                    font-size: 60px;
                    font-weight: 500;
                    margin-bottom: 16px;

                    @media screen and #{$mediaQueryRange-midMobileDevice} {
                        font-size: 20px !important;
                        margin-top: 20px;
                        margin-bottom: 0px;
                    }

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        font-size: 38px;
                        margin-top: 20px;
                    }

                    @media screen and #{$mediaQueryRange-tabletDevice} {
                        font-size: 44px;
                        margin-bottom: 0;
                    }
                }

                .text {
                    font-size: 32px;
                    margin: 10px 0px 6px 0px;

                    @media screen and #{$mediaQueryRange-midMobileDevice} {
                        font-size: 11px !important;
                        margin: unset;
                    }

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        font-size: 20px;
                    }

                    @media screen and #{$mediaQueryRange-tabletDevice} {
                        margin: unset;
                        font-size: 24px;
                    }
                }

                .price_text {
                    font-size: 50px;
                    font-weight: 500;

                    @media screen and #{$mediaQueryRange-midMobileDevice} {
                        font-size: 17px !important;
                    }

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        font-size: 32px;
                    }

                    @media screen and #{$mediaQueryRange-tabletDevice} {
                        font-size: 37px;
                    }
                }

                .bottom-section {
                    margin-top: 12px;

                    @media screen and #{$mediaQueryRange-midMobileDevice} {
                        margin-top: 0px !important;
                    }

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        margin-top: 6px;
                    }

                    @media screen and #{$mediaQueryRange-tabletDevice} {
                        margin-top: 0px;
                    }

                    .btnLarge {
                        min-height: 44px;
                        font-size: 20px;
                        font-weight: $brand-fontWeight;
                        width: 380px;
                        border-radius: 24px;

                        @media screen and #{$mediaQueryRange-midMobileDevice} {
                            min-width: 130px !important;
                            width: 130px !important;
                        }

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            font-size: 14px;
                            min-width: 250px;
                            // min-width: 145px;
                            min-height: 36px;
                        }

                        @media screen and #{$mediaQueryRange-tabletDevice} {
                            font-size: 16px;
                            width: 280px;
                        }
                    }
                }
            }
        }
    }
}
