.otp_wrapper {
    @include Flex(column, center, flex-start);
    height: 100dvh;
    text-align: center;
    background-color: #071027; 

    .container {
        margin-top: 8%;
    }

    .card_title {
        font-size: 26px;
        color: $brand-secondaryColorLight;
    }
}

.otp_box {
    padding: 75px;
    background-color: #122a61;
    .title {
        font-size: 28px;
    }

    p {
        color: #CACACA;
        font-size: 16px;
    }

    .otp_btn {
        height: 48px;
        font-size: 18px;
        font-weight: 600;
    }

    .otp_filed_box {
        margin-top: 17px;

        input {
            width: 50px;
            height: 50px;
            margin-inline: 15px;
            background-color: #10151D;
            border: 0.5px solid #2D5FD5;
            border-radius: 5px;
            color: white;
            font-size: 16px;
            text-align: center;
        }
    }

    .resend_otp {
        margin-top: 15px;
        font-size: 15px;
        font-weight: normal;
        opacity: 90%;

        a {
            text-decoration: underline;
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .otp_wrapper {
        background-color: #071027; 
        .container {
            margin-top: 20%;

            // .sign_logo {
            //     height: 50px;
            // }
        }

        .sign_box {
            width: auto;
        }

        .otp_box {
            padding: 27px;

            .title {
                font-size: 20px;
                margin-top: 20px;
            }

            p {
                font-size: 12px;
            }

            .otp_btn {
                height: 40px;
                font-size: 14px;
            }

            .otp_filed_box {
                margin-top: 20px;

                input {
                    margin-right: 3px;
                    width: 42px;
                    height: 42px;
                }
            }

            .resend_otp {
                margin-top: 3px;
                font-size: 12px;
                opacity: 90%;
            }
        }
    }
}