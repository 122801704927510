.cmp-popUp {
    background-image: url("../../images/png/bg_cmp.png");
    background-repeat: no-repeat;
    background-color: #0f2651;
}

.modal_box_container {
    .cmp_Modal_container {
        padding: 30px;

        .cmp_Modal_Content {
            // margin: 25px 0px;
            padding: 45px 24px 20px;
            text-align: center;
            font: normal normal normal 16px/18px $brand-fontFamily;
            letter-spacing: 0px;
            color: #ffffff;

            .cmp_Modal_ContentOne {
                margin-bottom: 14px;

                span {
                    color: #f8c84f;
                }

                .pointer {
                    text-decoration: underline;
                }
            }

            .cmp_Modal_Buttons {
                .cmp_Modal_yesBtn {
                    color: black;
                    margin: 5px;
                    padding: 10px;
                    background-color: #f6ba23;
                    width: 30%;
                    border: #f6ba23;
                    border-radius: 22px;
                }

                .cmp_Modal_noBtn {
                    color: black;
                    margin: 5px;
                    padding: 10px;
                    background-color: #f6ba23;
                    border-radius: 22px;
                    width: 30%;
                    border: #f6ba23;
                }
            }
        }

        @media screen and #{$mediaQueryRange-midMobileDevice} {
            padding: 8px;

            .cmp_Modal_Content {
                margin: 25px 0px;
                padding: 18px 24px 20px;
                font: normal normal normal 13px/18px $brand-fontFamily;
            }
        }
    }
}

.coins_prizes_wrapper {
    margin-top: 6px;

    .coins_prizes_header {
        .coins_prizes_banner {
            .mobile_banner {
                width: 100%;

                img {
                    width: 100%;
                }

                &.hideMobile {
                    display: none;
                }
            }

            .desktop_banner {
                width: 100%;

                img {
                    width: 100%;
                }

                &.hideDesktop {
                    display: block;
                }
            }

            @media screen and #{$mediaQueryRange-mobileDevice} {
                margin: 0 !important;

                .mobile_banner {
                    &.hideMobile {
                        display: block;
                    }
                }

                .desktop_banner {
                    &.hideDesktop {
                        display: none;
                    }
                }
            }
        }

        .coins_prizesBanner_text {
            margin-bottom: 60px;

            .coins_prizesBanner_textHeader {
                h2 {
                    text-align: center;
                    margin-bottom: 16px;
                    font-size: 28px;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        font-size: 20px;
                    }
                }
            }

            .coins_prizesBanner_textContent {
                p {
                    margin-bottom: 6px;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        font-size: 12px;
                        color: #e6e6e6;
                    }
                }
            }
        }
    }

    .coins_prizes_body {
        .container {
            max-width: unset;
            margin: unset;
            padding: unset;
        }

        .coins_prizes_cards {
            @include Flex(row, flex-start, space-between);
            flex-wrap: wrap;
            margin-bottom: 20px;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                margin-bottom: 23px;
            }

            .card {
                width: 386px;
                height: auto;
                text-align: center;
                // margin-inline: auto;
                margin: 12px auto;

                background: #0f2651 0% 0% no-repeat padding-box;
                box-shadow: 0px 4px 12px #00000040;
                border-radius: 8px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 100%;
                    margin: 10px !important;
                }

                .card_top {
                    margin: 15px 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 171px;
                    }

                    .prize_images {
                        &.EPL_VIP_DAY_img {
                            width: 342px;
                        }
                    }
                }

                .card_content {
                    width: 342px;
                    opacity: 1;
                    text-align: start;
                    border-color: #444a57;
                    // width: 342px;
                    height: auto;
                    padding: 5px;
                    position: relative;
                    margin: -28px auto 0px;
                    padding: 20px 20px;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        width: 100%;
                    }

                    ul .arrow_list {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 0;

                        // @include Flex(row, center, center);
                        img {
                            margin: 0px 4px 20px 0px;
                        }

                        li {
                            color: #e6e6e6;
                            margin: 12px 0px 2px 10px;
                            font: normal normal normal 13px/18px $brand-fontFamily;
                            letter-spacing: 0px;
                            list-style-image: url("../../images/png/arrow.png");

                            @media screen and #{$mediaQueryRange-mobileDevice} {
                                font-size: 14px;
                            }
                        }
                    }

                    .card_content_header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .card_Content_Coins {
                            display: flex;
                            align-items: center;

                            img {
                                width: 32px;
                                height: 32px;
                                margin-right: 4px;
                            }
                        }

                        .card_header_txt {
                            color: #f8c84f;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            font: normal normal 600 16px/16px $brand-fontFamily;
                            letter-spacing: 0px;

                            @media screen and #{$mediaQueryRange-mobileDevice} {
                                flex: 0 0 40%;
                            }
                        }

                        .card_header_coins {
                            // flex: 1 1 30%;
                            display: flex;
                            align-items: center;

                            .coins_img {
                                flex: 1;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;

                                img {
                                    width: 21px;
                                    max-width: 21px;
                                }
                            }

                            .coins {
                                flex: 1;
                                color: #f8c84f;
                                font: normal normal 600 23px/43px $brand-fontFamily;
                                letter-spacing: 0px;
                                color: #ffffff;
                                opacity: 1;
                                margin-left: 4px;
                                // width: 80%;
                            }
                        }
                    }

                    .cmp_button,
                    .btn,
                    .btnLarge {
                        background-color: #f6ba23;
                        border: #f6ba23;
                        color: #000000;
                        width: 100%;
                        padding: 10px;
                        margin-top: 12px;
                        border-radius: 22px;
                        font: normal normal 600 16px/18px $brand-fontFamily;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            width: 100% !important;
                        }
                    }
                }
            }
        }

        .cmp_Accordion_footer {
            text-align: center;
            color: #acacac;
            font: normal normal normal 14px/16px $brand-fontFamily;

            span {
                a {
                    color: #f8c84f;
                    text-decoration: underline;
                }
            }

            @media screen and #{$mediaQueryRange-mobileDevice} {
                text-align: left;
                font: normal normal normal 11px/16px $brand-fontFamily;
                letter-spacing: 0px;
                color: #acacac;
            }
        }

        .cmp_accordion {
            .cric-accordion {
                background: #0f2651 0% 0% no-repeat padding-box;
                border: 1px solid #6b7991;
                margin: 10px auto;
                padding: 8px;
                border-radius: 8px;

                .cric-Accordion-header {
                    padding: 5px;
                    background: #0f2651 0% 0% no-repeat padding-box;
                    color: #e6e6e6;
                    font: normal normal normal 16px $brand-fontFamily;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        font: normal normal normal 15px/16px $brand-fontFamily;
                        // align-items: start;
                        gap: 5px;

                    }
                }

                .cric-accordion-content {
                    background: #0f2651 0% 0% no-repeat padding-box;
                    padding: 5px;

                    .accordion_sectionOne {
                        margin: unset;

                        .content {
                            a {
                                color: #f8c84f;
                            }

                            span {
                                color: #f8c84f;
                                text-decoration: underline;
                            }

                            ul {
                                margin: 0;
                                padding: 0px;

                                li {
                                    list-style: none;
                                    margin-bottom: 5px;
                                }
                            }
                        }
                    }
                }
            }

            .cmp_accordion_header {
                text-align: center;
                margin-bottom: 30px;

                p {
                    margin-bottom: 8px;
                    letter-spacing: 3px;
                    font-size: 14px;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        font-size: 12px;
                    }
                }

                div {
                    font-size: 24px;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        font-size: 22px;
                        line-height: 26px;
                    }
                }
            }

            .cmp_Accordion_Content {
                .accordion_sectionOne {
                    margin: unset;

                    .content {
                        a {
                            color: #f8c84f;
                        }

                        span {
                            color: #f8c84f;
                            text-decoration: underline;
                        }

                        ul {
                            margin: unset;

                            li {
                                list-style: none;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and #{$mediaQueryRange-mobileDevice} {
        .container {
            max-width: unset;
            margin: unset;
            padding: unset;

            .coins_prizes_body {
                .container {
                    max-width: 1440px;
                    margin: 0 auto;
                    padding: 0 12px;
                }
            }
        }
    }
}