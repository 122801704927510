.setting_container {
    min-height: 50dvh;

    .title {
        font-size: $brand-fontSizeWebPageHeading;
        text-align: center;
        color: $brand-secondaryColorLight;
        margin-bottom: 20px;
    }

    .icon {
        &::after {
            content: "\276F";
            color: $brand-textColor;
        }
    }

    .notification {
        margin: 24px;

        .switch {
            position: relative;
            display: inline-block;
            width: 32px;
            height: 20px;
            left: 72px;

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 2px solid $brand-textColor;
                -webkit-transition: .4s;
                transition: .4s;
            }

            .slider:before {
                position: absolute;
                content: "";
                top: 2px;
                left: 1px;
                height: 12px;
                width: 12px;
                background-color: $brand-textColor;
                -webkit-transition: .4s;
                transition: .4s;
            }

            input:checked+.slider {
                background-color: green;
                border: 2px solid green;
            }

            input:checked+.slider:before {
                -webkit-transform: translateX(12px);
                -ms-transform: translateX(12px);
                transform: translateX(12px);
            }

            /* Rounded sliders */
            .slider.round {
                border-radius: 36px;
            }

            .slider.round:before {
                border-radius: 48%;
            }

            input {
                width: 0;
            }
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .setting_container {

        .title {
            font-size: $brand-fontSizeMobilePageHeading;
        }

        .board_box {
            font-size: 16px !important;
        }

        .notification {
            font-size: 12px;

            .switch {
                left: 36px;
            }
        }

    }
}