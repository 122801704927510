ul li,
ol li {
    list-style: none;
    list-style-image: none;
    list-style-type: none;
}


a {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
}


.mx-2 {
    margin-right: 20px;
    margin-left: 20px;
}

.bg_gray_gradient {
    background: transparent linear-gradient(180deg, #484E5B 0%, #323947 100%) 0% 0% no-repeat padding-box;
    box-shadow: 4px 4px 4px #00000026;
    border-radius: 18px;
}

.pointer {
    cursor: pointer;
}

.ReactModal__Overlay {
    background-color: #000000ad !important;
    z-index: 50;
}

.ReactModal__Content {
    border: none !important;
    outline: none !important;
    background: none !important;
}


.underline {
    text-decoration: underline;
    cursor: pointer;
}

.brand_secondary {
    color: $brand-secondaryColorLight;
}

.form_input {
    background: #232833 0% 0% no-repeat padding-box;
    border: 0.5px solid #8B8B8B;
    border-radius: 38px;
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    color: $brand-textGrayColorLight;
}

.body-no-scroll {
    overflow: hidden;
}

.splide__track {
    height: 100%;
}

.splide {
    height: 100%;
}

.error-msg {
    color: #FF4E4E;
    font-size: 14px;
}

.success-msg {
    color: #5afc0f;
    font-size: 14px;
}

.ReactModal__Content {
    max-width: 661px;
    margin: 0 auto;
}

.d-flex {
    display: flex;
    align-items: center;
}

.justify-content-center {
    justify-content: space-between;
}

.splide__pagination__page {
    &.is-active {
        background-color: #FE8163;
    }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #10151d inset !important;
    -webkit-text-fill-color: white;
}



@media screen and #{$mediaQueryRange-mobileDevice} {
    .ReactModal__Content {
        width: 76dvw;
    }
}

// @media screen and #{$mediaQueryRange-tabletDevice} {
//     .ReactModal__Content {
//         width: 52dvw;
//     }
// }

@media screen and #{$mediaQueryRange-midMobileDevice} {
    .ReactModal__Content {
        width: 84dvw;
    }
}



    .react-datepicker__day.react-datepicker__day--keyboard-selected {
        background: none !important;
        // color: black;
    }
    // .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--today{
    //     background-color: black !important;
    // }
