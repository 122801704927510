.line_wrapper {
    background-color: white;
    width: 100%;

    .black_line {
        height: 3px;
        background-color: #071027;
    }

    .red_line {
        height: 3px;
        background-color: #ea2524;
        margin-top: 2px;
    }

    .green_line {
        height: 3px;
        background-color: #045d04;
        margin-top: 2px;
    }
}

.banner_container {
    // border-radius: none !important;
    // padding: none;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        padding: 0px !important;
    }
}

.banner_wrapper {
    width: 100%;
    //height: 450px;
    // margin-top: 8px;
    overflow: hidden;

    .splide__list {
        display: flex;
    }

    .bannerSplider {
        overflow: hidden;
    }

    span {
        display: none;
    }

    @media screen and #{$mediaQueryRange-mobileDevice} {
        // height: 208px;
        ul {
            li {
                width: 100%;
                // padding: 0 12px;

                .homepage_banner {
                    width: 100%;
                }
            }
        }
    }

    @media screen and #{$mediaQueryRange-tabletDevice} {
        ul {
            li {
                width: 50%;
            }
        }
    }

    .homepage_banner {
        // width: 100%;
        // width: 466.5px;
        height: 100%;
        position: relative;

        // @media screen and #{$mediaQueryRange-mobileDevice} {
        //     width: 375px;
        // }

        // @media screen and #{$SB-mediaQueryRange-tabletDevice} {
        //     width: 366px;
        // }

        .video_icon {
            position: absolute;
            z-index: 10;
            //width: 60px;
            //height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            right: 0;
            top: 50%;
            bottom: 0;
            margin: 0 auto;
            transform: translateY(-50%);

            img {
                height: 60px;

                @media screen and #{$SB-mediaQueryRange-tabletDevice} {
                    height: 50px;
                }

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    height: 41px;
                }

                @media screen and #{$mediaQueryRange-midMobileDevice} {
                    height: 31px;
                }

                @media screen and #{$mediaQueryRange-smallMobile} {
                    height: 31px;
                }
            }
        }

        .team_name {
            position: absolute;
            z-index: 10;
            width: 100%;
            bottom: 0;
            display: flex;
            justify-content: start;
            align-items: end;

            //padding: 2px 6px;
            @media screen and #{$mediaQueryRange-mobileDevice} {
                font-size: 14px;
            }
        }

        .fixture_name {
            // background-color: rgba(0, 0, 0, 0.6);
            background: transparent linear-gradient(180deg, #2a2a2a00 0%, #2a2a2ab3 43%, #000000cc 100%) 0% 0% no-repeat
                padding-box;
            opacity: 1;
            width: 100%;
            // padding: 2px 6px;
            padding: 18px 6px 8px 6px;

            &.content_banner {
                background: none;
            }
        }
        .banner_container {
            position: relative;
            // height: 100%;
            margin-top: 7px;

            img {
                // width: 100%;
                height: 245px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    height: auto;
                    width: 100%;
                    object-fit: cover;
                    object-position: top center;
                    aspect-ratio: 16/9;
                }

                @media screen and #{$mediaQueryRange-tabletDevice} {
                    height: auto;
                    width: 100%;
                    object-fit: cover;
                    object-position: top center;
                    aspect-ratio: 16/9;
                }
            }
        }
    }

    .banner_text {
        color: #fe8163;
        font-size: 80px;
        position: absolute;
        top: 60px;
        right: 150px;
        font-family: "Oswald", sans-serif;

        @media screen and #{$mediaQueryRange-tabletDevice} {
            right: 112px;
            font-size: 48px;
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
            right: 50px;
            top: 18px;
            font-size: 38px;
        }

        @media screen and #{$mediaQueryRange-midMobileDevice} {
            right: 22px;
            font-size: 30px;
        }

        @media screen and #{$mediaQueryRange-smallMobile} {
            right: 12px;
            font-size: 22px;
        }

        span {
            font-size: 60px;
            color: white;

            @media screen and #{$mediaQueryRange-tabletDevice} {
                font-size: 28px;
            }

            @media screen and #{$mediaQueryRange-mobileDevice} {
                font-size: 20px;
            }

            @media screen and #{$mediaQueryRange-midMobileDevice} {
                font-size: 16px;
            }

            @media screen and #{$mediaQueryRange-smallMobile} {
                font-size: 12px;
            }

            span {
                color: #fe8163;
            }
        }
    }

    .splide__pagination {
        bottom: -20px;
    }
}
