@import "../mixins/mixins";

.footerlinks_container {
    width: 100%;
    background: transparent linear-gradient(180deg, #183468 0%, #152f61 17%, #092048 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: 26px;

    .content_section {
        padding-top: 50px;
        @include Flex(column, center, center);
        text-align: center;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            padding-top: 24px;
            width: 130px;
            margin: auto;
        }

        img {
            width: 100%;
            height: 100%;
        }

        .content {
            margin-top: 10px;
            width: 70%;
            color: #858585;
        }
    }

    .links_section {
        @include Flex(row, start, center, 25%);
        margin: 25px;
        font-size: 16px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            margin: 6px;
            font-size: 14px;
        }

        .title {
            color: #fed540;
            font-size: 18px;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                font-size: 16px;
            }
        }

        p {
            margin: 15px auto;
            color: #858585;
            font-weight: normal;
            text-decoration: underline;
        }
    }

    .copyright {
        text-align: center;
        padding-bottom: 20px;
        font-size: 16px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            padding-bottom: 20px;
            font-size: 14px;
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .mobile_footer {
        display: block !important;
    }
}

// Mobile footer
.mobile_footer {
    display: none;
    // position: fixed;
    bottom: 0;
    z-index: 9;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;

    .footer_content {
        margin-top: 6px;

        .footer-menu-dropdown {
            position: absolute;
            background: #0f2651 0% 0% no-repeat padding-box;
            box-shadow: 0px 4px 12px #00000040;
            width: auto;
            font-size: 18px;
            border-radius: 7px;
            // display: none;
            overflow: hidden;
            z-index: 99;
            white-space: nowrap;

            ul {
                display: inline-block;

                li {
                    @include Flex(row, center, flex-start);
                    padding: 7px 8px;
                    // margin-top: 10px;
                    width: 100%;

                    .icon_container {
                        width: 21px;
                        display: grid;
                        place-items: center;

                        img {
                            width: 100%;
                        }
                    }

                    .text_container {
                        font-size: 12px;
                        margin-left: 12px;
                    }

                    &:hover {
                        cursor: pointer;
                        background: #030814 0% 0% no-repeat padding-box;
                    }
                }
            }
        }

        .footer_list {
            border-radius: 6px;
            display: flex;
            background-color: #183468;
            justify-content: space-evenly;
            // align-items: center;

            .footer_list_items {
                display: flex;
                justify-content: center;
                // margin: 3px 10px;
                padding: 8px 14px;
                align-items: center;
                flex-direction: column;
                border-radius: 5px;
                width: 100%;

                @media screen and #{$mediaQueryRange-midMobileDevice} {
                    padding: 8px 4px 6px 4px;
                }

                span {
                    font-size: 12px;
                    // color: #416bb8;
                    // background-color: #3854af;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .dropdown-menu {
                    position: relative;

                    &.active {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .select-dropdown-icon {
                        position: absolute;
                        right: -11px;

                        img {
                            height: 5px;
                            padding-left: 3px;
                        }
                    }
                }

                .footer_Card {
                    width: 20px;
                    height: 20px;
                    // display: flex;
                    align-items: center;
                    justify-content: center;

                    &.games_icon {
                        width: 24px;
                    }

                    img {
                        // width: 100%;
                        // color: #416bb8;
                        height: 20px;

                        &.active {
                            // color: #f8c84f;
                        }
                    }
                }

                &.active {
                    // border-bottom: 2px solid $brand-secondaryColorLight;
                    background-color: #2152c4;

                    span {
                        color: $brand-secondaryColorLight;
                    }
                }
            }
        }
    }
}