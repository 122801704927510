.highlight_main_block {
    .highlight_heading {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        margin-bottom: 12px;
        font-size: $brand-fontSizeWebHeading;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: $brand-fontSizeMobileAccordHeading;
            margin-top: 14px;
            margin-bottom: 10px;
        }

        .heading_block {
            font-weight: $brand-fontWeight;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
                width: 20px;
                margin-right: 4px;

                @media screen and #{$mediaQueryRange-tabletDevice} {
                    width: 18px;
                }

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 16px;
                }
            }
        }

        .viewallhighlights_link {
            display: flex;
            justify-content: center;
            align-items: center;

            .viewall_txt {
                display: flex;
                align-items: center;
                font-size: 20px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    font-size: 12px;
                }
            }

            .viewall_btn {
                color: $brand-blackColor;
                background-color: $brand-secondaryColor;
                border-radius: 5px;
                margin-left: 3px;
                height: 20px;
                width: 21px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    border-radius: 3px;
                    height: 12px;
                    width: 12px;
                }
            }
        }
    }

    .highlight_wrapper {
        //display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 10px;

        .splide__list {
            display: flex;
        }

        .HighlightSplider {
            overflow: hidden;
        }

        span {
            display: none;
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {

            // height: 208px;
            ul {
                li {
                    // width: 32.2%;
                    width: 49%;
                    // padding: 0 12px;

                    .homepage_banner {
                        width: 100%;
                    }
                }
            }
        }

        @media screen and #{$mediaQueryRange-tabletDevice} {
            ul {
                li {
                    // width: 24.2%;
                    width: 32.5%;
                }
            }
        }

        .highlight_block {
            // width: 466.5px;
            height: 100%;
            position: relative;

            // @media screen and #{$mediaQueryRange-tabletDevice} {
            //     width: 242px;
            // }

            // @media screen and #{$mediaQueryRange-mobileDevice} {
            //     width: 110px;
            // }

            .highlights_Video {
                position: relative;

                .video_icon {
                    position: absolute;
                    //z-index: 10;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    //align-items: center;
                    transform: translateY(50%);
                    top: -28px;
                    z-index: 3;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        top: -12px;
                    }

                    img {
                        width: 60px;
                        height: 60px;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
           
            img {
                height: 183px;
                border-radius: 6px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    height: auto;
                }

                @media screen and #{$mediaQueryRange-tabletDevice} {
                    height: auto;
                }
            }

            .team_name {
                font-size: 16px;
                margin-top: 6px;
                color: #9bb9ff;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 93%;

                @media screen and #{$mediaQueryRange-tabletDevice} {
                    font-size: 16px;
                }

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    font-size: 12px;
                    margin-top: 3px;
                }
            }

            .date {
                font-size: 12px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    font-size: 10px;
                }
            }
        }

        .group_block {
            border: 1px solid #183468;
            background-color: #183468;
            border-radius: 8px;
            width: 450px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                padding: 6px 0;
            }

            .group_name {
                margin: 6px 0px;
                font-size: 16px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    margin: 4px 0px;
                    font-size: 10px;
                }
            }

            .main_group_img {
                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 34px;
                    height: 34px;
                }
            }

            .img_group {
                img {
                    width: 20px;
                    margin-left: -5px;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        width: 16px;
                    }
                }
            }

            .join_btn {
                width: 100px;
                height: 30px;
                font-weight: 800;
                margin-top: 6px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 73px;
                    height: 20px;
                    margin-top: 4px;
                    font-size: 12px;
                }
            }
        }
    }

    .banner_wrapper {
        width: 100%;
        height: 60px;
        margin-top: 50px;

        @media screen and #{$mediaQueryRange-tabletDevice} {
            height: 50px;
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
            height: 40px;
            margin-top: 40px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}