@import "../mixins/mixins";

.error_message {
  color: red;
  font-size: 14px;
  padding: 0px 0px 0px 20px;
  width: 100%;
  text-align: start;
}

.signup_wrapper {
  background-color: #071027;
  height: 100dvh;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  text-align: center;
  @include Flex(column, center, flex-start);

  @media screen and #{$mediaQueryRange-mobileDevice} {
    background-image: none !important;
    height: auto;
  }

  @media screen and #{$mediaQueryRange-midMobileDevice} {
    background-image: none !important;
    height: 100dvh;
  }

  .signup_container {
    margin-top: 8%;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      margin-top: 15%;
      @include Flex(column, center, center);

      .signup_logo {
        height: 50px;
      }
    }

    .signUpBackground {
      margin-top: 44px;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        display: block;
        width: 90vw;

        img {
          width: 100%;
        }
      }
    }
  }

  .sign_title {
    font-size: 28px;
    font-weight: 900;
    color: $brand-secondaryColor;
    margin-top: 20px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      margin-top: 10px;
      font-size: 20px;
    }
  }

  p {
    margin: 17px 0 0 0;
    font-size: 18px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      margin-bottom: 4px;
      margin-top: 5px;
      font-size: 14px;
    }
  }

  .coin-gain-text {
    color: #a6bae7;
  }

  span {
    font-size: 18px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      font-size: 14px;
    }
  }

  .signup_box {
    width: 502px;
    background-color: #122a61;
    border-radius: 28px;
    opacity: 1;
    margin: 32px 0 0 0;
    @include Flex(column, center, center, 15px);

    @media screen and #{$mediaQueryRange-mobileDevice} {
      width: 420px;
      margin-bottom: 48px;
    }

    @media screen and #{$mediaQueryRange-midMobileDevice} {
      width: 100%;
    }

    .signup_content {
      width: 100%;
      padding: 0 30px;

      @include Flex(column, center, center);

      @media screen and #{$mediaQueryRange-mobileDevice} {
        padding: 0 20px;
      }

      .signup_option {
        width: 100%;
        margin-top: 15px;
        @include Flex(row, center, space-between);
        cursor: pointer;

        .signup_phone {
          padding: 20px 20px;
          width: 50%;
          border-bottom: 3px solid $brand-textInActiveColorLight;
          color: $brand-textInActiveColorLight;

          @media screen and #{$mediaQueryRange-mobileDevice} {
            padding: 22px 0px !important;
            font-size: 14px;
            width: 50%;
          }
        }

        .signup_email {
          width: 50%;
          padding: 20px 20px;
          border-bottom: 3px solid $brand-textInActiveColorLight;
          color: $brand-textInActiveColorLight;
        }

        .active {
          border-bottom: 5px solid $brand-secondaryColor;
          color: $brand-secondaryColor;
        }
      }

      .form_group {
        width: 100%;
        @include Flex(column, center, center, 15px);

        @media screen and #{$mediaQueryRange-mobileDevice} {
          gap: 6px;
        }

        &.email_group {
          margin-top: 35px;

          @media screen and #{$mediaQueryRange-mobileDevice} {
            margin-top: 30px;
          }
        }

        .phone_group {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          width: 100%;
          margin-top: 35px;

          &.phone-group-dropdown {
            position: relative;

            // .select-dropdown-icon {
            //   position: absolute;
            //   top: 16px;
            //   left: 60px;
            //   font-size: 20px;
            //   color: white;

            //   @media screen and #{$mediaQueryRange-mobileDevice} {
            //     top: 11px;
            //     left: 47px;
            //   }
            // }
          }

          @media screen and #{$mediaQueryRange-mobileDevice} {
            margin-top: 30px;
          }

          .country_input {
            width: 20%;
            margin-right: 10px;
            padding-left: 20px;
            border-radius: 26px;
            border: $countryCode_border;

            @media screen and #{$mediaQueryRange-mobileDevice} {
              padding-left: 18px;
              width: 75px;
              margin-right: 5px;
            }
          }

          .number_input {
            width: 80%;
            padding-left: 20px;
            border: $countryCode_border;
          }
        }

        input {
          width: 100%;
          background-color: #030814;
          border: 0.5px solid #030814;
          border-radius: 24px;
          color: white;
          font-size: 16px;
          height: $brand-InputHeightDesktop;
          padding-left: 20px;
          border: $countryCode_border;

          @media screen and #{$mediaQueryRange-mobileDevice} {
            height: $brand-InputHeightMobile;
          }
        }

        select {
          width: 100%;
          background-color: #030814;
          border: 0.5px solid #030814;
          border-radius: 24px;
          color: white;
          font-size: 16px;
          height: $brand-InputHeightDesktop;
          padding-left: 20px;
          appearance: none;

          @media screen and #{$mediaQueryRange-mobileDevice} {
            height: $brand-InputHeightMobile;
            font-size: 14px;
          }
        }

        .btn {
          width: 100%;
          margin-bottom: 40px;
          height: $brand-ButtonHeightDesktop;
          font-size: 17px;
          font-weight: 600;
          border-radius: 28px;
          margin-top: 8px;

          @media screen and #{$mediaQueryRange-mobileDevice} {
            height: $brand-ButtonHeightMobile;
          }
        }
      }
    }
  }
}