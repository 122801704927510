.sign_wrapper {
  background-color: #071027;
  height: 100dvh;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  text-align: center;
  @include Flex(column, center, flex-start);

  .container {
    @media screen and #{$mediaQueryRange-mobileDevice} {
      width: 88%;
    }

    @media screen and #{$mediaQueryRange-midMobileDevice} {
      width: 100%;
    }

    .signin_content {
      margin: 70px 0px 0px 0px;
    }

    .password_contain {
      margin-top: 50px !important;
    }
  }

  .card_title {
    font-size: 26px;
    color: #f8c84f;
    font-weight: 900;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      font-size: 18px;
    }
  }

  .signUpBackground {
    display: none;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      width: 90vw;

      img {
        width: 100%;
      }
    }
  }
}

.pin_contents {
  .pin_content {
    @include Flex(row, center, flex-start);
    margin-top: 9px;
    text-align: start;

    .check_icon {
      background-color: #f8c84f;
      border-radius: 50%;
      margin: 6px 8px 0px 6px;
      color: #030814;
    }

    p {
      font-size: 14px;
      color: #c1c1c1;
    }
  }
}

.signin_title {
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 28px;
  font-weight: 900;
  color: $brand-secondaryColor;

  @media screen and #{$mediaQueryRange-mobileDevice} {
    margin-top: 20px;
    font-size: 22px;
  }
}

.sign_box {
  width: 552px;
  background: transparent linear-gradient(180deg, #183468 0%, #152f61 17%, #092048 100%) 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  margin: 24px 0 0 0;
  height: auto;
  padding-bottom: 36px;
  @include Flex(column, center, center, 15px);

  @media screen and #{$mediaQueryRange-mobileDevice} {
    width: auto;
    margin: 30px 0 0 0;
  }

  .error-msg-position {
    padding-left: 20px;
    padding-top: 2px;
  }

  .signup_contant {
    width: 100%;
    padding: 0 10px;

    @include Flex(column, center, center);

    @media screen and #{$mediaQueryRange-mobileDevice} {
      padding: 0 5px;
    }

    .signup_option {
      width: 100%;
      margin-top: 15px;
      @include Flex(row, center, space-between);
      cursor: pointer;

      .signup_phone {
        padding: 20px 20px;
        width: 50%;
        border-bottom: 3px solid $brand-textInActiveColorLight;
        color: $brand-textInActiveColorLight;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          padding: 20px 0px !important;
          font-size: 16px;
        }
      }

      .signup_email {
        width: 50%;
        padding: 20px 20px;
        border-bottom: 3px solid $brand-textInActiveColorLight;
        color: $brand-textInActiveColorLight;
      }

      .active {
        border-bottom: 5px solid $brand-secondaryColor;
        color: $brand-secondaryColor;
      }
    }
  }

  .form_group {
    position: relative;
    margin: 0 auto;
    width: 80%;
    @include Flex(column, baseline, space-evenly);

    input {
      width: 100%;
      background-color: #030814;
      border: $countryCode_border;
      border-radius: 24px;
      color: white;
      font-size: 16px;
      height: $brand-InputHeightDesktop;
      padding-left: 20px;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        height: $brand-InputHeightMobile;
      }
    }

    .phone_group {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      width: 100%;
      margin-top: 20px;

      &.phone-group-dropdown {
        position: relative;

        // .select-dropdown-icon {
        //   position: absolute;
        //   top: 16px;
        //   left: 57px;
        //   font-size: 20px;

        //   @media screen and #{$mediaQueryRange-mobileDevice} {
        //     top: 13px;
        //     left: 47px;
        //   }
        // }
      }

      @media screen and #{$mediaQueryRange-mobileDevice} {
        justify-content: space-between;
        gap: 5px;
      }

      .country_input {
        width: 20%;
        background-color: #030814;
        padding-left: 18px;
        border-radius: 24px;
        height: $brand-InputHeightDesktop;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          width: 75px;
          height: $brand-InputHeightMobile;
        }
      }

      .number_input {
        margin-top: 0 !important;
        width: 80%;
        padding-left: 20px;
      }

      select {
        width: 100%;
        background-color: #030814;
        border: $countryCode_border;
        border-radius: 24px;
        color: white;
        font-size: 16px;
        height: 52px;
        padding-left: 20px;
        appearance: none;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          height: 40px;
          font-size: 14px;
        }
      }
    }

    input:focus {
      outline: none;
    }

    .form_signin_input {
      position: relative;
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      height: 46px;

      .signin_pass {
        margin: 0;
      }
    }

    .signin_eye {
      position: absolute;
      // top: 32px;
      right: 18px;
      // font-size: 20px;
      margin-top: 6px;
    }

    .btn {
      margin-top: 20px;
      margin-bottom: 16px;
      width: 100%;
      // font-weight: bold;
      font-size: $brand-fontSizeWebAccordHeading;
      font-weight: 800;
      height: $brand-ButtonHeightDesktop;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        height: $brand-ButtonHeightMobile;
      }
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 4px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      font-size: 12px;
    }

    span {
      color: #fbdd91;
      text-decoration: underline;
    }
  }
}

.password_wrapper {
  background-color: #122a61;
  border-radius: 28px;
  opacity: 1;
  margin: 28px 0 0 0;
  height: auto;
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex: 1 0 350px;
  padding: 40px 30px;

  @media screen and #{$mediaQueryRange-mobileDevice} {
    gap: 20px;
    width: 100%;
    padding: 40px 15px;
    border-radius: 12px;
  }

  .form_input {
    @include Flex(row, center, flex-start);
    height: 52px;
    padding: 0px;
    position: relative;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      height: 40px;
    }

    input {
      width: 100%;
      // height: 100%;
      outline: none;
      border: $countryCode_border;
      padding: 0px 20px;
      background-color: #030814;
      color: white;
      font-size: 16px;
      border-radius: 24px;
    }

    span {
      font-size: 20px;
      position: absolute;
      right: 0;
      // padding-right: 15px;
      margin-right: 12px;
      display: flex;
      align-items: center;
    }
  }

  button {
    height: 52px;
    font-size: 18px;
    font-weight: 700;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      height: 40px;
      font-size: 14px;
      font-weight: 700;
    }
  }
}