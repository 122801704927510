.dropdown_wrapper {

    .dropdown_button {
        width: 86px;
        background-color: #030814;
        border: $countryCode_border;
        border-radius: 24px;
        color: white !important;
        font-size: 16px;
        height: 52px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        cursor: default !important;
        padding-left: 6px;
        font-weight: normal !important;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            height: 46px;
            font-size: 14px;
            width: 70px;
        }

        .select-dropdown-icon {
            font-size: 20px;
            margin-top: 4px;
        }
    }

    .custom-dropdown {
        width: 86px;
        position: absolute;
        z-index: 10;
        background-color: #030814;
        border: $countryCode_border;
        cursor: default !important;
        font-size: 16px;
        text-align: center;
        border-radius: 4px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: 14px;
            width: 70px;
        }

        .dropdown-option {
            margin-top: 2px;
            margin-bottom: 2px;
        }

        .dropdown-option:hover {
            background-color: #2152C4;
        }

        .active {
            background-color: #2152C4;
        }
    }
}