.commentary_wrapper {
    font-size: 16px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: 14px;
    }

    .commentary_contain {
        margin-top: 10px;
    }

    .player_block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #183468 0% 0% no-repeat padding-box;
        padding: 8px 10px;
        margin-top: 10px;

        .player_info {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 6px;
            margin-top: 6px;

            .avtar {
                background-color: black;
                border-radius: 50%;

                img {
                    width: 24px;
                    height: 24px;
                }
            }

            .left_icon {
                font-size: 26px;
                color: #379824;
            }

            .right_icon {
                font-size: 26px;
                color: #DB1010;
            }
        }

        .team_name {
            text-align: center;

            img {
                width: 28px;
                height: 28px;
                margin-bottom: 2px;
            }
        }
    }

    .button {
        text-align: center;

        .view_button {
            margin-top: 16px;
            font-weight: $brand-fontWeight;
            height: 40px;
            width: 160px;
            font-size: 16px;
        }
    }
}

.top_player_wrapper {
    font-size: 16px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: 14px;
    }

    .top_heading {
        color: #F6BA23;
        text-align: center;
        margin-top: 10px;
        font-size: 18px;
        font-size: $brand-fontWeight;
    }

    .player_block {
        margin-top: 10px;

        .player_info_head {
            display: flex;
            justify-content: space-between;
            text-align: start;
            align-items: center;
            border-radius: 6px 6px 0 0;
            background-color: #06193E;
            padding: 8px;

            .heading {
                text-align: start;
                padding-left: 10px;
            }
        }

        .player_info_body {
            background-color: #0A2250;
            border-radius: 0 0 6px 6px;
            padding: 8px;
            line-height: 36px;

            .body_containt {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .team_name {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    text-align: start;
                    gap: 4px;

                    img {
                        width: 23px;
                        height: 23px;
                    }
                }

                .accuracy_text {
                    text-align: end;
                    padding-right: 10px;
                }
            }
        }

        .space-2 {
            flex-grow: 1;
            flex-basis: 0;
            text-align: center;
        }
    }

    .button {
        text-align: center;

        .view_button {
            margin-top: 16px;
            font-weight: $brand-fontWeight;
            height: 40px;
            width: 160px;
            font-size: 16px;
        }
    }
}