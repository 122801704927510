@import "../mixins/mixins";


.winner_board_wrapper {
    margin: 8px 0;

    .winner_board_title {
        @include Flex(row, center, space-between);
        font-size: 26px;
        font-style: italic;
        margin-bottom: 9px;
        font-weight: 700;
        span:nth-child(2) {
            font-size: 13px;
            color: #FE9A82;
            font-style: normal;
            text-decoration: underline;
        }
    }

    .winner_card_section {

        // width: 393px;
        // height: 220px;
        // @include Flex(row, center, space-between, 20px);
        .card_wrapper {
            margin: 0 20px 17px;
            height: auto;
            width: auto;
        }
    }

    .winner_card_wrapper {
        position: relative;
        // width: 330px;
        height: 100%;
        padding: 10px;
        @include Flex(row, center, space-between);

        .profile_img {
            width: 95px;
        }

        .left_section,
        .right_section {
            height: 100%;
            flex-grow: 1;
            @include Flex(column, center, center);
            margin: 0;
        }

        .left_section {
            width: 50%;

            .image_card {
                width: 65%;
                height: 65%;

                img {
                    width: 100%;
                    // height: 103px;
                    // object-fit: contain;
                }
            }

            .rank {
                font-style: italic;
                margin: 5px auto;
            }

            .price {
                color: #FDC830;
                font-size: 32px;
                font-weight: bold;
                @include Flex(row,center,center);
                img {
                    margin: 0px 5px 5px 0px; 
                }
            }
        }

        .right_section {

            .week {
                color: #EE7A5E;
                justify-self: flex-start;
                flex-grow: 0.5;
            }

            .trophy_img {
                flex-grow: 2;
                width: 100px;
            }
        }

        .view_button {
            position: absolute;
            left: 50%;
            bottom: -10%;
            transform: translate(-50%, 0%);
        }

        .btn.square {
            border-radius: 20px;
            width: 130px;
            height: 30px;
        }
    }
}


@media screen and #{$mediaQueryRange-mobileDevice} {
    .profile_img {
        // width: 75px !important;
    }

    .trophy_img {
        width: 80px !important;
    }

    .winner_board_wrapper .winner_board_title {
        font-size: 20px;
    }
}