.btn {
    left: 1669px;
    width: 110px;
    height: 40px;
    // background: transparent linear-gradient(180deg, $brand-secondaryColor 0%, $brand-secondaryColorDark 100%) 0% 0% no-repeat padding-box;
    background-color: #f6ba23;
    border-radius: 24px;
    opacity: 1;
    color: $brand-blackColor;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    border: none;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &.btnDisabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &.btnMedium {
        min-height: 36px;
        width: 270px;
    }

    &.btnLarge {
        min-height: 44px;
        max-height: 58px;
        width: 418px;
        font-size: 18px;
        margin: 10px 0;
    }

    &.outline {
        outline: none;
        border: 2px solid #ee7a5e;
        background: transparent !important;
    }

    &.signup {
        margin-left: 16px;
    }

    &.square {
        border-radius: 7px;
    }

    &.btn-primary {
        background: transparent linear-gradient(180deg, #00984a 0%, #008b44 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 12px #00000040;
    }

    &.btn-strech {
        width: 100%;
    }

    &.disabled {
        background: transparent linear-gradient(180deg, #7b7b7b 0%, #474747 100%) 0% 0% no-repeat padding-box;
        color: #979797;
        cursor: not-allowed;
    }
}

button {
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.badge {
    border-radius: 20px;
    padding: 5px 10px;

    &.primary {
        color: white;
        background-color: #00984a;
    }
}

.link_button {
    border: none;
    outline: none;
    background: none;
    color: $brand-secondaryColor;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and #{$mediaQueryRange-mobileLayout} {
    .signup {
        margin-left: 9px !important;
        font-size: 14px;
        font-weight: 600;
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .btn {
        width: 80px;
        height: 34px;
    }

    .signup {
        margin-left: 9px !important;
    }

    .btnLarge {
        width: 220px !important;
        font-size: 16px;
        margin: 20px 0;
    }
}
