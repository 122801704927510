.container_heading {
    max-width: 804px;
    margin: 0 auto;
    padding: 0 12px;

    .score_heading_wrapper {
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 20px;
        gap: 6px;
        margin-top: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        img {
            width: 24px;
            height: 24px;
        }
    }

    .score_card_wrapper {
        background-color: #0a2250;
        border-radius: 6px;
        font-size: 16px;
        margin-top: 10px;
        padding: 6px 18px 14px 18px;
        line-height: 32px;

        .time_container {
            width: 25%;
        }

        .matchMinute_container {
            width: 25%;
            line-height: 22px;
            // text-align: center;
        }

        .matchStatus_container {
            background-color: #ffd6d6;
            color: #ff3a3a;
            padding: 0 4px;
            border-radius: 2px;
        }

        .score_card_block {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 4px;
            margin-top: 10px;

            .homeTeam {
                img {
                    width: 22px;
                }

                display: flex;
                justify-content: start;
                align-items: center;
                gap: 6px;
                width: 25%;

                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // @media screen and (min-width: 460px) and (max-width: 530px) {
                //     width: 100px !important;
                //     white-space: nowrap;
                //     overflow: hidden;
                //     text-overflow: ellipsis;
                // }

                .homeTeamName {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .awayTeam {
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 6px;
                width: 25%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                img {
                    width: 22px;
                }
            }

            .score_line {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                gap: 8px;
                width: 25%;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    min-width: 20px;
                }
            }
        }
    }
}

.score_top_menu {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    height: 45px;
    border: 1px solid #2152c4;
    border-radius: 4px;
    background: #183468 0% 0% no-repeat padding-box;
    padding: 4px 0px;
    margin-top: 26px;
    color: #95a9dc;
    font-size: 16px;
    width: 100%;
    overflow: hidden;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: 14px;
    }

    .splide__list {
        display: flex;
    }

    .HighlightSplider {
        overflow: hidden;
    }

    .splide__arrow {
        background-color: transparent;

        svg {
            fill: white;
        }
    }

    .splide__arrow--prev {
        display: none;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            display: none;
            left: -6px;
            margin-top: 2px;
        }
    }

    .splide__arrow--next {
        display: none;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            display: none;
            right: -6px;
            margin-top: 3px;
        }
    }

    .splide__track {
        width: 100%;
    }

    ul {
        align-items: center;
        // justify-content: space-evenly;
        justify-content: space-around;
        align-items: center;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            // width: 128% !important;
        }

        li {
            text-align: center;
            margin: 0 4px !important;
        }
    }

    div {
        cursor: pointer;
        width: 100%;
        text-align: center;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            width: 100%;
        }
    }

    .active {
        background: #2152c4 0% 0% no-repeat padding-box;
        // width: 100%;
        border-radius: 6px;
        color: #fff;
        padding: 8px 30px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            padding: 8px 10px;
        }
    }
}

.score_lineups_wrapper {
    margin-top: 10px;
    font-size: 16px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: 14px;
    }

    .lineups_button_grp {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
        width: 100%;
        font-size: 16px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: 14px;
        }

        button {
            width: 50%;
            padding: 8px;
            border-radius: 4px;
            background-color: #153c95;
            border: 1px solid #2152c4;
            color: #fff;
            font-weight: $brand-fontWeight;
        }

        .active {
            color: #000;
            background-color: #f6ba23;
            border: none;
        }
    }

    .home_formation {
        background-color: #0f2651;
        padding: 6px;
        font-weight: 500;
        width: 50%;
        margin: 0 auto;
        margin-top: 10px;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 2px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            width: 100%;
        }

        @media screen and #{$mediaQueryRange-tabletDevice} {
            width: 60%;
        }
    }

    .away_formation {
        background-color: #0f2651;
        padding: 6px;
        font-weight: 500;
        width: 50%;
        margin: 0 auto;
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 2px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            width: 100%;
        }

        @media screen and #{$mediaQueryRange-tabletDevice} {
            width: 60%;
        }
    }

    .match_map {
        background-image: url("../../images/png/fixtures/match_map.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        height: 850px;
        margin: 0 auto;
        width: 50%;
        text-align: center;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            width: 100%;
            height: 650px;
        }

        @media screen and #{$mediaQueryRange-tabletDevice} {
            width: 60%;
        }

        .players {
            width: 100%;
            height: 100%;
            font-size: 14px;
            position: relative;
            padding: 0 36px;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                padding: 0 18px;
            }

            @media screen and #{$mediaQueryRange-tabletDevice} {
                padding: 0 30px;
            }

            .home_team_contain {
                height: 50%;
            }

            .away_team_contain {
                height: 50%;
                position: relative;
            }

            .team_name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 90px;
                font-size: 14px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 54px;
                    font-size: 12px;
                }

                @media screen and #{$mediaQueryRange-tabletDevice} {
                    width: 70px;
                }
            }

            .home_score_count {
                border: 2px solid white;
                background-color: #183468;
                border-radius: 50%;
                width: 36px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 31px;
                    height: 31px;
                }
            }

            .away_score_count {
                border: 2px solid white;
                background-color: white;
                border-radius: 50%;
                color: #000;
                font-weight: bold;
                width: 36px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 31px;
                    height: 31px;
                }
            }

            .home_players {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                position: relative;
                top: 14%;
                margin-top: 14px;
                // gap: 20px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    top: 10%;
                    // gap: 10px;
                    margin-top: 10px;
                }
            }

            .away_players {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                position: relative;
                top: 8%;
                margin-top: 10px;
                // gap: 20px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    top: 2%;
                    // gap: 10px;
                    margin-top: 10px;
                }
            }

            .first_player {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                top: 16%;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    top: 14%;
                }
            }

            .last_player {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 26px;

                >div {
                    position: absolute;
                    // top: 12%;
                    bottom: 15%;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        // top: 2%;
                        bottom: 15%;
                    }
                }

            }
        }
    }

    .web_score_map {
        display: block;
        text-align: center;
        margin-top: 16px;
        // width: 100%;

        // img {
        //     width: 100%;
        // }

        @media screen and #{$mediaQueryRange-mobileDevice} {
            display: none;
        }
    }

    .mobile_score_map {
        display: none;
        text-align: center;
        margin-top: 16px;
        width: 100%;

        img {
            width: 100%;
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
            display: block;
        }
    }

    .score_table_wrapper {
        margin-top: 20px;

        .head_block {
            background-color: $brand-secondaryColorLight;
            padding: 2px 8px;
            color: #000;
            font-weight: $brand-fontWeight;
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 4px;
            border-radius: 4px;
        }

        .body_block {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            align-items: center;
            margin-top: 4px;
            border-radius: 4px;
            background-color: #10306b;
            padding: 4px 10px;

            .left_block {
                display: flex;
                justify-content: start;
                gap: 4px;

                .team_score {
                    min-width: 16px;
                    text-align: start;
                }

                .team_name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // width: 200px;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        width: 90px;
                    }
                }
            }

            .right_block {
                display: flex;
                align-items: center;
                justify-content: end;
                gap: 7px;

                .logos {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    gap: 3px;
                }
            }
        }

        .body_block:nth-child(2n-1) {
            background-color: #0f2651;
        }
    }

    .manager_wrapper {
        margin-top: 10px;

        .manager_heading {
            font-weight: $brand-fontWeight;
            background-color: $brand-secondaryColorLight;
            padding: 3px 14px;
            color: #000;
            border-radius: 4px;
        }

        .team_contain {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 5px;
            margin-top: 4px;
            background-color: #10306b;
            padding: 4px 10px;

            div {
                margin: 0 auto;
                color: #95a9dc;
                font-size: 14px;
                            }
        }

        .no_players {
            background-color: #10306b;
            // height: 80px;
            padding: 4px 10px;
            color: #95a9dc;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            margin-top: 8px;
        }
    }

    .key_wrapper {
        background-color: #10306b;
        margin-top: 8px;
        padding: 6px;
        font-size: 14px;
        color: #fff;

        .key {
            margin-left: 6px;
        }

        .key_details {
            color: #95a9dc;
            display: flex;
            width: 100%;
            margin-top: 6px;
            margin-left: 6px;

            div {
                width: 45%;
                display: flex;
                align-items: center;
                gap: 4px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 49%;
                }

                img {
                    width: 14px;
                    height: 14px;
                }

                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    .social_lineups_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 14px;

        .icon_Container {
            img {
                width: 26px;
                height: 26px;
            }
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .container_heading {
        .score_heading_wrapper {
            font-size: 16px;

            img {
                width: 20px;
                height: 20px;
            }
        }

        .score_card_wrapper {
            font-size: 14px;

            .time_container {
                width: 60px;
                //line-height: 10px;
            }

            .matchMinute_container {
                width: 58px;
                line-height: 22px;
                // text-align: center;
            }

            .matchStatus_container {
                background-color: #ffd6d6;
                color: #ff3a3a;
                padding: 2px 0;
                border-radius: 2px;
                font-size: 12px;
            }

            .score_card_block {
                .homeTeam {
                    gap: 6px;
                    min-width: 75px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    .homeTeamName {
                        min-width: 50px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .awayTeam {
                    min-width: 60px;

                    .awayTeamName {
                        min-width: 35px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}

@media screen and #{$mediaQueryRange-midMobileDevice} {
    .container_heading {
        .score_heading_wrapper {
            font-size: 13px;

            img {
                width: 20px;
                height: 20px;
            }
        }

        .score_card_wrapper {
            font-size: 14px;
            padding: 3px 8px 8px 10px;

            .time_container {
                width: 60px;
                //line-height: 10px;
            }

            .matchMinute_container {
                width: 58px;
                line-height: 17px;
                // text-align: center;
            }

            .matchStatus_container {
                background-color: #ffd6d6;
                color: #ff3a3a;
                padding: 2px 0;
                border-radius: 2px;
            }

            .score_card_block {
                // .homeTeam {
                //     img {
                //         width: 12px;
                //     }

                //     gap: 6px;
                //     min-width: 65px;
                //     white-space: nowrap;
                //     overflow: hidden;
                //     text-overflow: ellipsis;

                //     .homeTeamName {
                //         min-width: 50px;
                //         white-space: nowrap;
                //         overflow: hidden;
                //         text-overflow: ellipsis;
                //     }
                // }

                // .score_line {
                //     min-width: 80px;
                // }

                // .awayTeam {
                //     img {
                //         width: 12px;
                //     }

                //     min-width: 65px;
                //     white-space: nowrap;
                //     overflow: hidden;
                //     text-overflow: ellipsis;

                //     .awayTeamName {
                //         min-width: 50px;
                //         white-space: nowrap;
                //         overflow: hidden;
                //         text-overflow: ellipsis;

                //     }
                // }
            }
        }
    }
}