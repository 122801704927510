.footer_containt {
    margin-top: 20px;
    margin-left: -12px;
    margin-right: -12px;
}

.live_score_wrapper {
    background-image: url("../../images/png/liveScoreBackg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #10306B;
    background-size: 100% 100%;
    // height: auto;
    height: 160px;
    display: flex;
    padding: 30px;
    justify-content: center;
    align-items: center;
    gap: 60px;
    margin-top: 6px;
    border-radius: 6px;
    margin-bottom: -10px;
    position: relative;

    .live_time {
        position: absolute;
        top: 24px;
    }

    .kickOff_date {
        text-align: center;
    }

    .home_score,
    .away_score {
        background-color: #F8C84F;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 36px;
        color: black;
        font-weight: bold;
        border-radius: 6px;
    }

    .score_line {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;
    }

    .home_team {
        text-align: center;

        img {
            width: 40px;
            height: 40px;
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: 12px;
        }
    }

    .away_team {
        text-align: center;

        img {
            width: 40px;
            height: 40px;
        }

        .team_title {
            font-size: 10px;
            position: absolute;
            margin-left: -12px;
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: 12px;
        }
    }
}

.back_button {
    position: relative;
    top: -50px;
    left: -150px;
    height: 40px;
}

.detail_wrapper {
    margin-top: 16px;
    font-size: 16px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: 14px;
    }

    .details_dropdown {
        border: 0.5px solid #3D71EB;
        border-radius: 6px;
        width: 100%;
        height: 36px;
        background-color: #183468;
        color: white;
        vertical-align: top;
        padding: 0 6px;
    }

    .detail_text {
        margin: 10px 0;
    }

    .event_block {
        .key_heading {
            text-align: center;
            color: #F8C84F;
            padding: 6px;
            border-bottom: 3px solid #F8C84F;
            font-size: 20px;
        }

        .right_event_card {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 10px;

            .empty_box {
                width: 50%;
                display: flex;
                justify-content: end;
                align-items: end;

                .secondYellowCard {
                    width: 15px;
                    height: 15px;
                    margin: 8px;
                }

                .box {
                    width: 14px;
                    height: 20px;
                    background-color: #FFFF30;
                    margin: 10px;
                }

                .red_card {
                    width: 14px;
                    height: 20px;
                    background-color: #d4230b;
                    margin: 10px;
                }

                .football_box {
                    text-align: center;
                    margin: 10px;
                    line-height: 30px;
                    height: 24px;
                    width: 24px;
                }

            }

            .info_box {
                border-left: 1px solid #A7A5A5;
                padding-left: 10px;
                width: 50%;
                padding: 10px;
            }
        }

        .left_event_card {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 10px;

            .empty_box {
                width: 52%;
                display: flex;
                justify-content: start;
                align-items: end;

                .secondYellowCard {
                    width: 15px;
                    height: 15px;
                    margin: 8px;
                }

                .box {
                    width: 14px;
                    height: 20px;
                    background-color: #FFFF30;
                    margin: 10px;
                }

                .red_card {
                    width: 14px;
                    height: 20px;
                    background-color: #d4230b;
                    margin: 10px;
                }

                .football_box {
                    text-align: center;
                    margin: 10px;
                    line-height: 30px;
                    height: 24px;
                    width: 24px;
                }

            }

            .info_box {
                border-right: 1px solid #A7A5A5;
                padding-left: 10px;
                width: 48%;
                padding: 10px;

                div {
                    text-align: end;
                }
            }
        }

        .bottom_box_container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;
            margin-top: 20px;
            @media screen and #{$mediaQueryRange-mobileDevice} { 
                gap: 10px;
            }
            .bottom_block {
                display: flex;
                justify-content: center;
                align-items: center;

                .bottom_contain {
                    border: 0.5px solid #A7A5A5;
                    padding: 6px 10px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .secondYellowCard {
                        width: 14px;
                        height: 21px;
                    }
                    
                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        padding: 0px 3px;
                        height: 24px;
                        width: 18px;
                    }

                    .blank_box {
                        width: 12px;
                        height: 16px;
                        background-color: #FFFF30;
                    }

                    .red_box {
                        width: 12px;
                        height: 16px;
                        background-color: #d4230b;
                    }

                    .goal {
                        height: 20px;
                        width: 20px;

                    }

                }


            }
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .live_score_wrapper {
        gap: 40px;
    }

    .back_button {
        position: relative;
        height: 22px;
        top: -10px;
        left: -20px;
    }

    .detail_wrapper {
        .detail_text {
            font-size: 13px;
        }

        .event_block {
            .key_heading {
                font-size: 14px;
            }

            .left_event_card {

                .empty_box {
                    width: 58%;

                }

                .info_box {
                    width: 42%;
                }
            }
        }
    }
}