.prizes_head {
    @include Flex(row, center, center);
    margin: 30px auto;

    .prizes_title {
        color: #FEA792;
        font-size: 32px;
    }
}

.prizes_banner {
    margin-bottom: 28px;

    img {
        width: 100%;
    }

}

$bg-first: transparent linear-gradient(180deg, #D68001 0%, #FFEF5A 50%, #D68001 100%) 0% 0% no-repeat padding-box;
$bg-second: transparent linear-gradient(180deg, #B4B6B8 0%, #EBF5FF 51%, #A3A6A8 100%) 0% 0% no-repeat padding-box;
$bg-third: transparent linear-gradient(180deg, #B5730D 0%, #E8B363 51%, #B5730D 100%) 0% 0% no-repeat padding-box;

.prizes_content {
    .prizes_content_head {
        background: #230F09 0% 0% no-repeat padding-box;
        border: 1px solid #F8876C;
        border-radius: 8px;
        text-align: center;
        color: #F9C2B5;
        display: grid;
        place-items: center;

        p {
            padding: 26px 10px;
        }
    }

    .prize_container {
        @include Flex(row, center, space-between);
        background: #1A1A1A 0% 0% no-repeat padding-box;
        border-radius: 4px;
        padding: 4px 50px;
        margin: 12px auto;
        width: 96%;
        color: #878787;
        border: 1px solid rgba(128, 128, 128, 0.281);

        &:nth-child(1) {
            background: $bg-first;
            color: black;
        }

        &:nth-child(2) {
            color: black;
            background: $bg-second;
        }

        &:nth-child(3) {
            color: black;
            background: $bg-third;
        }

        .right_section {
            @include Flex(row, center, center);

            .coin_container {
                width: 32px;
                height: 32px;
                margin: 0 6px;

                img {
                    width: 100%
                }
            }

            .amount_container {
                font-weight: 900;
                font-size: 20px;
            }
        }

        .left_section {
            @include Flex(row, center, center);

            .text_container {
                font-weight: 900;
                font-size: 20px;
                width: 110px;
            }

            .img_container {
                width: 36px;
                height: 36px;
                margin: 0 6px;
                background-color: rgb(83, 80, 80);
                text-align: center;
                border-radius: 50%;
                display: grid;
                place-items: center;

                img {
                    width: 18px;

                }
            }
        }
    }

    .prize_note {
        text-align: center;
        color: #C2C2C2;
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .prizes_banner {

        img {
            height: 160px;
        }

    }

    .prizes_content {

        .prize_container {
            padding: 4px 20px;

            .right_section {

                .coin_container {
                    width: 28px;
                    height: 28px;
                }

                .amount_container {
                    font-weight: 900;
                    font-size: 16px;
                }
            }

            .left_section {

                .text_container {
                    font-weight: 900;
                    font-size: 16px;
                    width: 85px;
                }

                .img_container {
                    width: 28px;
                    height: 28px;

                    img {
                        width: 16px;
                    }
                }
            }
        }
    }
}