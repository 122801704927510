// News Header

.main_News_Page {
    .container {
        .news_search_input {
            display: flex;
            align-items: center;
            background-color: #142D5B;
            border-radius: 6px;
            margin-top: 10px;
            width: 40%;
            padding-left: 8px;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                width: 100%;
            }

            @media screen and #{$mediaQueryRange-tabletDevice} {
                width: 50%;
            }

            .search_input {
                background-color: #142D5B;
                border: none;
                width: 100%;
                outline: none;
                padding: 5px;
                border-radius: 6px;
            }

            input::placeholder {
                color: #4063BC;
            }

            span {
                padding: 8px 10px 5px 5px;
            }
        }

        .news_banner_wrapper {
            width: 100%;
            overflow: hidden;
            margin-top: 10px;

            .splide__list {
                display: flex;
            }

            .bannerSplider {
                overflow: hidden;
            }

            span {
                display: none;
            }

            ul {
                li {
                    width: 33%;
                }
            }

            @media screen and #{$mediaQueryRange-mobileDevice} {

                ul {
                    li {
                        width: 100%;

                        .homepage_banner {
                            width: 100%;
                        }
                    }
                }
            }

            @media screen and #{$mediaQueryRange-tabletDevice} {
                ul {
                    li {
                        width: 50%;
                    }
                }
            }

            .homepage_banner {
                height: 100%;

                .team_name {
                    z-index: 10;
                    width: 100%;
                    font-size: 16px;
                    margin-top: 6px;
                    padding-right: 20px;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        font-size: 14px;
                        padding-right: 0px;
                    }
                }

                .news_published_date {
                    font-size: 12px;
                    color: #888888;
                }

                .banner_container {
                    margin-top: 7px;

                    img {
                        // width: 100%;
                        // height: 245px;
                        max-width: 100%;
                        max-height: 100%;
                        height: auto;
                        border-radius: 8px;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            // height: 235px;
                            // width: 100%;
                            // object-fit: fill;
                        }

                        @media screen and #{$mediaQueryRange-tabletDevice} {
                            // height: 245px;
                            // width: 100%;
                            // object-fit: fill;
                        }
                    }
                }
            }
        }

        .news_menu {
            border: 1px solid #2152C4;
            border-radius: 4px;
            background: #183468 0% 0% no-repeat padding-box;
            max-width: 26%;
            margin: auto;
            margin-top: 30px;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                max-width: 100%;
            }

            @media screen and #{$mediaQueryRange-tabletDevice} {
                max-width: 45%;
            }

            @media screen and #{$mediaQueryRange-lowDesktopDevice} {
                max-width: 34% !important;
            }

            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                font-size: 16px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    font-size: 14px;
                }

                li {
                    div {
                        // background-color: #142D5B;
                        padding: 6px 20px;
                        border-radius: 6px;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            padding: 6px 15px;
                        }
                    }

                    .active {
                        background: #2152C4 0% 0% no-repeat padding-box;
                        opacity: 1;
                        margin: 4px;
                    }
                }
            }

            .splide__arrow--next {
                display: none;
            }

            .splide__arrow--prev {
                display: none;
            }
        }

        .news_stories_wrapper {
            margin-top: 10px;

            .stories_container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                flex-wrap: wrap;
                gap: 10px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    display: block;
                    justify-content: start;
                    align-items: start;
                    width: 100%;
                    flex-wrap: nowrap;
                    gap: 0px;
                }


                .stories_block {
                    margin-top: 8px;
                    display: flex;
                    align-items: start;
                    justify-content: start;
                    gap: 10px;
                    background-color: #0F1C3E;
                    border-radius: 6px;
                    // height: 225px;
                    height: 176px;
                    width: 49.5%;

                    // @media screen and #{$mediaQueryRange-lowDesktopDevice} {
                    //     height: 176px;
                    // }

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        height: 120px;
                        width: 100%;
                    }

                    @media screen and #{$mediaQueryRange-tabletDevice} {
                        height: 130px;
                        width: 49%;
                    }

                    .image_container {
                        max-width: 60%;
                        // min-width: 298px;
                        min-width: 235px;
                        height: 100%;

                        // @media screen and #{$mediaQueryRange-lowDesktopDevice} {
                        //     min-width: 235px;
                        // }

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            max-width: 230px;
                            min-width: 160px;
                        }

                        @media screen and #{$mediaQueryRange-tabletDevice} {
                            min-width: 172px;
                        }

                        img {
                            // width: 100%;
                            // height: 100%;
                            max-width: 100%;
                            max-height: 100%;
                            height: auto;
                            border-radius: 6px 0 0 6px;
                        }
                    }

                    .title_block {
                        width: 64%;
                        padding: 14px 6px 6px 0px;
                        font-size: 16px;
                        position: relative;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            font-size: 12px;
                            padding: 8px 6px 6px 0px;
                        }

                        @media screen and #{$mediaQueryRange-tabletDevice} {
                            font-size: 14px;
                        }


                        .published_date {
                            font-size: 12px;
                            color: #888888;
                        }
                    }
                }
            }


            .view_more_button {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                cursor: pointer;

                span {
                    background-color: #F6BA23;
                    height: 16px;
                    width: 16px;
                    border-radius: 4px;
                    color: black;
                    display: flex;
                    align-items: center;

                    img {
                        position: relative;
                        top: -5px;
                        width: 7px;
                        height: 10px;
                    }
                }
            }
        }

        .featured_wrapper {
            margin-top: 10px;

            h4 {
                margin-bottom: 6px;
            }

            ul {
                display: flex;
                justify-content: start;
                gap: 8px;

                li {
                    .news_text_containt {
                        border: 2px solid #0F1C3E;
                        padding: 6px 8px;
                        border-radius: 8px;
                        width: 264px;
                        height: 116px;

                        div {
                            height: 82px;
                            font-size: 13px;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 4;
                            display: -webkit-box;
                        }
                    }

                    .news_published_date {
                        color: #888888;
                        font-size: 10px;
                    }
                }
            }

            .splide__arrow--next {
                display: none;
            }

            .splide__arrow--prev {
                display: none;
            }
        }

        .news_main_header {
            .main_header_content {
                .splide__list {
                    display: flex;
                }

                ul {
                    // display: flex;
                    // justify-content: flex-start;
                    margin: 10px 0;

                    .news_list_item {
                        padding: 10px;
                        border-radius: 8px;
                        font: normal normal medium 14px/18px $brand-fontFamily;
                        border: 1px solid #3d71eb;
                        background-color: #183468;
                        color: $brand-textColor;
                        text-align: center;
                        margin-right: 10px;
                        width: auto !important;

                        div {
                            white-space: nowrap;
                        }

                        &.active {
                            background-color: $brand-secondaryColorLight;
                            color: $brand-blackColor;
                            border: 1px solid $brand-secondaryColorLight;
                        }
                    }

                    @media (max-width: 767px) {
                        .news_list {}
                    }
                }
            }
        }

        .news_main_body {
            .headlines_section {
                .headlines_content {
                    .headlines_card {
                        .card_header {
                            p {
                                text-align: left;
                                font: normal normal 500 22px/22px $brand-fontFamily;
                                letter-spacing: -0.08px;
                                color: #ffffff;
                                padding: 5px 0px;
                                opacity: 1;
                                margin: 10px 0px;

                                @media screen and #{$mediaQueryRange-mobileDevice} {
                                    font: normal normal 500 16px/16px $brand-fontFamily;
                                }
                            }
                        }

                        .card_body {
                            position: relative;
                            display: inline-block;

                            img {
                                display: block;
                                max-width: 100%;
                                height: auto;
                            }

                            a {
                                position: absolute;
                                bottom: 0px;
                                left: 0px;
                                display: flex;
                                justify-content: center;
                                align-items: flex-end;
                                background: transparent linear-gradient(180deg, #2b318a00 0%, #19318c8c 38%, #0a318e 100%) 0% 0% no-repeat padding-box;
                                opacity: 1;
                                height: 130px;
                                width: 100%;
                                padding-bottom: 20px;
                                font-size: 18px;

                                @media screen and #{$mediaQueryRange-mobileDevice} {
                                    height: 64px;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }

            .top_stories_section {
                .top_stories_content {
                    .title {
                        text-align: left;
                        // font: normal normal 500 22px/22px $brand-fontFamily;
                        font-weight: $brand-fontWeight;
                        font-size: $brand-fontSizeWebPageHeading;
                        letter-spacing: -0.08px;
                        color: #ffffff;
                        // padding: 5px 0px;
                        opacity: 1;
                        margin: 12px 0px;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            // font: normal normal 500 16px/16px $brand-fontFamily;
                            font-size: $brand-fontSizeMobilePageHeading;
                        }
                    }

                    .top_stories_card {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: start;
                        gap: 8px;

                        // @media screen and #{$mediaQueryRange-tabletDevice} {
                        //     display: flex;
                        // }

                        // @media screen and #{$mediaQueryRange-lowDesktopDevice} {
                        //     display: flex;
                        // }

                        // @media screen and #{$mediaQueryRange-desktopDevice} {
                        //     display: flex;
                        // }

                        .card_body {
                            background: #183468 0% 0% no-repeat padding-box;
                            border-radius: 8px;
                            margin-bottom: 8px;

                            &:last-child {
                                margin-right: unset;
                            }

                            @media screen and #{$mediaQueryRange-mobileDevice} {
                                width: 100%;
                            }

                            @media screen and #{$mediaQueryRange-tabletDevice} {
                                flex: 1 1 49.2%;
                                max-width: 49.2%;
                                width: 48%;
                                // margin-right: 8px;
                            }

                            @media screen and #{$mediaQueryRange-lowDesktopDevice} {
                                // flex: 1 1 32%;
                                width: 32.5%;
                                // margin-right: 8px;
                            }

                            @media screen and #{$mediaQueryRange-desktopDevice} {
                                // flex: 1 1 32%;
                                width: 32.5%;
                                // margin-right: 8px;
                            }

                            img {
                                width: 100%;
                                border-radius: 8px 8px 0px 0px;
                            }

                            .card_footer_content {
                                padding: 10px 16px 16px 16px;

                                .top_stories_txt {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                }

                                .top_stories_info {
                                    margin-top: 10px;
                                    display: flex;
                                    justify-content: space-between;

                                    .top_stories_tags {
                                        background: #272727cc 0% 0% no-repeat padding-box;
                                        border-radius: 20px;
                                        opacity: 1;
                                        color: #c7c7c7;
                                        padding: 3px 6px;
                                        font-size: 12px;
                                        letter-spacing: 0px;
                                        align-self: flex-start;
                                        text-align: left;
                                    }

                                    .top_stories_time {
                                        color: #d1d1d1;
                                        font: normal normal normal 12px/15px $brand-fontFamily;
                                        padding: 6px;
                                        align-self: flex-end;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//news Article
.article_main_Page {
    .container {
        .article_content {
            .article_header {
                .back-button {
                    position: unset;
                    margin: 10px 0px;
                }
            }

            .article_body {
                .article-title {
                    margin: 6px 0px;
                    font-size: 16px;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        margin: 0 0 4px 0;
                        font-size: 14px;
                    }
                }

                .artical_newsText {
                    p {
                        font-size: 16px;
                        margin-top: 10px;
                    }

                    h2 {
                        margin-top: 10px;
                        margin-bottom: 0px;
                        font-size: 20px;
                    }

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        p {
                            font-size: 14px;
                            margin-top: 8px;
                        }

                        h2 {
                            font-size: 16px;
                        }
                    }
                }

                .article-image {
                    text-align: center;

                    img[alt="football_img"][src$="/static/media/placeholder-img.9fd90711b7ce6a018c93.jpg"] {
                        width: auto;
                        margin-top: 8px;
                        border-radius: 10px;
                    }

                    img {
                        width: 100%;
                        margin-top: 8px;
                        border-radius: 10px;
                    }

                    .article-links {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .socialmediaIcons {
                            display: flex;
                            justify-content: space-between;
                            align-self: flex-start;

                            .socialmedia_links {
                                padding: 2px;
                            }
                        }

                        .article_published {
                            display: flex;
                            justify-content: space-between;
                            align-self: flex-end;
                            margin: 10px 5px 12px 5px;

                            .article_date {
                                margin-right: 5px;
                                font-size: 12px;
                                color: #6f81ac;
                            }

                            .article_time {
                                margin-right: 5px;
                                font-size: 12px;
                                color: #d1d1d1;
                            }
                        }
                    }
                }

                p {
                    font: normal normal normal 16px $brand-fontFamily;
                    color: $brand-textColor;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        font: normal normal normal 14px $brand-fontFamily;
                    }
                }

                .article_tags {
                    .tags_title {
                        margin-top: 10px;
                        padding-bottom: 8px;
                        border-bottom: 0.5px solid #707070;
                        font: normal normal 500 22px/22px $brand-fontFamily;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            font: normal normal 500 16px/16px $brand-fontFamily;
                        }
                    }
                }

                .related_article {
                    .related_article_title {
                        margin-top: 10px;

                        border-bottom: 0.5px solid #707070;
                        padding-bottom: 8px;
                        font: normal normal 500 22px/22px $brand-fontFamily;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            font: normal normal 500 16px/16px $brand-fontFamily;
                        }
                    }

                    .related_article_content {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 10px;

                        .related_article_card {
                            flex: 1 1 33.3%;
                            width: 33.3%;
                            display: flex;

                            .card_image {
                                flex: 1 1 45%;
                                width: 45%;
                                margin: 5px;

                                img {
                                    border-radius: 8px;
                                    width: 100%;
                                }
                            }

                            .card_body {
                                flex: 1 1 55%;
                                width: 55%;
                                margin: 5px;

                                p {
                                    font-size: 16px;

                                    @media screen and #{$mediaQueryRange-mobileDevice} {
                                        font: normal normal normal 14px $brand-fontFamily;
                                    }
                                }

                                .card_info {
                                    display: flex;
                                    justify-content: space-between;
                                    margin-top: 14px;

                                    .tags_list {
                                        align-self: flex-start;
                                        margin: unset;
                                    }

                                    .article_published_time {
                                        align-self: flex-end;
                                        padding: 2px;
                                        font-size: 12px;
                                        color: #d1d1d1;
                                    }
                                }
                            }

                            @media screen and #{$mediaQueryRange-mobileDevice} {
                                flex: 1 1 100%;
                            }

                            @media screen and #{$mediaQueryRange-tabletDevice} {
                                flex: 1 1 50%;
                                width: 50%;
                                max-width: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .tags_list {
        background: #000000cc 0% 0% no-repeat padding-box;
        opacity: 1;
        color: #c7c7c7;
        border-radius: 15px;
        border: 0.5px solid #878585;
        padding: 2px;
        margin: 10px 5px 12px 5px;
        font-size: 12px;
        flex-shrink: 0;
        flex-basis: auto;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}