@mixin Flex($direction, $align, $justify, $gap: 0) {
    display: flex;
    flex-direction: $direction;
    align-items: $align;
    justify-content: $justify;
    gap: $gap;
}

.flash_container_outer {
    background: transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 50%, #ffffff00 100%) 0% 0% no-repeat
        padding-box;
    box-shadow: 0px 4px 12px #00000040;
    border-radius: 4px;
    opacity: 1;
    width: 100%;
    margin: 0 auto;
    height: 53px;
    // height: calc(100% + 2px);
    display: grid;
    place-items: center;

    .flash_container_inner {
        width: 100%;
        height: 51px;
        border-radius: 4px;
        background: #071027;
        box-shadow: 0px 4px 12px #00000040;
    }
}

.form_container {
    max-width: 100%;

    .form_group {
        @include Flex(column, stretch, space-between);

        .form_input {
            background: #10151d 0% 0% no-repeat padding-box;
            border: 0.5px solid #2d5fd5;
            border-radius: 28px;
            height: 40px;
            margin: 10px 0;
            color: $brand-textGrayColorLight;
            font-size: 16px;
            padding: 0 10px;
        }
    }
}
