.gain_coin_container {
    .terms_and_conditions_content {
        margin-top: 10px;

        .content_box {
            padding: 12px 18px 16px 18px;
            padding-bottom: 22px;
            color: #a2a2a2;
            // background: #232833 0% 0% no-repeat padding-box;
            background-color: #021433;
            box-shadow: 0px 4px 12px #00000040;
            border-radius: 4px;

            .content_title {
                color: white;
                margin-top: 6px;
                font-size: $brand-fontSizeWebAccordHeading;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    font-size: $brand-fontSizeMobileAccordHeading;
                }
            }

            .content_description {
                p {
                    font-size: $brand-fontSizeAccordContaint;
                    margin-top: 10px;
                }

                ol {
                    padding: 4px 16px;

                    li {
                        list-style-type: decimal;
                        padding-top: 12px;
                        font-size: $brand-fontSizeAccordContaint;
                    }

                    ul {
                        padding-left: 20px;

                        li {
                            list-style: disc;

                            &::marker {
                                color: #f8c84f;
                            }
                        }
                    }
                }

                p {
                    padding: 4px 0;
                }

                span {
                    color: #f8c84f;
                }
            }
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .gain_coin_container {
        .gain_coins_head {
            .gain_coins_title {
                font-size: $brand-fontSizeMobilePageHeading;
            }
        }

        .gain_coin_banner {
            margin-bottom: 4px;
        }

        .terms_and_conditions_content {
            .content_box {
                padding: 12px 14px 16px 14px;
            }
        }
    }
}