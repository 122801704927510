.promotion_wrapper {
  .promotion_heading {
    text-align: center;
    color: #f6ba23;
    font-size: $brand-fontSizeWebPageHeading;
    margin-top: -18px;
    font-weight: $brand-fontWeight;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      font-size: $brand-fontSizeMobilePageHeading;
      margin: -8px 0;
    }
  }

  .promotion_banner {
    text-align: center;
    margin-top: 20px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      margin-top: 20px;
    }

    .promotion_banner_mobile {
      display: none;

      img {
        width: 100%;
        height: 100%;
      }

      @media screen and #{$mediaQueryRange-mobileDevice} {
        display: block;
      }
    }

    .promotion_banner_web {
      display: block;

      img {
        width: 100%;
        height: 100%;
      }

      @media screen and #{$mediaQueryRange-mobileDevice} {
        display: none;
      }
    }
  }

  .promotion_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 26px;

    .contain {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 10px;
      font-size: $brand-fontSizeWebHeading;
      margin-right: 10px;

      .description {
        line-height: 24px;

        p {
          font-size: $brand-fontSizeAccordContaint;

          @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: 12px;
          }
        }
      }

      @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: $brand-fontSizeWebAccordHeading;
      }
    }
  }

  .game_wrapper {
    margin-top: 20px;

    .game_heading {
      font-size: 24px;
      font-weight: 400;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: 18px;
      }
    }

    .banner_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-top: 12px;

      .game_banner {
        width: 100%;
        // height: 246px;

        img {
          width: 100%;
          height: 100%;
        }

        // @media screen and #{$mediaQueryRange-midMobileDevice} {
        //     height: 198px;
        // }
      }

      .mobile_banner {
        display: none;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          display: block;
        }
      }

      .web_banner {
        display: block;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          display: none;
        }
      }
    }
  }
}

.promotion_detail {
  width: 100%;
  color: white;
  background: transparent
    linear-gradient(90deg, #ffffff00 0%, #ffffff 50%, #ffffff00 100%) 0% 0%
    no-repeat padding-box;
  overflow: hidden;
  box-shadow: 0px 4px 12px #00000040;
  border-radius: 4px;
  margin: 15px auto;
  padding: 1px 0px;

  .detail_contain {
    background: #0f2651 0 0 no-repeat padding-box !important;
    padding: 16px 18px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      padding: 12px 10px;
    }

    h4 {
      font-size: $brand-fontSizeWebAccordHeading;
      font-weight: $brand-fontWeight;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: $brand-fontSizeMobileAccordHeading;
      }
    }

    p {
      color: #a2a2a2;
      margin: 6px 0px;
      font-size: $brand-fontSizeMobileAccordHeading;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: $brand-fontSizeAccordContaint;
      }
    }

    ol.order_list {
      margin-bottom: 8px;
    }

    ul {
      margin-top: 4px;

      li {
        // display: flex;
        // gap: 3px;
        color: #a2a2a2;
        font-size: $brand-fontSizeMobileAccordHeading;
        line-height: 26px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          font-size: $brand-fontSizeAccordContaint;
          line-height: 20px;
        }

        .order_list {
          margin-left: 26px;
          margin-bottom: 6px;
          line-height: 14px;

          li {
            list-style-type: disc !important;
            margin-top: 8px;
          }
        }

        span {
          color: #f6ba23;
        }
      }
    }

    ol {
      li {
        color: #a2a2a2;
        font-size: $brand-fontSizeMobileAccordHeading;
        line-height: 24px;
        margin-top: 12px;
        list-style-type: disc;
        margin-left: 34px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          font-size: $brand-fontSizeAccordContaint;
          line-height: 18px;
        }
      }
    }
  }
}
