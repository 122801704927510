@import "../mixins/mixins";

.mpgame_heading {
    margin: 15px 0px 10px 0px;
}

.super_card_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 16px 0px 12px 0px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        margin: 14px 0px 10px 0px;
    }

    img {
        width: 24px;
        margin-right: 4px;
        vertical-align: text-top;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            width: 20px;
        }
    }

    p {
        font-weight: $brand-fontWeight;
        font-size: $brand-fontSizeWebHeading;
        color: white;
        white-space: nowrap;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: 16px;
        }
    }
}

.game_card_main {
    display: flex;
    flex-wrap: wrap;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        display: block;
    }

    .super_card_wrapper {
        flex: 1;
        width: 31%;
        margin-right: 5px;

        &:last-child {
            margin-right: unset;
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
            flex: none;
            width: 100%;
            margin-bottom: 10px;
        }

        @media screen and #{$mediaQueryRange-tabletDevice} {
            flex: none;
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.super_card {
    // background-image: url("../../images/png/games_background_desktop.png");
    background-image: url("../../images/web/homepagebanners/games_background_desktop.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    border-radius: 10px;
    border: 0.3px solid #2152c4;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        // background-image: url("../../images/png/games_background_mobile.png");
        background-image: url("../../images/web/homepagebanners/games_background_mobile.webp");
        background-size: cover;
        background-repeat: no-repeat;
        margin: 0;
    }
}

.match_predictor_card {
    .top-section {
        // @include Flex(row, center, space-around);
        padding: 10px;
        min-height: 197px;
        position: relative;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            min-height: unset;

            .liveIcon {
                width: 60px;
            }
        }

        @media screen and #{$mediaQueryRange-tabletDevice} {
            min-height: unset;
        }

        @media screen and #{$mediaQueryRange-lowDesktopDevice} {
            min-height: 203px;
        }
    }

    .entry_button {
        position: absolute;

        button {
            @include Flex(row, center, center);
            background-color: #2152c4 !important;
            border: none;
            outline: none;
            color: white;
            font-size: 14px;
            padding: 5px;
            box-shadow: -4px 3px black;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                background-color: #9d80e0;
                border: none;
                outline: none;
                color: white;
                font-size: 14px;
                padding: 4px;
            }

            @media screen and #{$mediaQueryRange-tabletDevice} {
                font-size: 22px;
                padding: 4px;
            }

            img {
                width: 15px;
                padding: 0px 1px 0px 3px;
                margin-top: 5px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 15px;
                    padding: 4px 1px 0px 3px;
                    margin-top: 0;
                }

                @media screen and #{$mediaQueryRange-tabletDevice} {
                    width: 20px;
                    padding: 2px 1px 0px 3px;
                    margin-top: 1px;
                }
            }
        }
    }

    .middle-section {
        display: flex;
        justify-content: flex-start;

        .game_title {
            width: 50%;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                &.hitsix {
                    width: 80px;
                }
            }

            &.hitsix {
                width: 100px;
            }

            .mp_logo {
                margin: 20px 10px 0px 0px;

                @media screen and #{$mediaQueryRange-midMobileDevice} {
                    margin: 20px 10px 0px 0px !important;
                }

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    margin: 40px 10px 0px 0px;
                    width: 220px;
                }

                @media screen and #{$mediaQueryRange-tabletDevice} {
                    margin: 60px 10px 0px 0px;
                }
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .game_content {
            width: 50%;

            .coins_means_prize_txt {
                color: $brand-secondaryColor;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .left {
            font-size: 14px;
            font-weight: 500;
            min-height: 60px;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                text-align: start;
                font-size: 14px;
                line-height: 20px;
                width: auto;
            }

            @media screen and #{$mediaQueryRange-lowDesktopDevice} {
                font-size: 13px;
                line-height: 20px;
                min-height: 63px;
            }

            @media screen and #{$mediaQueryRange-tabletDevice} {
                font-size: 18px;
                width: 100%;
            }

            span {
                color: #f6ba23;
            }

            p {
                color: #a8a8a8;
                font-size: 17px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    color: #a8a8a8;
                    font-size: 12px;
                    margin: 16px 0;
                }

                @media screen and #{$mediaQueryRange-tabletDevice} {
                    color: #a8a8a8;
                    font-size: 14px;
                }

                &.active {
                    color: white;
                }
            }
        }

        .right {
            font-size: 40px;
            text-align: center;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                font-size: 14px;
                text-align: start;

                small {
                    margin-left: 25px;
                    font-size: 16px;
                }
            }

            .prize_detail {
                font-size: 14px;
                font-weight: normal;
                margin-top: 5px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    font-size: 14px;
                    font-weight: normal;
                }

                @media screen and #{$mediaQueryRange-tabletDevice} {
                    font-size: 18px;
                    font-weight: normal;
                }
            }

            .prize_amount {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    align-items: center;
                }

                .prizes_amount_coin_img {
                    height: 33px;
                    width: 33px;
                    line-height: 33px;
                    margin-right: 5px;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        margin-right: 0px;
                        height: 29px;
                        width: 32px;
                        line-height: 29px;
                    }

                    @media screen and #{$mediaQueryRange-tabletDevice} {
                        margin-right: 5px;
                        height: 33px;
                        width: 33px;
                        line-height: 33px;
                    }

                    @media screen and #{$mediaQueryRange-lowDesktopDevice} {
                        margin-right: 5px;
                        height: 30px;
                        width: 26px;
                        line-height: 29px;
                    }

                    img {
                        width: 100%;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            width: 27px;
                        }

                        @media screen and #{$mediaQueryRange-tabletDevice} {
                            width: 50px;
                        }

                        @media screen and #{$mediaQueryRange-lowDesktopDevice} {
                            width: 35px;
                        }
                    }
                }

                .prize_amount_coins {
                    font-size: 34px;
                    line-height: 40px;
                    font-weight: 500;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        font-size: 27px;
                        line-height: 33px;
                    }

                    @media screen and #{$mediaQueryRange-tabletDevice} {
                        font-size: 38px;
                        line-height: 40px;
                    }

                    @media screen and #{$mediaQueryRange-lowDesktopDevice} {
                        font-size: 25px;
                        line-height: 26px;
                    }
                }
            }

            .vctt_banner {
                font-size: 20px;
                font-weight: 200;
                flex-direction: column;
            }

            p {
                text-align: center;
                @include Flex(column, flex-start, flex-start);
                font-size: 109px;
                font-weight: 600;
                margin: 0;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    text-align: center;
                    font-size: 30px;
                    font-weight: 600;
                    margin: 0;
                    letter-spacing: 2px;
                }

                @media screen and #{$mediaQueryRange-tabletDevice} {
                    font-size: 70px;
                    font-weight: 600;
                    margin: 0;
                }
            }

            img {
                width: 85px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 26px;
                }

                @media screen and #{$mediaQueryRange-tabletDevice} {
                    width: 55px;
                }
            }
        }

        small {
            margin-left: 25px;
            font-size: 16px;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                margin-left: 25px;
                font-size: 16px;
            }
        }
    }
}

.bottom-section {
    width: 100%;

    p {
        font-size: 14px;
        // color: #FEB3A1;
        align-items: start;
    }

    .btn.btnMedium {
        border-radius: 24px;
        font-weight: $brand-fontWeight;
        margin: 5px 0px 5px 0px;
        font-size: 20px;
        width: 120px;
        height: 37px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            width: 120px;
            font-size: 16px;
        }

        @media screen and #{$mediaQueryRange-tabletDevice} {
            margin: 10px 0px 10px 0px;
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .btn.btnLarge {
        width: 150px !important;
        min-height: 44px;
    }
}