.card_wrapper {
    background: rgba(70, 70, 70, 0.25);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    // min-width: 374px;
    width: 100%;
    height: 100%;
}