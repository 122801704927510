.video_wrapper {
    margin-top: 16px;

    .top_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #2152C4;
        border-radius: 8px 8px 0 0;
        padding: 4px 16px;
        padding-bottom: 0px;
        margin-bottom: -6px;

        .video_back_btn {
            font-size: 34px;
            left: 0px;
            color: #F6BA23;

            img {
                width: 32px;
                height: 32px;
            }
        }

        .icons {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 14px;
            font-size: 28px;

            .full_screen {
                font-size: 24px;
            }
        }
    }

    .video_play_iframe {
        width: 100%;
        height: 740px;

        @media screen and #{$mediaQueryRange-midMobileDevice} {
            height: 220px !important;
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
            height: 380px;
        }

        @media screen and #{$mediaQueryRange-tabletDevice} {
            height: 500px;
        }

        iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }
}