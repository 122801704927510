@import "../mixins/mixins";

.mainHeader {
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    // background: $brand-blackColor 0% 0% no-repeat padding-box;
    background-color: #2152c4;
    z-index: 10;
}

.mainHeader .mainHeader-container {
    min-height: 85px;
    padding: 4px 0px;
    @include Flex(row, center, space-around);
    flex-wrap: nowrap;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
}

.mainHeader .mainHeader-leftSection {
    display: flex;
    align-items: center;
    width: 195px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        width: 145px;
    }
}

.mainHeader .mainHeader-logo {

    // padding-right: 10px;
    // margin-top: 6px;
    img {
        vertical-align: middle;
        width: 200px;
    }

    @media screen and #{$mediaQueryRange-mobileDevice} {
        margin-top: 0;
    }
}

.show-desktop {
    display: block !important;
}

.show-mobile {
    display: none !important;
}

.mainHeader-middleSection {
    // flex: 1 1 auto;
    // padding: 0 20px;
    // margin-left: 20px;
    // width: 66%;
    padding-left: 10px;

    ul {
        // display: inline-flex;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 10px;

        li {
            // flex: 1 1 auto;
            // margin-right: 28px;
            position: relative;
            padding-right: 20px;

            .mainHeader-item {
                color: $brand-textColor;
                font-size: 18px;
                white-space: nowrap;
                cursor: pointer;
                font-weight: 400;
                display: flex;
                align-items: center;
                position: relative;

                &.active {
                    color: $brand-secondaryColor;
                }

                .vector_arrow {
                    width: 16px;
                    margin-left: 3px;
                }

                .header-icons {
                    width: 18px;
                    height: 18px;
                    margin-right: 3px;
                }
            }

            &.hit:hover {
                .hitSix {
                    display: block;
                }
            }

            &.mp:hover {
                .mp-dropdown {
                    display: block;
                }
            }

            &.vctt:hover {
                .vctt-dropdown {
                    display: block;
                }
            }

            &.more:hover {
                .more-dropdown {
                    display: block;
                }
            }

            .navlink-dropdown {
                position: absolute;
                background: #0f2651 0% 0% no-repeat padding-box;
                box-shadow: 0px 4px 12px #00000040;
                width: auto;
                font-size: 18px;
                border-radius: 7px;
                display: none;
                overflow: hidden;
                z-index: 99;
                white-space: nowrap;

                ul {
                    display: inline-block;

                    li {
                        @include Flex(row, center, flex-start);
                        padding: 7px 8px;
                        // margin-top: 10px;
                        width: 100%;

                        .icon_container {
                            width: 21px;
                            display: grid;
                            place-items: center;

                            img {
                                width: 100%;
                            }
                        }

                        .text_container {
                            font-size: 18px;
                            margin-left: 8px;
                        }

                        &:hover {
                            cursor: pointer;
                            background: #030814 0% 0% no-repeat padding-box;
                        }
                    }
                }
            }
        }
    }
}

.mainHeader-rightSection {
    display: flex;
    align-items: center;
    justify-content: end;
    // width: 18%;
    width: auto;
}

.mainHeader-beforeLogin {
    display: block;
    text-align: center;
    padding: 6px 0px;

    .login_btn {
        font-weight: 500;
        height: 30px !important;
        background-color: #2152c4;
        border: 1px solid $brand-textColor;
        color: $brand-textColor;
        margin: 4px;
        font-size: 14px;
        border-radius: 24px !important;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            margin: 2px;
            border-radius: 13px !important;
            height: 26px !important;
            font-size: 12px;
        }
    }

    .signup_btn {
        font-size: 14px;
        height: 30px !important;
        font-weight: 600;
        border-radius: 24px !important;
        color: $brand-blackColor;
        background-color: $brand-secondaryColor;
        border: none;
        margin: 4px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            margin: 2px;
            border-radius: 13px !important;
            font-size: 12px;
            height: 26px !important;
        }
    }
}

.mainHeader-loginRegister {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .outline {
        border: 2px solid #f6ba23 !important;
        color: $brand-textColor !important;
    }
}

.mainHeader-afterLogin .mainHeader-profile {
    @include Flex(row, center, flex-start);

    .coin_section {
        .userid {
            color: #ad9ad9;
        }
    }

    .user_coin {
        @include Flex(row, center, flex-end);
        gap: 4px;

        .coin_container {
            display: grid;
            place-items: center;
            width: 17px;

            img {
                width: 100%;
                vertical-align: middle;
            }
        }
    }

    .profileIcon {
        width: 45px;
        margin-left: 10px;
        position: relative;
        cursor: pointer;

        img {
            width: 100%;
        }

        &:hover {
            .profile {
                display: block;
            }
        }

        .navlink-dropdown {
            position: absolute;
            background: #0f2651 0% 0% no-repeat padding-box;
            box-shadow: 0px 4px 12px #00000040;
            width: auto;
            font-size: 14px;
            border-radius: 7px;
            display: none;
            overflow: hidden;
            z-index: 99;
            white-space: nowrap;
            right: -5px;

            ul {
                display: inline-block;

                li {
                    @include Flex(row, center, flex-start);
                    padding: 7px 8px;
                    // margin-top: 10px;
                    width: 100%;

                    .icon_container {
                        width: 21px;
                        display: grid;
                        place-items: center;

                        img {
                            width: 100%;
                        }
                    }

                    .text_container {
                        font-size: 18px;
                        margin-left: 12px;
                    }

                    &:hover {
                        cursor: pointer;
                        background: #202020 0% 0% no-repeat padding-box;
                    }
                }
            }
        }

        @media screen and #{$mediaQueryRange-mobileLayout} {
            width: 40px;
            &:hover {
                .profile {
                    display: none;
                }
            }

            img {
                width: 100%;
            }

            .navlink-dropdown {
                display: none;
            }
        }
    }
}

.mainHeader .mainHeader-hamBurger {
    display: none;
    padding-right: 4px;

    img {
        vertical-align: middle;
        width: 30px;
    }
}

// sideMenu

.sidemenu-opened {
    // overflow: hidden;
    position: fixed;
    width: 100%;
}

.homepage_layout {
    background-color: #071027;
}

.SIDEMENU-mask {
    background: rgba(22, 21, 21, 0.685);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: fixed;
    z-index: 114;
    display: block;
}

.sidemenu {
    position: absolute;
    top: 0;
    z-index: 1000 !important;
    width: 100vw;
    height: 100dvh;
    overflow: auto;
    transition: all 0.4s ease-in;

    &.hide {
        transform: translateX(-100%);
    }

    .sidemenu-head {
        @include Flex(row, center, space-between);
        padding: 12px 12px 12px 12px;
        font-size: 12px;
        font-weight: 100;
        background-color: #27448e;

        .btn {
            margin-top: 10px;
            height: 23px;
        }

        .side_menu_logo {
            img {
                width: 200px;
            }
        }
    }

    .sidemenu-body {
        display: flex;
        justify-content: space-between;

        ul {
            background: #071027;
            width: 100%;
            min-height: 100dvh;

            .games_list {
                border-top: 0.1px solid rgb(78, 75, 75) !important;
                border-bottom: none !important;
                padding: 10px 30px;
                margin: 0 -20px;
            }

            ol.accordion_content_box {
                li {
                    padding: 10px 20px 10px 62px;
                }
            }

            .sidmenu_games_list {
                padding: 15px 20px 0px 20px;
            }

            li {
                border-bottom: 0.1px solid rgb(78, 75, 75);
                padding: 15px 20px;
                font-size: 14px;
                font-weight: normal;
                background-color: #071027;

                .left {
                    @include Flex(row, center, flex-start);
                }

                .list_title {
                    padding: 0 15px;
                }

                .btn {
                    height: 27px;
                }

                .icon_container {
                    width: 30px;
                    height: 30px;

                    &.sidemenu_settings_icons {
                        width: 31px;
                    }

                    &.news_img {
                        width: 31px;

                        img {
                            height: 28px;
                        }
                    }

                    .store_img {
                        height: 26px;
                        width: 26px;
                    }

                    .news_img {
                        height: auto;
                    }

                    img {
                        width: 100%;
                        height: 30px;
                    }
                }

                .sidemenu-accordion {
                    @include Flex(row, center, space-between);
                    margin-bottom: 10px;
                }

                .collapsed {
                    .accordion_content_box {
                        display: none;
                    }

                    .right {
                        transform: rotate(180deg);
                        transition: all 0.35s;
                    }

                    .sidemen-games {
                        margin-bottom: 15px;
                    }
                }

                .collapse {
                    .sidemen-games {
                        margin-bottom: 15px;
                    }
                }

                .right {
                    transform: rotate(0deg);
                    transition: all 0.35s;
                    // transform-origin: center;
                }
            }
        }

        .sidemenu-closeIcon {
            // background-color: rgba(225, 225, 225, 0.3);
            padding: 8px 6px 0px 4px;
            font-size: 30px;
            color: #f6ba23;
        }
    }

    .accordion_content_box {
        .accordion_content {
            padding: 15px 70px;
            border-top: 0.1px solid rgb(78, 75, 75);
        }
    }
}

@media screen and (min-width: 1273px) {
    .sidemenu {
        transform: translateX(-100%);
    }

    .sidemenu-opened {
        position: static;
    }
}

@media screen and #{$mediaQueryRange-mobileLayout} {

    // .sidemenu & .hide{
    //     transform: translateX(0%);
    // }
    .mainHeader .mainHeader-container {
        @include Flex(row, center, space-between);
        min-height: 55px;
        padding: 0;
    }

    .hide-mobile {
        display: none !important;
    }

    .show-mobile {
        display: block !important;
    }

    .mainHeader .mainHeader-hamBurger {
        display: block;
        padding-right: 8px;

        img {
            width: 35px;
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .side_menu_logo {
        width: 130px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .mainHeader .mainHeader-logo {
        padding-right: 4px;

        img {
            width: 110px;
        }
    }

    .mainHeader .mainHeader-hamBurger {
        padding-right: 7px;

        img {
            vertical-align: middle;
            width: 25px;
        }
    }

    .mainHeader-rightSection {
        .btn {
            width: 65px;
            height: 31px;
        }
    }

    .coin_section {
        .userid {
            font-size: 14px;
        }
    }
}

.d-none {
    display: none;
}

.v-hidden {
    visibility: hidden;
}