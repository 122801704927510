.my_profile_container {
  margin: 15px auto;

  .title {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 20px;
  }

  .user_id {
    text-align: right;
    // background-color: #00984A;
  }

  .profile_card,
  .score_card_wrapper {
    margin: 0 auto;
    text-align: center;
  }

  .profle_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .profile_card {
    div {
      margin-bottom: 7px;
    }

    .user_coins {
      color: $brand-secondaryColorLight;
      font-weight: 600;
      letter-spacing: 1px;
    }

    img {
      width: 120px;
    }
  }

  .score_card_wrapper {
    background: transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 50%, #ffffff00 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 12px #00000040;
    border-radius: 4px;
    opacity: 1;
    width: 100%;
    display: grid;
    place-items: center;
    margin-top: 30px;
    padding: 2px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      width: 100%;
      margin-top: 20px;

      .score_card {
        font-size: 14px;
        height: 38px;

        p {
          margin: 0 3px;
        }
      }
    }

    @media screen and #{$mediaQueryRange-tabletDevice} {
      width: 100% !important;

      .score_card {
        height: 48px;
        font-size: 20px;
      }
    }
  }

  .score_card {
    width: 100%;
    height: 58px;
    background: #021433 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 12px #00000040;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 22px;

    p {
      margin: 0 6px;

      // text-decoration: underline;
      span:first-child {
        text-decoration: underline;
        color: white;
      }

      span {
        text-decoration: none;
        color: $brand-secondaryColorLight;
      }
    }


    .divider {
      background-color: #ffffff;
      width: 1px;
      height: 60%;
    }
  }

  .username_content_box {
    text-align: center;
    font-size: 16px;

    button {
      background: none;
      border: none;
      outline: none;
      text-decoration: underline;
      color: $brand-secondaryColorLight;
      font-size: 16px;
      margin-left: 5px;
      cursor: pointer;
    }

    @media screen and #{$mediaQueryRange-mobileDevice} {
      font-size: 13px;

      button {
        font-size: 12px;
      }
    }
  }

  .profile_banner {
    width: 100%;
    height: 100%;
    //background: url("../../images/banners/bg_Profile_section.png");
    background: transparent linear-gradient(180deg, #183468 0%, #152f61 17%, #092048 100%) 0% 0% no-repeat padding-box;
    box-shadow: 4px 4px 4px #00000026;
    // background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // background-position: bottom 50% right 60%;
    margin: 20px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    position: relative;

    .profile_banner_content {
      width: 100%;
      // height: 290px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 0 20px 0;

      // @media screen and #{$mediaQueryRange-mobileDevice} {
      //     height: 124px;
      // }

      .title {
        margin-top: 10px;
        margin-bottom: 10px;
        color: $brand-secondaryColorLight;
        font-size: 20px;
        padding-left: 50px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          padding-left: unset;
          margin-top: 8px;
          margin-bottom: 8px;
          font-size: 14px;
        }
      }

      .content {
        // padding-left: 50px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          // padding-left: unset;
          padding: 0px 10px;
          font-size: 12px;
        }
      }

      .profile_banner_coins {
        display: flex;
        justify-content: space-evenly;
        width: 100%;

        img {
          width: auto;
          height: 110px;
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
          img {
            width: 44px;
            height: 39px;
          }

          img:nth-child(2) {
            width: 46px;
            height: 52px;
          }

          img:nth-child(3) {
            width: 24px;
            height: 39px;
          }
        }
      }
    }
  }

  .accolades {

    // background-color: antiquewhite;
    .title {
      margin: 10px 0px;
      font-size: 18px;
    }

    .board_box {
      margin: 10px 0px;

      .bg_dark {
        width: 100%;
        // height: 100%;
        height: 50px;
        background: #021433 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 12px #00000040;
        border-radius: 4px;
        opacity: 1;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          font-size: 12px;
          line-height: 15px;
        }
      }

      .bg_flash {
        background: transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 50%, #ffffff00 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 12px #00000040;
        border-radius: 4px;
        opacity: 1;
        width: auto;
        // height: calc(100% + 2px);
        height: 52px;
        display: grid;
        place-items: center;
        font-size: $brand-fontSizeWebAccordHeading;
        font-weight: normal;
        color: $brand-textGrayColorLight;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          height: 40px;
        }
      }

      .bg_dark {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        color: white;
        font-size: 18px;

        span:nth-child(2) {
          color: #f8c84f;
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
          height: 38px;
          font-size: 14px;
          line-height: 15px;
        }
      }

      @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: $brand-fontSizeMobileAccordHeading;
        margin: 6px 0;
      }
    }
  }

  .personal_details_form_section {
    .title {
      span {
        font-size: 18px;
      }
    }

    .edit_text {
      font-size: 26px;
    }

    .pen_icon {
      height: 26px;
      width: 26px;
    }

    .error-msg-position {
      margin-left: 20px;
      margin-top: 2px;
    }

    .date_picker_container {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;

      .react-datepicker__current-month {
        display: none;
      }

      .react-datepicker__month-read-view {
        visibility: visible !important;
        left: 17px;
      }

      .react-datepicker__month-read-view--selected-month {
        // bottom: 10px;
        // left: 15px
        top: -10px;
      }

      .react-datepicker__month-read-view--down-arrow {
        left: -18px;
        top: -7px;
      }

      .react-datepicker__year-read-view--down-arrow {
        top: -8px;
        right: -60px;
      }

      .react-datepicker__year-read-view--selected-year {
        top: -9px;
        left: -3px;
      }

      .react-datepicker__month-dropdown {
        background: transparent linear-gradient(180deg, #183468 0%, #152f61 17%, #092048 100%) 0% 0% no-repeat padding-box;
        width: 40%;
      }

      .react-datepicker__year-dropdown {
        background: transparent linear-gradient(180deg, #183468 0%, #152f61 17%, #092048 100%) 0% 0% no-repeat padding-box;
        width: 40%;
        left: auto !important;
      }

      .react-datepicker__navigation {
        padding: none !important;
        margin: none !important;
      }

      .react-datepicker__year-option--selected {
        left: auto !important;
      }

      .react-datepicker__year-dropdown {
        left: auto !important;
      }

      .react-datepicker__year-read-view {
        visibility: visible !important;
      }

      .react-datepicker__month-option--selected {
        left: auto !important;
      }

      .react-datepicker__navigation--years-upcoming {
        background-image: url(../../images/png/white_arrow.png);
        background-size: 15px 7px;
        background-repeat: no-repeat;
        left: 27px;
        top: -10px;
        transform: rotate(180deg);
      }

      .react-datepicker__navigation--years-previous {
        background-image: url(../../images/png/white_arrow.png);
        background-size: 15px 7px;
        background-repeat: no-repeat;
        left: 36px;
        top: 9px
      }

      span {
        position: absolute;
        right: 0;
        margin-right: 15px;
        // margin-top: 4px;
        margin-left: 7px;
        cursor: pointer;

        img {
          vertical-align: middle;
        }
      }

      .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__header {
        // background: transparent linear-gradient(181deg, #444a57 0%, #292d39 100%, #000000 100%) 0% 0% no-repeat
        //     padding-box;
        background-color: #27448e;
        color: white !important;
        border-bottom: none;
      }

      .react-datepicker {
        // background: transparent linear-gradient(181deg, #444a57 0%, #292d39 100%, #000000 100%) 0% 0% no-repeat
        //     padding-box;
        background-color: #27448e;
        color: white !important;
        border: 1px solid #444a57;
      }

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        color: white;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        color: #c2c2c2;
      }

      .react-datepicker__day:hover,
      .react-datepicker__month-text:hover,
      .react-datepicker__quarter-text:hover,
      .react-datepicker__year-text:hover {
        background-color: black;
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        background-color: black;
        color: #c2c2c2;
      }

      .react-datepicker__day--disabled,
      .react-datepicker__month-text--disabled,
      .react-datepicker__quarter-text--disabled,
      .react-datepicker__year-text--disabled {
        cursor: default;
        color: #ccc !important;
      }

      .react-datepicker__day--disabled:hover,
      .react-datepicker__month-text--disabled:hover,
      .react-datepicker__quarter-text--disabled:hover,
      .react-datepicker__year-text--disabled:hover {
        background: none !important;
      }

      .react-datepicker__navigation {
        // border: 1px solid white;
        padding: 0px 3px;
        margin: 4px;
        border-radius: 4px;
        height: 23px;
        width: 23px;
      }

      .react-datepicker__navigation-icon--next {
        left: 2px;
      }

      .react-datepicker__navigation-icon--previous {
        right: -5px;
      }

      .react-datepicker__navigation--next {
        right: 2px;
      }

      .react-datepicker__navigation-icon {
        &::before {
          top: 5px;
        }
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected {
        background-color: black;
        color: white;
      }

      .react-datepicker-popper[data-placement^="bottom"] {
        padding-top: 5px;
      }
    }

    @media screen and #{$mediaQueryRange-mobileDevice} {
      .edit_text {
        font-size: 18px;
      }

      .pen_icon {
        height: 18px;
        width: 18px;
      }
    }
  }

  .form_container_wrapper {
    // padding: 10px 50px;

    .form_button {
      button {
        // background: transparent linear-gradient(180deg, #fe8163 0%, #b25a45 100%) 0% 0% no-repeat padding-box;
        background-color: $brand-secondaryColor;
        border-radius: 27px;
        // color: white;
        color: $brand-blackColor;
        height: 50px;
        border: none;
        outline: none;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
      }

      .pointer {
        margin-top: 20px;
      }

      .btnDisabled {
        margin-top: 20px;
      }
    }

    @media screen and #{$mediaQueryRange-mobileDevice} {
      padding: unset;
    }
  }

  .form_container {
    background: transparent linear-gradient(180deg, #183468 0%, #152f61 17%, #092048 100%) 0% 0% no-repeat padding-box;
    border-radius: 12px;
    width: 100%;
    // height: 900px;
    padding: 30px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      height: unset;
    }

    .form_group {
      @include Flex(row, flex-start, space-between);
      width: 100%;
      flex-wrap: wrap;
      gap: 20px;

      .form_control {
        width: 48%;
        @include Flex(column, flex-start, flex-start);

        label {
          margin-bottom: 5px;
          color: $brand-textGrayColorLight;
        }

        input {
          width: 100%;
          height: 50px;
          border-radius: 25px;
          background-color: #030814;
          outline: none;
          border: 1px solid#2D5FD5;
          padding-left: 20px;
          font-size: 18px;
          color: white;

          // color: #797070;
          //opacity: 1;
          &::placeholder {
            color: white;
            -webkit-text-fill-color: white;
          }

          @media screen and #{$mediaQueryRange-mobileDevice} {
            border-radius: 20px;
          }
        }

        ​ input[type="date"]::-webkit-calendar-picker-indicator {
          cursor: pointer;
          margin-right: 12px;
          opacity: 1;
          filter: invert(0.8);
        }

        input:disabled {
          color: #797070;
          opacity: 1;
          -webkit-text-fill-color: #797070;

          &::placeholder {
            color: #797070;
            -webkit-text-fill-color: #797070;
          }

          // color: rgb(121, 112, 112);

        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
          width: 100%;

          input {
            // width: unset;
            height: 40px !important;
            font-size: 14px;
          }
        }
      }

      .grow {
        width: 100%;
      }
    }

    .radio_group {
      margin: 20px 0;

      color: $brand-textGrayColorLight;

      .radio_title {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .item_group {
        @include Flex(row, center, flex-start, 20px);

        .radio_item {
          @include Flex(row, center, center, 5px);
        }

        input[type="radio"] {
          -webkit-appearance: none;
          appearance: none;
          margin: 0;
          font: inherit;
          color: $brand-secondaryColorLight;
          width: 1.15em;
          height: 1.15em;
          border: 0.15em solid $brand-secondaryColorLight;
          border-radius: 50%;
          transform: translateY(-0.075em);

          display: grid;
          place-content: center;
        }

        input[type="radio"]::before {
          content: "";
          width: 0.65em;
          height: 0.65em;
          border-radius: 50%;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em $brand-secondaryColorLight;
          background-color: CanvasText;
        }

        input[type="radio"]:checked::before {
          transform: scale(1);
        }
      }
    }

    .form_address_container {
      margin: 20px 0;

      .address_title {
        margin: 10px 0;
        color: $brand-secondaryColorLight;
        font-size: 18px;
        @include Flex(row, center, space-between);

        img {
          width: 20px;
        }
      }
    }
  }
}

.button_control {
  width: 48%;
  @include Flex(column, flex-start, flex-start);

  label {
    margin-bottom: 5px;
    color: $brand-textGrayColorLight;
  }

  button {
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background-color: #030814;
    outline: none;
    border: 1px solid #2d5fd5;
    padding-left: 20px;
    font-size: 18px;
    color: rgb(121, 112, 112);
    text-align: start;
    cursor: pointer;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      border-radius: 20px;
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    margin-right: 12px;
    opacity: 1;
    filter: invert(0.8);
  }

  input:disabled {
    color: rgb(121, 112, 112);
  }

  @media screen and #{$mediaQueryRange-mobileDevice} {
    width: 100%;

    input {
      // width: unset;
      height: 38px;
      font-size: 14px;
    }
  }
}

.profile_icons_box {
  display: flex;
  flex-wrap: wrap;
  // height: 100%;
  justify-content: center;
  margin: 30px 30px 0 30px;

  .profice_icon_wrapper {
    border-radius: 50%;
    width: 114px;
    margin: 4px;
    position: relative;

    .profile_icon {
      height: 100%;
      width: 100%;
      cursor: pointer;
    }

    .icon_check {
      position: absolute;
      font-size: 25px;
      color: $brand-textColor;
      background-color: $brand-textBlueColor;
      border-radius: 50%;
      right: 3%;
      top: 1%;
      width: 23px;
      height: 23px;
    }
  }
}

.selectAvtar {
  @include Flex(row, center, center);
  padding-bottom: 6px;
  margin-bottom: 6px;
  color: rgb(17, 204, 101);
  font-weight: 500;
  letter-spacing: 0px;

  .icon_check {
    width: 16px;
    height: 16px;
  }

  div {
    margin-left: 6px;
    font: normal normal normal 14px/18px $brand-fontFamily;
  }

  .icon_check {
    background-color: rgb(17, 204, 101);
    border-radius: 12px;
    color: white;
  }
}

.myprofile_username_modal {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  padding: 45px;

  // height: 450px;
  // margin: 30px;
  @media screen and #{$mediaQueryRange-mobileDevice} {
    padding: 22px;
  }

  .title {
    // text-align: center;
    font-size: 20px;
    color: $brand-secondaryColorLight;
    margin-bottom: 10px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      font-size: 16px;
    }
  }

  .content {
    margin: 10px auto;
    line-height: 25px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      font-size: 14px;
      line-height: unset;
      margin: unset;
    }
  }

  .light {
    color: $brand-textGrayColorLight;
    font-size: 16px;
    font-weight: lighter;
    margin: 10px auto;
    line-height: 20px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      line-height: unset;
    }
  }

  .label {
    margin-bottom: 10px;
    color: $brand-textGrayColorLight;
    font-size: 16px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      margin-bottom: 6px;
      font-size: 16px;
    }
  }

  .form_input {
    background: #030814 0% 0% no-repeat padding-box;
    width: 100%;
    outline: none;
    border: 1px solid $brand-textBlueColor;
    height: 50px;
    border-radius: 30px;
    padding-left: 15px;
    font-size: 18px;
    color: $brand-textColor;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      height: 36px;
      font-size: 16px;
    }
  }

  .form_error {
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $brand-textGrayColorLight;
    font-size: 14px;
    margin-left: 14px;

    span:nth-child(1) {
      color: $brand-textDanger;
    }

    @media screen and #{$mediaQueryRange-mobileDevice} {
      font-size: 10px;
    }
  }

  .btn-large {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    font: normal normal 600 20px $brand-fontFamily;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      font: normal normal 600 16px $brand-fontFamily;
      height: 40px;
    }
  }
}

.myprofile_username_success_modal {
  .games_Feedback_popup {
    min-height: 220px;

    .popup_content {
      .content {
        font-size: 16px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          font-size: 13px;
        }
      }

      @media screen and #{$mediaQueryRange-mobileDevice} {
        padding: 20px 0px;
      }

      padding: 20px 20px;
    }
  }
}

.verifiction_modal_wrapper {
  .games_Feedback_popup {
    .success-content {
      font-size: 18px;
    }

    .content {
      font-size: 22px;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: 18px;
      }
    }

    .btn-wrapper {
      margin: 0;

      .btn.btnMedium {
        @media screen and #{$mediaQueryRange-mobileDevice} {
          width: 250px;
        }
      }
    }
  }

  .form_group {
    width: 100%;
    text-align: center;
  }

  .btn.btnMedium {
    min-height: 40px;
    width: 350px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      width: 250px;
    }
  }

  .form_control {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;

    &.phone-group-dropdown {
      position: relative;

      // .select-dropdown-icon {
      //   position: absolute;
      //   top: 16px;
      //   left: 57px;
      //   font-size: 20px;

      //   @media screen and #{$mediaQueryRange-mobileDevice} {
      //     top: 11px;
      //     left: 35px;
      //   }
      // }
    }

    select {
      width: 100%;
      background-color: #030814;
      border: 0.5px solid #030814;
      border-radius: 24px;
      color: white;
      font-size: 16px;
      height: $brand-InputHeightDesktop;
      padding-left: 20px;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        height: $brand-InputHeightMobile;
        font-size: 14px;
      }
    }

    input {
      width: 100%;
      height: $brand-InputHeightDesktop;
      border-radius: 26px;
      background-color: #030814;
      outline: none;
      border: 1px solid #2d5fd5;
      padding-left: 20px;
      font-size: 18px;
      color: #ffffff;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        height: $brand-InputHeightMobile;
        font-size: 14px;
      }
    }

    .country_input {
      width: 33%;
      margin-right: 10px;
      padding-left: 18px;
      border-radius: 26px;
      border: $countryCode_border;
      appearance: none;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        padding-left: 10px;
        margin-right: 5px;
        width: 75px;
      }
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      cursor: pointer;
      margin-right: 12px;
      opacity: 1;
      filter: invert(0.8);
    }

    input:disabled {
      color: rgb(121, 112, 112);
    }

    @media screen and #{$mediaQueryRange-mobileDevice} {
      width: 100%;
    }
  }
}

.otp_filed_box {
  margin-top: 17px;

  .resend_otp {
    font-size: 12px;
    padding: 8px 0px 0 0;
  }

  input {
    width: 50px;
    height: 50px;
    margin-inline: 15px;
    background-color: #030814;
    border: 0.5px solid #2d5fd5;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    text-align: center;
  }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
  .otp_filed_box {
    margin-top: 20px;

    input {
      margin-right: 3px;
      width: 42px;
      height: 42px;
    }
  }
}

// player referred styles

$even-bg: #0f2651 0% 0% no-repeat padding-box;
$odd-bg: #183468 0% 0% no-repeat padding-box;

.stackbox_container {
  .back-button {
    top: 86px;
    left: 80px;

    @media screen and #{$mediaQueryRange-midMobileDevice} {
      // top: 71px !important;
      left: 10px !important;
    }

    @media screen and #{$mediaQueryRange-mobileDevice} {
      top: 122px;
      left: 16px;
    }

    @media screen and #{$mediaQueryRange-tabletDevice} {
      top: 87px;
      left: 20px;
    }

    .backButton_Text {
      @media screen and #{$mediaQueryRange-midMobileDevice} {
        font-size: 14px;
      }
    }
  }
}

.page_title {
  text-align: center;
  font-size: 26px;
  margin: 32px auto 20px auto;

  span {
    font-weight: 900;
    color: $brand-secondaryColorLight;
  }

  @media screen and #{$mediaQueryRange-mobileDevice} {
    text-align: center;
    font-size: 18px;
    margin: 10px auto 10px auto;
  }

  @media screen and #{$mediaQueryRange-midMobileDevice} {
    font-size: 20px;
    margin: 20px auto 20px auto;
  }
}

.referred_players_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: start;

  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
    background: #1b1b1b;
  }

  &::-webkit-scrollbar-thumb {
    background: #394054;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .referred_player_item {
    background: $even-bg;
    @include Flex(row, center, flex-start);
    padding: 0 30px;

    .player_id {
      min-width: 3%;
    }

    .player_name {
      // max-width: 60%;
      flex-grow: 1;
    }

    .amount,
    .referred_count {
      width: 10%;
      text-align: center;
    }

    width: 100%;
    height: 51px;
    box-shadow: 0px 4px 12px #00000040;
    border-radius: 4px;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      height: 30px;
      border-radius: 0;
    }

    .player_avatar {
      margin: 0 10px;
    }

    .player_avatar_img {
      width: auto;
      max-width: 40px;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        width: 20px;
        height: 20px;
      }
    }

    .star_icon,
    .player_avatar {
      width: auto;
      display: grid;
      place-items: center;
    }

    .star_icon {
      max-width: 5%;
    }

    .star_icon_img {
      width: 15px;
      margin: 0 auto;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        width: 9px;
        height: 8px;
      }
    }
  }

  .flash_container:nth-last-of-type(even) {
    .referred_player_item {
      background: $odd-bg;
    }
  }

  .flash_container {
    background: transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 50%, #ffffff00 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 12px #00000040;
    border-radius: 4px;
    opacity: 1;
    width: 100%;
    // margin: 0 auto;
    height: 53px;
    // height: calc(100% + 2px);
    // display: grid;
    // place-items: center;
    @include Flex(row, center, center);

    &.faded {
      opacity: 0.18 !important;

      .referred_player_item {
        background: #000000 0% 0% no-repeat padding-box !important;
      }
    }

    @media screen and #{$mediaQueryRange-mobileDevice} {
      width: 100%;
      height: 32px;
    }
  }
}

// player referred styles

// invite friends styles

.invite_friends_model {
  @include Flex(column, stretch, space-evenly);
  padding: 0px 30px;
  // min-height: 170px;
  height: auto;
  margin: 20px auto;
  margin-bottom: 30px;

  .title {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: $brand-secondaryColorLight;
  }

  .content {
    text-align: center;
    font-size: 14px;
    font-weight: lighter;
    margin-bottom: 15px;
  }

  .form_group {
    max-height: 360px;
    overflow-y: auto;
    width: 450px;
    // margin-top: 10px;

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
      background: #232833;
    }

    &::-webkit-scrollbar-thumb {
      background: #8b8b8b80;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  .form_group_inline {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;

    @include Flex(row, center, space-between, 5px);
    margin: 15px auto;

    @media screen and #{$mediaQueryRange-mobileDevice} {
      height: 32px;
      margin: 14px auto;
      gap: 3px;
    }

    .select_wrapper {
      width: 86px;
      border-radius: 26px;
      border: $countryCode_border;
      background-color: #030814;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        width: 76px;
      }
    }

    .dropdown_wrapper {
      .dropdown_button {
        height: 40px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          width: 50px;
        }
      }
    }

    &.phone-group-dropdown {
      position: relative;

      .select-dropdown-icon {
        position: absolute;
        top: 11px;
        left: 57px;
        font-size: 20px;

        @media screen and #{$mediaQueryRange-mobileDevice} {
          top: 6px;
          left: 35px;
        }
      }
    }

    select {
      width: 100%;
      background-color: #030814;
      border: 0.5px solid #030814;
      border-radius: 24px;
      color: white;
      font-size: 16px;
      height: 40px;
      padding-left: 20px;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        height: 40px;
        font-size: 14px;
      }
    }

    select:focus {
      outline: none;
    }

    .country_input {
      width: 90%;
      // padding-left: 20px;
      // border-radius: 26px;
      border: none;
      // appearance: none;

      @media screen and #{$mediaQueryRange-mobileDevice} {
        padding-left: 7px;
      }
    }

    .form_input {
      background: #030814 0% 0% no-repeat padding-box;
      border: 0.5px solid #2d5fd5;
      border-radius: 20px;
      padding: 0 10px;
      font-size: 13px;
      margin-left: 2px;
      //color: $brand-textGrayColorLight;
      flex-grow: 1;
      color: $brand-textColor;
    }

    .btn_invite {
      font-size: 16px;
      width: 80px;
      height: 100%;
      border-radius: 24px;
      color: $brand-textColor;
      cursor: pointer;
      outline: none;
      box-shadow: none;
      border: none;
      @include Flex(row, center, center);
    }

    .invite {
      background: #f6ba23 0% 0% no-repeat padding-box;
      margin-right: 2px;
      color: #000000;
      font-size: 14px;
      font-weight: $brand-fontWeight;
      width: 80px;
      overflow: hidden;
    }

    .invited {
      background-color: green !important;
      margin-right: 22px;
      font-size: 14px;
      font-weight: $brand-fontWeight;
    }

    .icon_delete {
      font-size: 20px;
      color: $brand-textGrayColorLight;
      cursor: pointer;
    }
  }

  .add_more {
    font: normal normal normal 14px/18px $brand-fontFamily;
    color: #c1c1c1;
    @include Flex(row, center, flex-start, 5px);

    .icon_add {
      background-color: $brand-secondaryColor;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      color: #000000;
      border-radius: 50%;
      cursor: pointer;
      font-size: 12px;
    }
  }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
  .invite_friends_model {
    gap: 0;
    padding: 0px;
    margin-top: 0px;

    .title {
      font-size: 16px;
    }

    .add_more {
      font-size: 12px;
    }

    .form_group {
      max-width: 320px;
    }

    .form_group_inline .btn_invite {
      height: 40px;
    }
  }

  .invite_friends_model .content {
    font-size: 12px;
  }
}

@media screen and #{$mediaQueryRange-midMobileDevice} {
  .invite_friends_model .form_group_inline .form_input {
    width: 160px;
    font-size: 13px;
    margin-right: 2px;
  }

  .invite_friends_model .form_group_inline .btn_invite {
    font-size: 12px;
    width: 65px;
    height: 40px;
  }

  .invite_friends_model {
    .form_group {
      width: 290px !important;
    }
  }
}

@media screen and #{$mediaQueryRange-tabletDevice} {
  .profile_icons_box {
    margin: 10px;

    .profice_icon_wrapper {
      width: 94px;
      margin: 7px;
      position: relative;
    }
  }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
  .profile_icons_box {
    margin: 10px;

    .profice_icon_wrapper {
      width: 62px;

      .icon_check {
        right: -8%;
        top: 1%;
        width: 18px;
        height: 18px;
      }
    }
  }

  .button_control {
    button {
      height: 40px;
      font-size: 14px;
    }
  }
}

@media screen and #{$mediaQueryRange-midMobileDevice} {
  .profile_icons_box {
    margin: 10px;

    .profice_icon_wrapper {
      width: 62px;

      .icon_check {
        position: absolute;
        right: -8%;
        top: 1%;
        width: 15px;
        height: 15px;
      }
    }
  }

  .selectAvtar {
    font-size: 10px;
  }
}

.verify {
  color: #797070;
  align-self: flex-end;
  font-size: 12px;
  padding: 5px 5px;
}

.err_message {
  color: red;
  font-size: 14px;
  text-align: center;
  padding-bottom: 8px;
}