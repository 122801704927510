.SB-loader {
    // padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 12px 0;

    svg {
        height: 30px;
        width: 80px;

        circle {
            fill: #fff;
        }
    }
}

.text-center {
    text-align: center;
    padding: 10px 0;
}

// img{
//     vertical-align: middle;
//     border-style: none;
//     max-width: 100%;
// }
.loaderSVGIcon {
    animation: rotating 1.4s linear infinite;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

img {
    border-style: none;
    max-width: 100%;
}
