.container {
    .games_banner_body {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        @media screen and #{$mediaQueryRange-mobileDevice} {
            display: block;
        }
        @media screen and #{$mediaQueryRange-tabletDevice} {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
        }
        .game_banner_wrapper {
            flex: 1 1 30%;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                width: 100%;
            }
            @media screen and #{$mediaQueryRange-tabletDevice} {
                flex: 1 1 48%;
            }

            .game_logo {
                width: 330px;
                height: 168px;
                text-align: center;
                margin-top: 16px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
