$brand-primaryColor: #1e242d;
$brand-secondaryColor: #f6ba23;
$brand-secondaryColorLight: #f8c84f;
$brand-secondaryColorDark: #b25a45;
$brand-tertiaryColor: #444a57;
$brand-textColor: #ffffff;
$brand-textActiveColor: #a88ee3;
$brand-blackColor: #000000;
$brand-textGrayColor: #858585;
$brand-textGrayColorLight: #c1c1c1;
$brand-textDanger: #ff4e4e;
$brand-textBlueColor: #2d5fd5;
$brand-textBlueColorLight: #27448e;
$brand-textInActiveColorLight: #9c9c9c;

$brand-fontFamily: Poppins;

$brand-fontSizeWebHeading: 24px;
$brand-fontSizeMobileHeading: 14px;

$brand-fontSizeWebPageHeading: 24px;
$brand-fontSizeMobilePageHeading: 20px;
$brand-fontSizeWebAccordHeading: 18px;
$brand-fontSizeMobileAccordHeading: 16px;
$brand-fontSizeAccordContaint: 14px;

$brand-fontWeight: 500;

$brand-InputHeightMobile: 46px;
$brand-InputHeightDesktop: 52px;
$brand-ButtonHeightMobile: 48px;
$brand-ButtonHeightDesktop: 54px;

$mediaQueryRange-smallMobile: "(min-width: 280px) and (max-width: 359px)";

$mediaQueryRange-smallTab: "(min-width: 768px) and (max-width: 459px)";

$mobileLayout-minWidth: 280px;
$mobileLayout-maxWidth: 459px;
$mediaQueryRange-midMobileDevice: "(min-width: #{$mobileLayout-minWidth}) and (max-width: #{$mobileLayout-maxWidth})";

$mobileLayout-minWidth: 459px;
$mobileLayout-maxWidth: 759px;
$mediaQueryRange-midTabletDevice: "(min-width: #{$mobileLayout-minWidth}) and (max-width: #{$mobileLayout-maxWidth})";

$mobileLayout-minWidth: 280px;
$mobileLayout-maxWidth: 1273px;
$mediaQueryRange-mobileLayout: "(min-width: #{$mobileLayout-minWidth}) and (max-width: #{$mobileLayout-maxWidth})";

$mobileDevice-minWidth: 280px;
$mobileDevice-maxWidth: 767px;
$mediaQueryRange-mobileDevice: "(min-width: #{$mobileDevice-minWidth}) and (max-width: #{$mobileDevice-maxWidth})";

$mobileDevice-minWidth: 768px;
$mobileDevice-maxWidth: 1023px;
$mediaQueryRange-tabletDevice: "(min-width: #{$mobileDevice-minWidth}) and (max-width: #{$mobileDevice-maxWidth})";

$lowDesktopDevice-minWidth: 1024px;
$lowDesktopDevice-maxWidth: 1279px;
$mediaQueryRange-lowDesktopDevice: "(min-width: #{$lowDesktopDevice-minWidth}) and (max-width: #{$lowDesktopDevice-maxWidth})";

@media screen and #{$mediaQueryRange-lowDesktopDevice} {
}

$desktopDevice-minWidth: 1024px;
$mediaQueryRange-desktopDevice: "(min-width: #{$desktopDevice-minWidth})";

@media screen and #{$mediaQueryRange-desktopDevice} {
}

$SB-tabletDevice-minWidth: 768px;
$SB-tabletDevice-maxWidth: 1023px;
$SB-mediaQueryRange-tabletDevice: "(min-width: #{$SB-tabletDevice-minWidth}) and (max-width: #{$SB-tabletDevice-maxWidth})";

@media screen and #{$SB-mediaQueryRange-tabletDevice} {
}

$teamCard_txtColor: #dbd5d5;
$kickOff_txtColor: #ae8a2b;
$msgBox-container_backGroundColor: #ffd6d6;
$msgBox-container_txtColor: #ff3a3a;
$countryCode_border: 1px solid #2d5fd5;
