.back-button {
    @include Flex(row, center, flex-start);
    // margin: 40px 0 0 200px;
    color: #f6ba23;
    position: absolute;
    top: 70px;
    left: 150px;

    .backButton_Text {
        font-size: 20px;
        font-weight: 900;
        margin: 0 7px;
    }

    .backButton_Icon {
        font-size: 44px;
        line-height: 1px;

        img {
            height: 40px;
            width: 40px;
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .back-button {
        top: 20px;
        left: 20px;

        .backButton_Text {
            font-size: 17px;
        }

        .backButton_Icon {
            font-size: 32px;
        }
    }
}