/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap"); */

/* @import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600&display=swap"); */

/* Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

body {
  margin: 0;
  /* font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #071027;
  color: white;

  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.socket_box {
  width: 600px;
  display: flex;
  height: 36px;
}

.socket_box_input {
  border: none;
  outline: none;
  color: #071027;
  flex-grow: 1;
  padding-left: 10px;
}

.socket_box_button {
  background-color: rgb(158, 88, 255);
  border: none;
  outline: none;
  width: 100px;
}

.socket_msg_list {
  margin-top: 20px;
  width: 600px;
  min-height: 400px;
  background-color: rgba(35, 43, 64, 0.6);
  padding: 20px 10px;
}

.socket_msg_list_item {
  background-color: rgba(8, 19, 45, 0.8);
  margin-bottom: 6px;
}

.socket_btn_group {
  margin-top: 16px;
  width: 600px;
  height: 33px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.socket_btn {
  border: none;
  outline: none;
  width: 100px;
  height: 100%;
  transition: all 0.2s ease-in;
}

.join {
  background-color: rgba(37, 185, 42, 0.5);
}

.join:hover {
  background-color: rgba(37, 185, 42, 0.9);
}

.exit {
  background-color: rgba(225, 62, 62, 0.5);
}

.exit:hover {
  background-color: rgba(225, 62, 62, 0.9);
}