.gain_coins_head {
    @include Flex(row, center, center);
    margin: 12px auto;

    .gain_coins_ref {
        background-color: #00984a;
        padding: 8px 16px;
        border-radius: 24px;
        position: absolute;
        right: 0;
    }

    .gain_coins_title {
        color: #f8c84f;
        font-size: $brand-fontSizeWebPageHeading;
        font-weight: $brand-fontWeight;
    }
}

.gain_coin_container {
    position: relative;

    .terms_banner_web {
        display: block;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            display: none;
        }
    }

    .terms_banner_mobile {
        display: none;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            display: block;
        }
    }

    .referAndEarnContent {
        margin-top: 10px;

        .content_box {
            border-radius: 4px;
            box-shadow: 0 4px 12px #00000040;
            color: #a2a2a2;
        }

        .cric-accordion {
            &.collapsed {
                .cric-Accordion-header {
                    padding: 10px 10px 10px 18px;
                }
            }

            .cric-Accordion-header {
                padding: 12px 10px 2px 18px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    padding: 10px !important;
                }

                background: #021433 0 0 no-repeat padding-box !important;

                .content_title {
                    color: #fff;
                    font-size: 16px;
                }
            }
        }

        .cric-accordion-content {
            padding: 0px 10px 20px 18px !important;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                padding: 0px 10px 10px 10px !important;
            }

            background: #021433 0 0 no-repeat padding-box !important;

            .content_description {
                .content_bold {
                    font-weight: 500;
                }

                .content_highlight {
                    color: white;
                    font-weight: 500;
                }

                p {
                    font-size: 14px;
                    padding-top: 8px;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        padding-top: 0px;
                        padding-bottom: 6px;
                    }
                }

                ol {
                    li {
                        font-size: 14px;
                        padding-top: 12px;
                    }
                }

                .telegram_refer_button {
                    margin-top: 10px;
                    align-items: center;
                    background-color: initial;
                    border: none;
                    border-radius: 12px;
                    box-shadow: none;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    gap: 0;
                    justify-content: center;
                    line-height: 22px;
                    outline: none;
                }
            }
        }
    }
}

.accordion_sectionOne {
    margin-bottom: 20px;

    .title {
        font-size: 16px;
        margin: 0 0 20px 0;
        color: white;
    }

    .sub-title {
        font-size: 16px;
        padding: 6px 0px;
        color: white;
    }

    .content {
        // margin: 0 0 20px 0;
        // padding: 0px 20px;
    }

    p {
        margin-bottom: 16px;
        font-size: 16px;
    }

    span {
        color: #f8c84f;
        //padding-left: 18px;
    }

    ul {
        padding-left: 20px;

        li {
            list-style-type: "→";
        }
    }

    ol {
        padding-left: 20px;

        li {
            list-style-type: decimal;
            line-height: 30px;
        }
    }

    .telegram_refer_button {
        @include Flex(row, center, center);
        background-color: transparent;
        border-radius: 12px;
        cursor: pointer;
        outline: none;
        box-shadow: none;
        border: none;
        line-height: 22px;
        margin-top: 10px;

        img {
            width: 398px;
        }
    }
}

.gain_coins_wrapper {
    min-height: 400px;

    .gain_coin_banner_desktop {
        img {
            display: block;
            width: 100%;
        }
    }

    .gain_coin_banner_mobile {
        img {
            display: none;
        }
    }

    .gain_coin_bannerHeader {
        background-position: center;
        background-size: cover;
        border-radius: 12px;
        @include Flex(row, center, flex-start);
        flex-wrap: wrap;
        // margin: 60px 0;
        margin-bottom: 16px;

        .gain_coin_banner {
            width: 100%;

            .gain_coin_banner_image_MB {
                display: none;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    display: block;
                }
            }

            .gain_coin_banner_image_DSK {
                display: block;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    display: none;
                }
            }
        }

        .gain_coinBanner_textHeader {
            text-align: center;
            padding-left: 48px;

            .refer {
                font-size: 60px;
            }

            .and {
                font-size: 50px;
                text-align: right;
                color: #fea792;
            }

            .Earn {
                font-size: 50px;
                text-align: right;
            }

            h1 {
                font-size: 60px;
            }

            h2 {
                color: #f8c84f;
                text-align: right;
                font-size: 50px;
            }
        }
    }

    .HowTogain_Accordion {
        .howToGainCoins_content {
            width: 100%;
            color: white;
            background: transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 50%, #ffffff00 100%) 0% 0% no-repeat
                padding-box;
            overflow: hidden;
            box-shadow: 0px 4px 12px #00000040;
            border-radius: 4px;
            margin: 15px auto;
            padding: 1px 0px;

            &.collapsed {
                .howToGainCoins_contentHeader {
                    &::after {
                        // transform: rotate(90deg);
                        transform: rotate(0deg);
                    }
                }

                .howToGainCoins_contentBody {
                    display: none;
                }
            }

            .howToGainCoins_contentHeader {
                background: #0f2651 0% 0% no-repeat padding-box;
                padding: 12px 18px;
                font-size: $brand-fontSizeWebAccordHeading;
                box-shadow: 0px 4px 12px #00000040;
                opacity: 1;
                @include Flex(row, center, space-between);
                cursor: pointer;

                // font-weight: 600;
                &::after {
                    // content: "\276F";
                    // transform: rotate(-90deg);
                    content: url("../../images/svg/down_arrow_accordian.svg");
                    transform: rotate(-180deg);
                    width: 1em;
                    // height: 1em;
                    text-align: center;
                    transition: all 0.35s;
                }

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    font-size: $brand-fontSizeMobileAccordHeading;
                    padding: 10px 12px;
                }
            }

            .howToGainCoins_contentBody {
                background: #0f2651 0% 0% no-repeat padding-box;
                padding: 0px 18px 12px 18px;
                font-size: $brand-fontSizeAccordContaint;
                color: #b7b7b7;

                .howToGainCoins_contentSection {
                    .content {
                        // padding: 0 20px;
                        // margin: 0 0 20px;

                        ul {
                            li {
                                display: flex;
                                padding-bottom: 6px;
                            }
                            span {
                                display: grid;
                                //place-items: center;
                            }
                        }

                        .title {
                            color: #ffffff;
                            font-size: $brand-fontSizeAccordContaint;
                            margin: 0 0 12px 0;

                            @media screen and #{$mediaQueryRange-mobileDevice} {
                                font-size: $brand-fontSizeAccordContaint;
                                margin: 0 0 5px;
                            }
                        }

                        span {
                            color: #f8c84f;
                        }
                    }

                    p {
                        margin-bottom: 10px;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            margin-bottom: 8px;
                        }
                    }
                }

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    padding: 0px 12px 1px 12px;
                }
            }
        }
    }
}

.gain_coin_banner {
    img {
        width: 100%;
    }
}

.model_x_container {
    max-width: 580px;
    // min-width: 280px;
    width: 36dvw;
    // min-height: 300px;

    .modal_footer {
        font-size: 12px;
        margin-bottom: 20px;
        text-align: center;
        color: #b4b4b4;
    }
}

.gaincoin_modal_wrapper {
    width: 100%;
    padding: 12px;

    .gaincoin_modal_head {
        //background: url("../../images/png/howtogaincoins/howtogaincoinspopupImgDSK.png");
        // padding: 0px 0px 10px 0px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 12px;
        opacity: 1;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            //background: url("../../images/png/howtogaincoins/howtogaincoinspopupImgMB.png");
            background-repeat: no-repeat;
            background-size: cover;
        }

        .title {
            text-align: center;
            font-size: 16px;
        }

        .logged_details {
            @include Flex(row, center, space-between);
            font-size: 12px;
            margin: 10px;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                font-size: 12px;
                margin: 10px 10px;
            }

            @media screen and #{$mediaQueryRange-midMobileDevice} {
                font-size: 12px;
                margin: 10px 10px;
            }

            .left {
                div {
                    padding-bottom: 6px;
                }
            }

            .right {
                .brand_secondary {
                    padding-bottom: 6px;
                }
            }
        }
    }

    .gaincoin_body {
        max-height: 250px;
        padding: 19px;
        background: #030814 0% 0% no-repeat padding-box;
        border-radius: 8px;
        color: #7a7a7a;
        margin: 10px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 6px;
            border-radius: 5px;
            background: #414141;
        }

        &::-webkit-scrollbar-thumb {
            background: #858585;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        .weekwise {
            margin-bottom: 10px;

            .title {
                font-size: 16px;
                margin-bottom: 4px;
            }

            .logged_details {
                @include Flex(row, center, space-between);
                font-size: 12px;
            }
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
            padding: 11px;
        }
    }
}

.modal_box_container {
    border-radius: 8px !important;
    margin: 0 auto;
    // width: 76dvw;
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .model_x_container {
        width: 100%;
    }

    .accordion_sectionOne {
        .content {
            .telegram_refer_button {
                margin: 0 auto;

                img {
                    width: 294px;
                }
            }

            .sub-title {
                font-size: 13px;
            }
        }

        p {
            font-size: 14px;
        }
    }

    .gain_coins_wrapper {
        .gain_coin_banner_desktop {
            img {
                display: none;
            }
        }

        .gain_coin_banner_mobile {
            img {
                display: block;
                width: 100%;
            }
        }

        .gain_coin_bannerHeader {
            margin-bottom: 10px;

            .gain_coinBanner_textHeader {
                padding-left: 0px;

                h2 {
                    font-size: 24px;
                }

                h1 {
                    font-size: 30px;
                }

                .refer {
                    font-size: 24px;
                }

                .and {
                    font-size: 20px;
                }

                .Earn {
                    font-size: 20px;
                }
            }
        }

        .gain_coin_container {
            .referAndEarnContent {
                .content_description {
                    .telegram_refer_button {
                        margin: 0 auto;
                        margin-top: 10px;
                        margin-bottom: 14px;

                        img {
                            width: 320px;
                        }
                    }
                }
            }
        }
    }

    .gain_coins_head {
        margin: 12px auto;

        .gain_coins_title {
            font-size: $brand-fontSizeMobilePageHeading !important;
        }

        .gain_coins_ref {
            padding: 4px;
            font-size: 14px;
        }
    }

    .gain_coin_banner {
        img {
            width: 100%;
        }
    }
}

@media screen and #{$mediaQueryRange-tabletDevice} {
    .model_x_container {
        width: 52dvw;
    }

    .gain_coins_wrapper {
        .gain_coin_bannerHeader {
            .gain_coin_banner {
                text-align: left;
            }

            .gain_coinBanner_textHeader {
                h2 {
                    font-size: 40px;
                }

                h1 {
                    font-size: 50px;
                }

                .refer {
                    font-size: 40px;
                }

                .and {
                    font-size: 36px;
                }

                .Earn {
                    font-size: 36px;
                }
            }
        }
    }
}

@media screen and #{$mediaQueryRange-midMobileDevice} {
    .modal_box_container {
        width: 84dvw;
    }

    .gain_coins_head {
        .title_for_mobile {
            font-size: $brand-fontSizeMobilePageHeading !important;
            position: relative;
            // left: -48px;
        }
    }

    .gain_coins_wrapper {
        .gain_coin_container {
            .referAndEarnContent {
                .content_description {
                    .telegram_refer_button {
                        img {
                            width: 270px;
                        }
                    }
                }
            }
        }
    }

    .gaincoin_modal_wrapper {
        .gain_coin_container {
            .referAndEarnContent {
                .content_description {
                    .telegram_refer_button {
                        img {
                            width: 270px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and #{$mediaQueryRange-smallMobile} {
    .accordion_sectionOne {
        .content {
            .telegram_refer_button {
                img {
                    width: 270px;
                }
            }
        }
    }

    .gain_coins_wrapper {
        .gain_coins_head {
            .gain_coins_ref {
                padding: 4px;
                font-size: 10px;
            }
        }

        .gain_coin_bannerHeader {
            .gain_coinBanner_textHeader {
                h2 {
                    font-size: 16px;
                }

                h1 {
                    font-size: 16px;
                }
            }
        }
    }
}
