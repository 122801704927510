.cric-accordion {
    width: 100%;
    color: white;
    background: transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 50%, #ffffff00 100%) 0% 0% no-repeat padding-box;
    overflow: hidden;
    box-shadow: 0px 4px 12px #00000040;
    border-radius: 4px;
    margin: 14px auto;
    padding: 1px 0px;

    .cric-Accordion-header {
        // padding: 18px 18px 6px 18px !important;
        padding: 12px 18px;
        font-size: $brand-fontSizeWebAccordHeading;
        // margin: 1px 0px;
        background: #021433 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 12px #00000040;
        opacity: 1;
        @include Flex(row, center, space-between);
        cursor: pointer;

        // font-weight: 600;
        &::after {
            // content: "\276F";
            // transform: rotate(-90deg);
            content: url("../../images/svg/down_arrow_accordian.svg");
            transform: rotate(-180deg);
            width: 1em;
            // height: 1em;
            //padding-left: 5px;
            text-align: center;
            transition: all 0.35s;
            display: block;
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
            padding: 10px 10px;
            font-size: $brand-fontSizeMobileAccordHeading;
        }
    }

    .cric-accordion-content {
        background: #021433 0% 0% no-repeat padding-box;
        padding: 0px 18px;
        font-size: $brand-fontSizeAccordContaint !important;
        padding-top: 0px;
        color: #b7b7b7;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            padding: 0px 10px;
        }
    }
}

.collapsed {
    .cric-Accordion-header {
        &::after {
            // transform: rotate(90deg);
            transform: rotate(0deg);
        }
    }

    .cric-accordion-content {
        display: none;
    }
}