.container_score {
    max-width: 804px;
    margin: 0 auto;
    padding: 0 12px;

    .score_wrapper {
        .heading_text {
            margin: 10px 0px;
            font-size: 20px;
        }

        .score_date_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .score_block {
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 5px;
                max-width: 410px;

                .form_input {
                    @include Flex(row, center, flex-start);
                    height: 40px;
                    padding: 0px;
                    width: 100%;

                    position: relative;
                    border-radius: 4px;
                    border: none;

                    input {
                        width: 100%;
                        height: 100%;
                        outline: none;
                        border: 1px solid #3D71EB;
                        padding: 0px 34px 0px 10px;
                        background-color: #183468;
                        color: white;
                        font-size: 16px;
                        border-radius: 6px;
                    }

                    span {
                        font-size: 20px;
                        position: absolute;
                        right: 0;
                        padding-right: 10px;
                        color: white;
                        cursor: pointer;
                        padding-top: 4px;
                    }
                }

                .live_text {
                    border-radius: 4px;
                    background-color: #183468;
                    border: 1px solid #3D71EB;
                    text-align: center;
                    padding: 8px 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 2px;
                    height: 40px;

                    div {
                        height: 6px;
                        width: 6px;
                        background-color: red;
                        border-radius: 50%;
                    }
                }

                .date_picker_container {
                    border-radius: 4px;
                    background-color: #183468;
                    border: 1px solid #3D71EB;
                    padding: 6px 8px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    padding-top: 10px;
                    .react-datepicker__current-month {
                        display: none;
                      }
                
                      .react-datepicker__month-read-view {
                        visibility: visible !important;
                        left: -6px;
                      }
                
                      .react-datepicker__month-read-view--selected-month {
                        // bottom: 10px;
                        // left: 15px
                        top: 0px;
                        position: relative;
                        left: 1px;
                      }
                
                      .react-datepicker__month-read-view--down-arrow {
                        // left: 53px;
                        top: 2px;
                      }
                
                      .react-datepicker__year-read-view--down-arrow {
                        top: 1px;
                        right: -14px;
                      }
                
                      .react-datepicker__year-read-view--selected-year {
                        top: -9px;
                        left: -3px;
                      }
                
                      .react-datepicker__month-dropdown {
                        background: transparent linear-gradient(180deg, #183468 0%, #152f61 17%, #092048 100%) 0% 0% no-repeat padding-box;
                        width: 53%;
                      }
                
                      .react-datepicker__year-dropdown {
                        background: transparent linear-gradient(180deg, #183468 0%, #152f61 17%, #092048 100%) 0% 0% no-repeat padding-box;
                        width: 34%;
                        left: auto !important;
                      }
                
                      .react-datepicker__navigation {
                        padding: none !important;
                        margin: none !important;
                      }
                
                      .react-datepicker__year-option--selected {
                        left: 58px !important;
                      }
                
                      .react-datepicker__year-dropdown {
                        left: auto !important;
                      }
                
                      .react-datepicker__year-read-view {
                        visibility: visible !important;
                      }
                
                      .react-datepicker__month-option--selected {
                        left: 101px !important;
                      }
                
                      .react-datepicker__navigation--years-upcoming {
                        background-image: url(../../images/png/white_arrow.png);
                        background-size: 15px 7px;
                        background-repeat: no-repeat;
                        left: -9px;
                        top: -10px;
                        transform: rotate(180deg);
                      }
                
                      .react-datepicker__navigation--years-previous {
                        background-image: url(../../images/png/white_arrow.png);
                        background-size: 15px 7px;
                        background-repeat: no-repeat;
                        left: 10px;
                        top: 9px
                      }
                    .react-datepicker-wrapper {
                        width: 0;
                    }

                    >span {
                        width: 20px;
                    }

                    .date_input {
                        width: 0px;
                        height: 0px;
                    }

                    .react-datepicker__input-container {
                        width: 0px;
                        height: 0px;
                    }

                    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
                        display: none;
                    }

                    .react-datepicker-popper {
                        margin-top: 10px;
                        margin-right: -30px;
                    }

                    .react-datepicker__header {
                        background-color: #27448E;
                        color: white !important;
                        border-bottom: none;
                    }

                    .react-datepicker {
                        background-color: #27448E;
                        color: white !important;
                        border: none;
                    }

                    .react-datepicker__current-month,
                    .react-datepicker-time__header,
                    .react-datepicker-year-header {
                        color: white;
                    }

                    .react-datepicker__day-name,
                    .react-datepicker__day,
                    .react-datepicker__time-name {
                        color: white;
                    }

                    .react-datepicker__day:hover,
                    .react-datepicker__month-text:hover,
                    .react-datepicker__quarter-text:hover,
                    .react-datepicker__year-text:hover {
                        background-color: black;
                    }

                    .react-datepicker__day--selected,
                    .react-datepicker__day--in-selecting-range,
                    .react-datepicker__day--in-range,
                    .react-datepicker__month-text--selected,
                    .react-datepicker__month-text--in-selecting-range,
                    .react-datepicker__month-text--in-range,
                    .react-datepicker__quarter-text--selected,
                    .react-datepicker__quarter-text--in-selecting-range,
                    .react-datepicker__quarter-text--in-range,
                    .react-datepicker__year-text--selected,
                    .react-datepicker__year-text--in-selecting-range,
                    .react-datepicker__year-text--in-range {
                        background-color: black;
                    }

                    .react-datepicker__day--disabled,
                    .react-datepicker__month-text--disabled,
                    .react-datepicker__quarter-text--disabled,
                    .react-datepicker__year-text--disabled {
                        cursor: default;
                        color: #ccc !important;
                    }

                    .react-datepicker__day--disabled:hover,
                    .react-datepicker__month-text--disabled:hover,
                    .react-datepicker__quarter-text--disabled:hover,
                    .react-datepicker__year-text--disabled:hover {
                        background: none !important;
                    }

                    .react-datepicker__navigation-icon--next {
                        left: -6px;
                    }

                    .react-datepicker__navigation-icon--previous {
                        right: -2px;
                    }

                    .react-datepicker__navigation--next {
                        right: -7px;
                    }

                    .react-datepicker__day--keyboard-selected,
                    .react-datepicker__month-text--keyboard-selected,
                    .react-datepicker__quarter-text--keyboard-selected,
                    .react-datepicker__year-text--keyboard-selected {
                        background-color: black;
                        color: white;
                    }

                    .react-datepicker-popper[data-placement^="bottom"] {
                        padding-top: 5px;
                    }
                }

                .like_logo {
                    border-radius: 6px;
                    background-color: #183468;
                    border: 1px solid #3D71EB;
                    padding: 8px 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        height: 18px;
                        width: 22px;
                    }
                }
            }

            .date_wrapper {
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 6px;
                font-size: 12px;
                max-width: 410px;
                width: 388px;

                .date_block {
                    border-radius: 6px;
                    background-color: #183468;
                    text-align: center;
                    padding: 3px 5px;
                    width: 100%;
                    // height: 40px;
                }

                .isActive {
                    background-color: #2152C4;
                }

                .date_month {
                    color: #9CBAFF;
                }
            }
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .container_score {

        // max-width: 520px;
        .score_wrapper {
            .heading_text {
                font-size: 16px;
            }

            .score_date_wrapper {
                display: block;
                align-items: center;

                .score_block {
                    width: 100%;
                }

                .date_wrapper {
                    width: 100%;
                    margin-top: 10px;
                }
            }
        }
    }
}

// @media screen and #{$mediaQueryRange-midMobileDevice} {
//     .container_score {
//         .score_wrapper {
//             .score_date_wrapper {
//                 .score_block {
//                     max-width: 240px;
//                 }

//                 .date_wrapper {
//                     max-width: 250px;
//                 }
//             }
//         }
//     }
// }