@import "normalize";
@import "./layouts/homeLayout.scss";
@import "./constants/variables";
@import "./components/header";
@import "./mixins/mixins";
@import "./base/buttons";
@import "./components/banner";
@import "./components/card";
@import "./components/trendingCard";
@import "./base/prefix";
@import "./components/superCard";
@import "./components/leaderboard";
@import "./components/winner";
@import "./components/footer";
@import "./components/signin";
@import "./components/signup";
@import "./components/myProfile";
@import "./components/accordion";
@import "./components/howToGainCoins";
@import "./components/modal";
@import "./components//termsAndConditions";
@import "./components/settings";
@import "./components/contactPreference";
@import "./components/changePassword";
@import "./components/prizes";
@import "./layouts/recoverLayout";
@import "./components/otp";
@import "./components/faq";
@import "./components/gamesFeedback";
@import "./components/common";
@import "./components//hitSix";
@import "./components/coinsMeanPrizes";
@import "./base/skeletonloader";
@import "./base/backBotton";
@import "./components/shareModal";
@import "./components/news";
@import "./components/highlights";
@import "./components/storeBanner";
@import "./components/goingViral";
@import "./components/score";
@import "./components/searchBar";
@import "./components/liveTable";
@import "./components/liveScore";
@import "./components/promotionBanner";
@import "./components/gameScreen";
@import "./components/scores";
@import "./components/viewallhighlights";
@import "./components/privacyPolicy";
@import "./components/promotion";
@import "./components/scorePreview";
@import "./components/videoLoad";
@import "./components/selectDropdown";
@import "./components/splide";
@import "./components/commentary";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}