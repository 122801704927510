.modal_box_container {
    // width: 100%;
    // height: 100%;
    max-width: 661px;
    height: auto;
    // max-height: 676px;
    /* UI Properties */
    background: transparent linear-gradient(180deg, #183468 0%, #152f61 17%, #092048 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 12px #00000040;
    // border-radius: 30px;
    opacity: 1;
    display: grid;
    place-items: center;
    overflow-y: auto;
    border: none !important;
}
.modal_box_container_link {
    // width: 100%;
    // height: 100%;
    max-width: 661px;
    height: auto;
    // max-height: 676px;
    /* UI Properties */
    background: transparent linear-gradient(180deg, #183468 0%, #152f61 17%, #092048 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 12px #00000040;
    // border-radius: 30px;
    opacity: 1;
    // display: grid;
    place-items: center;
    overflow-y: auto;
    border: none !important;
}

.modal_header {
    // background-color: rgb(0, 0, 0);
    // position: relative;
    height: 30px;
    width: 100%;

    .close_button {
        position: absolute;
        top: 25px;
        right: 25px;
        font-size: 35px;
        cursor: pointer;
        z-index: 9999;

        &.invite_friend {
            
            // right: 32px;
            // top: 12px;
            @media screen and #{$mediaQueryRange-mobileDevice} {
                right: 13px;
            }
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
            right: 13px;
            top: 13px;
            font-size: unset;
        }

        @media screen and #{$mediaQueryRange-tabletDevice} {
            right: 20px;
            top: 20px;
            font-size: unset;
        }

        img {
            width: 36px !important;
            right: 13px;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                width: 24px !important;
            }
        }
    }
}

.modal_box_container_small {
    width: 450px;
    height: auto;
    background: transparent linear-gradient(180deg, #183468 0%, #152f61 17%, #092048 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    display: grid;
    place-items: center;
    overflow-y: auto;
    border: none !important;
    margin: auto;
    border-radius: 20px;

    .games_Feedback_popup {
        .btn-wrapper {
            .btn.btnMedium {
                font-size: 18px;
                font-weight: 600;
                border-radius: 28px;
                min-height: $brand-ButtonHeightDesktop;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    border-radius: 24px;
                    font-size: 16px;
                    min-height: $brand-ButtonHeightMobile;
                }
            }
        }
    }

    .small_box_close_button {
        text-align: end;
        margin: 8px 10px 0 0;

        @media screen and #{$mediaQueryRange-midMobileDevice} {
            margin: 10px 16px 0 0;
        }
    }
}

@media screen and #{$mediaQueryRange-midMobileDevice} {
    .modal_box_container_small {
        width: 300px;
        margin: auto;
        border: 5px;
        border-radius: 20px;
    }
}

@media screen and #{$mediaQueryRange-midTabletDevice} {
    .modal_box_container_small {
        width: 346px;
        margin: auto;
        border: 5px;
        border-radius: 20px;
    }
}

.SB-modal-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
}