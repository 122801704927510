$preview-fontSizeWeb: 16px;
$preview-fontSizeMobile: 14px;
$preview-colorLightBlue: #183468;
$preview-colorMidBlue: #0f2651;
$preview-colorDarkBlue: #2152c4;

.score_preview_wrapper {
    margin-top: 6px;
    font-size: $preview-fontSizeWeb;

    @media screen and #{$mediaQueryRange-mobileDevice} {
        font-size: $preview-fontSizeMobile;
    }

    .preview_form {
        margin-top: 10px;

        .form_head {
            display: flex;
            justify-content: space-between;
            background-color: $brand-secondaryColorLight;
            align-items: center;
            padding: 0 6px;
            border-radius: 4px;

            .form-head-previewimg {
                width: 20%;
            }

            h4 {
                color: #000;
                //padding-left: 40px;
                width: 60%;
                text-align: center;
            }
            .form-head-select {
                width: 20%;
                text-align: right;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: auto;
                }
            }
            select {
                background-color: #0000005d;
                border-radius: 4px;
                margin: 3px;
                margin-left: 0;
                padding: 0 8px;
                &:focus{
                    outline: none;
                }
            }
        }

        .form_body {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 10px 4px 10px;
            background-color: $preview-colorLightBlue;
            margin-top: 4px;
            font-size: $preview-fontSizeWeb;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                font-size: $preview-fontSizeMobile;
                // justify-content: start;
                // gap: 10px;
            }

            .date_details {
                width: 20%;

                // @media screen and #{$mediaQueryRange-mobileDevice} {
                //     width: 22%;
                // }
            }

            .team_name {
                display: flex;
                justify-content: center;
                width: 60%;
                align-items: center;
                gap: 6px;

                .home_team_name {
                    min-width: 30px;
                    // width: 34%;
                    // text-align: end;
                }
                .team_scores {
                    display: flex;
                    min-width: 30px;
                    .HAscores {
                     padding: 0 4px;
                    }
                }
                
                .away_team_name {
                    min-width: 30px;
                }
            }

            .team_status {
                text-align: end;
                display: flex;
                justify-content: end;
                width: 20%;
                // padding-right: 30px;

                div {
                    padding: 3px;
                    border-radius: 4px;
                    width: 28px;
                    text-align: center;
                }

                .win {
                    background-color: #379824;
                }

                .loss {
                    background-color: #db1010;
                }

                .draw {
                    background-color: #818080;
                }
            }
        }

        // .form_body>div {
        //     flex-grow: 1;
        //     flex-basis: 0;
        //     text-align: start;
        // }

        .form_body:nth-child(2n-1) {
            background-color: $preview-colorMidBlue;
        }
    }

    .game_goals_details {
        background: $preview-colorMidBlue 0% 0% no-repeat padding-box;
        font-size: $preview-fontSizeWeb;
        margin-top: 6px;
        padding: 10px 12px;
        line-height: 22px;

        .game_score {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: $preview-fontSizeMobile;
        }
    }

    .head_to_head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        background-color: $brand-secondaryColorLight;
        color: #000;
        border-radius: 4px;
        padding: 3px 6px;
    }

    .match_count {
        text-align: center;
        margin-top: 10px;
        font-size: $preview-fontSizeWeb;
    }

    .preview_range {
        width: 80%;
        margin: 0 auto;
        margin-top: 12px;
        font-size: $preview-fontSizeWeb;

        .range_score {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .range_contain {
            background-color: $preview-colorDarkBlue;
            margin-top: 4px;
            width: 100%;

            .range_value {
                background-color: $brand-secondaryColor;
                text-align: right;
                padding: 7px 0;
                color: white;
            }
        }
    }

    .last_matches {
        margin-top: 10px;

        .status_block {
            display: flex;
            justify-content: center;
            margin-top: 6px;
            margin-bottom: 12px;

            div {
                border: 1px solid #fff;
                text-align: center;
                padding: 10px 22px;
            }
        }

        .game_details {
            display: flex;
            align-items: center;
            justify-content: start;
            font-size: $preview-fontSizeWeb;
            background-color: $preview-colorMidBlue;
            padding: 4px 8px;
            width: 100%;
            margin-top: 4px;

            @media screen and #{$mediaQueryRange-mobileDevice} {
                font-size: $preview-fontSizeMobile;
            }

            .game_date {
                width: 40%;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 30%;
                }
            }

            .team_data {
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 6px;
                width: 60%;

                .home_team_name {
                    width: 110px;
                    text-align: left;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media screen and #{$mediaQueryRange-mobileDevice} {
                        width: 70px;
                        min-width: 70px;
                    }
                }

                .team_scores {
                    display: flex;
                    min-width: 30px;
                    .HAscores {
                     padding: 0 4px;
                    }
                }

                .away_team_name {
                    text-align: start;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .game_details:nth-child(2n-1) {
            background-color: #10306b;
        }
    }

    .social_lineups_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 14px;

        .icon_Container {
            img {
                width: 26px;
                height: 26px;
            }
        }
    }
}
