@import "../mixins/mixins";

.current_leaderboard {
    margin-top: 20px;

    .leaderboard_title {
        font-size: 26px;
        font-style: italic;
        font-weight: bold;
    }

    .week {
        color: #C9B9EE;
        margin: 15px 0;
        width: 100%;
        @include Flex(row, center, space-between);
        font-size: 20px;
    }

    .card_wrapper {
        max-width: 450px;

    }

    .leader_board_wrapper {

        padding: 20px;
        // height: 248px;
        @include Flex(row, center, space-between);

        .image_section {
            width: 45%;
            position: relative;

            .label {
                position: absolute;
                top: 20px;
                left: 20px;
                transform: translate(-50%, -50%);
                background-color: #FE9A82;
                width: 20%;
                height: auto;
                // height: 43px;
                border-radius: 50%;
                text-align: center;
                line-height: 43px;
                color: black;
                font-size: 28px;
            }

            .imgContainer {
                width: 80%;

                .profile_img {
                    width: 100%;
                    margin: 0 0 0 40px;
                }
            }
        }

        .content_section {
            font-size: 22px;
            margin: 0 15px;
            width: 45%;

            div:nth-child(3) {
                @include Flex(row, center, flex-start);

                img {
                    width: 11%;
                    margin: 0 0 0 6px;
                }
            }

            .truncate {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 16ch;
            }

            div {
                margin-bottom: 5px;
            }

            div:nth-child(3) {
                color: #EE7A5E;
            }
        }
    }

    .profile_card_section {
        @include Flex(row, center, flex-start, 30px);

        .card_wrapper {
            border-radius: 25px !important;
        }
    }
}


@media screen and #{$mediaQueryRange-tabletDevice} {
    .current_leaderboard {
        .leader_board_wrapper {
            padding: 20px 14px;

            .image_section {
                .label {
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    font-size: 14px;
                }
            }

            .profile_img {
                width: 90px;
                margin: 0 0 0 24px !important;
            }

            .content_section {
                font-size: 14px;
                margin: 0 15px;
            }

        }

        .profile_card_section {
            gap: 15px !important;

            .card_wrapper:nth-last-child() {
                display: none;
            }
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .current_leaderboard {

        .leaderboard_title {
            font-size: 21px;
        }

        .week {
            font-size: 15px;
        }

        .leader_board_wrapper {
            padding: 20px 5px;

            .image_section {

                .label {
                    top: 8px;
                    left: 8px;
                    width: 23px;
                    height: 23px;
                    font-size: 18px;
                    line-height: 23px;
                    font-size: 70%;
                }

                .imgContainer {
                    .profile_img {
                        width: 100%;
                        margin: 0 0 0 12px;
                    }
                }
            }

            .content_section {
                font-size: 80%;
                margin: 0 15px;
                width: 45%;

                div:nth-child(3) {
                    @include Flex(row, center, flex-start);

                    img {
                        width: 11%;
                        margin: 0 0 0 6px;
                    }
                }

                div {
                    margin-bottom: 5px;
                }

                div:nth-child(3) {
                    color: #EE7A5E;
                }
            }
        }
    }

    .profile_card_section {
        gap: 15px !important;

        .card_wrapper:nth-child(3) {
            display: none;
        }
    }
}
@media screen and #{$mediaQueryRange-midMobileDevice} {
    .current_leaderboard {
        .leaderboard_title {
            font-size: 18px;
        }

        .week {
            font-size: 12px;
        }

        .leader_board_wrapper {
            padding: 20px 5px;

            .image_section {

                .label {
                    top: 5px;
                    left: 5px;
                    width: 15px;
                    height: 15px;
                    line-height: 15px;
                    font-size: 60%;
                }
            }

            .content_section {
                font-size: 60%;
                margin: 0 15px;
                width: 45%;

                div:nth-child(3) {
                    @include Flex(row, center, flex-start);

                    img {
                        width: 11%;
                        margin: 0 0 0 6px;
                    }
                }

                div {
                    margin-bottom: 5px;
                }

                div:nth-child(3) {
                    color: #EE7A5E;
                }
            }
        }
    }

    .profile_card_section {
        gap: 15px !important;

        .card_wrapper:nth-child(3) {
            display: none;
        }
    }
}