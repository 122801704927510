.viral_main_block {
    .viral_heading {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        margin-bottom: 12px;
        font-size: $brand-fontSizeWebHeading;
        font-weight: $brand-fontWeight;

        @media screen and #{$mediaQueryRange-mobileDevice} {
            font-size: 16px;
            margin-top: 14px;
            margin-bottom: 10px;
        }

        .heading_block {
            display: flex;
            justify-content: center;
            align-items: center;            

            img {
                width: 22px;
                margin-right: 4px;

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    width: 18px;
                }
            }
        }
    }

    .viral_wrapper {
        background: #183468 0% 0% no-repeat padding-box;
        border-radius: 8px;
        padding: 16px;

        .profile_block {
            display: flex;
            justify-content: space-between;
            align-items: start;

            .profile_containt {
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                gap: 10px;
                line-height: 24px;

                img {
                    width: 54px;
                    height: 54px;
                }

                div>div {
                    color: #9bb9ff;
                }
            }
        }

        .viral_info {
            line-height: 24px;

            div {
                color: #43b3ff;
            }
        }

        .viral_img {
            width: 100%;
            height: 650px;
            margin-top: 10px;

            @media screen and #{$mediaQueryRange-midMobileDevice} {
                height: 220px !important;
            }

            @media screen and #{$mediaQueryRange-mobileDevice} {
                height: 350px;
            }

            @media screen and #{$mediaQueryRange-tabletDevice} {
                height: 450px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .text_info {
            margin-top: 10px;
            color: #cecece;
        }

        ul {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 20px;
            margin-top: 10px;

            li {
                img {
                    width: 20px;
                    margin-right: 5px;
                }

                span {
                    font-size: 20px;
                }
            }
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .viral_main_block {
        .viral_wrapper {
            .profile_block {
                .profile_containt {
                    margin-bottom: 12px;
                    line-height: 18px;
                    font-size: 12px;

                    img {
                        width: 35px;
                        height: 35px;
                    }
                }

                .date_containt {
                    font-size: 12px;
                }
            }

            .viral_info {
                line-height: 18px;
                font-size: 12px;
            }

            // .viral_img {
            //     width: 100%;
            //     height: 184px;
            // }

            .text_info {
                font-size: 12px;
                margin-top: 6px;
            }

            ul {
                gap: 18px;
                margin-top: 10px;

                li {
                    img {
                        width: 16px;
                        margin-right: 2px;
                    }

                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}