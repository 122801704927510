:root {
    --loading-grey: #2b282848;
}

.skeleton_Wrapper {
    margin-top: 20px;
    .card {
        width: 100%;
        background-color: #0c193086;
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
        &.mobile_menubar {
            margin-bottom: 10px;
            display: none;
            @media screen and #{$mediaQueryRange-mobileDevice} {
                display: block;
            }
        }
        &.super_banner {
            margin-bottom: 10px;
        }
        &.reedem_coins {
            margin-bottom: 10px;
        }
        &.upcoming_and_live {
            margin-bottom: 10px;
        }
        &.games_banner {
            margin-bottom: 10px;
        }
        &.store_banner {
            margin-bottom: 10px;
        }
    }
}

.image {
    height: 500px;
    &.mobile_menubar {
        height: 60px;
    }
    &.super_banner {
        height: 235px;
    }
    &.reedem_coins {
        height: 65px;
    }
    &.upcoming_and_live {
        height: 154px;
    }
    &.games_banner {
        height: 232px;
    }
    &.store_banner {
        height: 204px;
    }
}

.loading .image,
.loading {
    background-color: #2b282848;
    background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(181, 183, 185, 0.1) 50%, rgba(0, 0, 0, 0) 60%)
        #374150;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 0.3s loading ease-in-out infinite;
}

.cards {
    width: 100%;
    @include Flex(row, center, space-between);
    overflow: hidden;
    .card1 {
        display: block;
        flex: 1 30%;
        margin-right: 10px;
        @media screen and #{$mediaQueryRange-mobileDevice} {
            display: block;
            width: 100%;
            height: 235px;
            margin-right: 0px;
        }
    }
    .card2 {
        display: block;
        flex: 1 30%;
        margin-right: 10px;
        @media screen and #{$mediaQueryRange-mobileDevice} {
            display: none;
        }
    }
    .card3 {
        display: block;
        flex: 1 30%;
        @media screen and #{$mediaQueryRange-mobileDevice} {
            display: none;
        }
    }
}

@keyframes loading {
    to {
        background-position-x: -20%;
    }
}

.loading h4 {
    min-height: 1.6rem;
    border-radius: 4px;
    animation-delay: 0.05s;
}

.loading .description {
    min-height: 4rem;
    border-radius: 4px;
    animation-delay: 0.06s;
}
