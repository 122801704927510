.main-viewAllHighlights-page {
    .container {
        .content-header {
            .header_recover {
                @media screen and #{$mediaQueryRange-mobileDevice} {
                    .top_Footer {
                        margin-top: 20px;
                    }
                }

                .container {
                    .back-button {
                        top: 10px;
                        left: 20px;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            margin-top: unset;
                            top: 4px;
                            left: 11px;
                        }
                    }
                }
            }
        }

        .main-viewAllHighlights-content {
            .content-body {
                .viewall-highlights-videos {
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        li {
                            margin-top: 10px;
                            width: 48%;

                            @media screen and #{$mediaQueryRange-mobileDevice} {
                                width: 100%;
                                margin-bottom: 10px;
                            }

                            .card-body {
                                position: relative;
                                max-width: 100%;
                                height: auto;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;

                                img {
                                    max-width: 100%;
                                    height: auto;
                                    border-radius: 10px;
                                }

                                .card-content {
                                    // position: absolute;
                                    display: flex;
                                    // justify-content: center;
                                    align-items: center;
                                    flex-direction: column;
                                    width: 100%;

                                    // bottom: 0;
                                    .video-icon {
                                        transform: translate(0%, -50%);
                                        top: 50%;
                                        position: absolute;

                                        .video-icon-mobile {
                                            display: none;

                                            @media screen and #{$mediaQueryRange-mobileDevice} {
                                                display: unset;
                                            }
                                        }

                                        .video-icon-desktop {
                                            display: unset;

                                            @media screen and #{$mediaQueryRange-mobileDevice} {
                                                display: none;
                                            }
                                        }
                                    }

                                    .card-txt {
                                        position: absolute;
                                        width: 100%;
                                        background: transparent linear-gradient(180deg, #2b318a00 0%, #19318c8c 38%, #0a318e 100%) 0% 0% no-repeat padding-box;
                                        opacity: 1;
                                        color: white;
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        padding-left: 10px;
                                        padding-top: 40px;
                                        padding-bottom: 24px;
                                        bottom: 0;
                                        max-width: 1200px;
                                        margin: 0 auto;
                                        border-radius: 10px;
                                    }

                                    .card_date {
                                        position: absolute;
                                        font-size: 12px;
                                        width: 100%;
                                        opacity: 1;
                                        color: white;
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        padding-left: 10px;
                                        padding-top: 40px;
                                        padding-bottom: 7px;
                                        bottom: 0;
                                        max-width: 1200px;
                                        margin: 0 auto;

                                        @media screen and #{$mediaQueryRange-mobileDevice} {
                                            font-size: 10px;
                                        }
                                    }
                                }
                            }
                          
                            @media screen and #{$mediaQueryRange-mobileDevice} {
                                // width: 300px;
                                // height: 200px;
                            }
                        }
                    }
                }
            }

            .content-footer {
                margin-bottom: 10px;
                width: 100%;

                .showMore-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .btn {
                        font: normal normal 600 16px/18px $brand-fontFamily;
                    }

                    .showMore-button {
                        width: 20%;

                        @media screen and #{$mediaQueryRange-mobileDevice} {
                            width: 20%;
                            // margin-bottom: 40px;
                        }
                    }
                }

                @media screen and #{$mediaQueryRange-mobileDevice} {
                    margin-bottom: 20px;
                }
            }
        }
    }
}