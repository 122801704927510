.shareModal_wrapper {
    .shareModal_content {
        padding: 20px;

        .referral_link {
            @include Flex(row, center, space-between);
            font-size: 12px;
            color: #8a8a8a;
            border-radius: 20px;
            margin: 40px auto 0;
            border: 1px solid #2d5fd5;
            @media screen and #{$mediaQueryRange-mobileDevice} {
                margin: 20px auto 0;
            }
        }

        .link_section {
            // padding: 12px 10px;
            padding: 7px 10px;
            background-color: #030814;
            width: 100%;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            // height: 50px;
            height: 40px;
            line-height: 2;
            font-size: 13px;
            min-width: 0;

            .truncate {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: auto;
            }
        }

        .copyButton {
            background: #f6ba23 0% 0% no-repeat padding-box;
            // padding: 12px 0;
            padding: 8px 0;
            width: 80px;
            // min-width: 10px;
            // max-width: 50px;
            font-size: 13px;
            font-weight: 700;
            color: $brand-blackColor;
            text-align: center;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            // height: 50px;
            height: 38px;
            line-height: 2;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 16px;
            }
        }

        .referral_content {
            margin: 20px auto;
            width: 90%;
            text-align: center;
            font-size: 14px;
        }

        .btn_share {
            text-align: center;

            .btnLarge {
                font-size: 18px;
                font-weight: $brand-fontWeight;
                width: 70%;
                margin: 0;
                margin-bottom: 20px;
            }
        }

        .social_links_icon {
            @include Flex(row, center, center);
            width: 50%;
            margin: 20px auto;

            .icon_Container {
                width: 50px;

                img {
                    width: 80%;
                }
            }
        }
    }
}

@media screen and #{$mediaQueryRange-mobileDevice} {
    .shareModal_wrapper {
        .referral_link {
            // width: 80%;
        }

        // .copyButton {
        //     // width: 30%;
        //     min-width: 80px !important;
        // }

        .btn_share {
            .btnLarge {
                font-size: 16px;
                width: 100% !important;
            }
        }

        .social_links_icon {
            .icon_Container {
                width: 40px;

                img {
                    width: 80%;
                }
            }
        }
    }
}
